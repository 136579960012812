import { useNavigate } from "react-router";
import { Context } from "../../context/context";
import useAuthHook from "./hook/useAuthHook";
import { useContext, useEffect } from "react";

function RedirectWrapper({ children }: any) {
  const history = useNavigate();
  const { ifAuthenticated, currentUser, ifSuperAdmin } = useContext(Context);

  useEffect(() => {
    const relativeUrl = window.location.pathname;

    //check if route is protected and is only accessible by general user
    const protectedRoutes = ["dashboard"];
    const ifRouteIsProtected = protectedRoutes.some((protectedRoute) =>
      relativeUrl.includes(protectedRoute)
    );
    if (ifAuthenticated === "false" && ifRouteIsProtected) {
      sessionStorage.setItem("redirectUrl", relativeUrl);
      history("/login");
    }

    //check if route is protected and is only accessible by admin user
    const protectedRoutesAdmin = ["admin"];
    const ifRouteIsProtectedAdmin = protectedRoutesAdmin.some(
      (protectedRouteAdmin) => relativeUrl.includes(protectedRouteAdmin)
    );
    if (ifAuthenticated === "false" && ifRouteIsProtectedAdmin) {
      sessionStorage.setItem("redirectUrl", relativeUrl);
      history("/login");
    }

    if (
      ifAuthenticated === "true" &&
      ifSuperAdmin === "false" &&
      ifRouteIsProtectedAdmin
    ) {
      history("/dashboard");
    }
  }, [ifAuthenticated, currentUser, ifSuperAdmin]);
  return <>{children}</>;
}

export default RedirectWrapper;
