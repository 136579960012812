import { Card, Col, Row } from "react-bootstrap";
import GeneralContainerRowColCard from "./generalContainerRowColCard";
import { useContext } from "react";
import { Context } from "../context/context";

function HorizontalOr({ children, ...props }: any) {
  const { openSidebar } = useContext(Context);
  return (
    <GeneralContainerRowColCard
      colMd={openSidebar ? 8 : 7}
      colLg={openSidebar ? 7 : 6}
      colXl={openSidebar ? 6 : 5}
      cardBorder={"none"}
      cardPaddingRight={"0px"}
      cardPaddingLeft={"0px"}
    >
      <Row>
        <Col>
          <hr style={{ opacity: 1 }} />
        </Col>
        <Col className="col-auto align-self-center" style={{ color: "black" }}>
          OR
        </Col>
        <Col>
          <hr style={{ opacity: 1 }} />
        </Col>
      </Row>
    </GeneralContainerRowColCard>
  );
}

export default HorizontalOr;
