import { AiOutlineDoubleRight, AiOutlineMenu } from "react-icons/ai";

function SidebarButtonComponent(props: any) {
  const { openSidebar, setOpenSidebar, closeAllSidebarItems } = props;
  const handleSetOpenSidebar = () => {
    if (openSidebar) {
      closeAllSidebarItems();
    }
    setOpenSidebar(!openSidebar);
    const width = window.innerWidth;
    if (width < 600 && !openSidebar) {
    }
    if (width < 600 && openSidebar) {
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        minHeight: "44px",
        alignItems: "center",
        marginRight: "10px",
        marginLeft: "10px",
      }}
    >
      <AiOutlineMenu
        id="mainSidebarButton"
        className={`${openSidebar ? "rotate180degrees" : ""}`}
        onClick={handleSetOpenSidebar}
        aria-controls="example-collapse-text"
        aria-expanded={openSidebar}
        style={{
          color: "white",
          fontSize: "30px",
          border: "2px solid white",
          borderRadius: "50%",
          padding: "4px",
          cursor: "pointer",
        }}
      />
    </div>
  );
}

export default SidebarButtonComponent;
