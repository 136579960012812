import { Button, Form, FormGroup } from "react-bootstrap";
import GeneralColCard from "./generalColCard";
import GeneralContainerRow from "./generalContainerRow";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../util/inputFieldErrorMapper";
import { frontendInputFields } from "../util/frontendInputFields";
import FileUpload from "./fileUpload";
import ImageCrop from "./imageCrop";
import FormErrorMessage from "./formErrorMessage";
import AdvertisementShow from "./advertisementShow";
import CharacterCounter from "./characterCounter";
import { CategoryAndSubCategorySelect } from "./categoryAndSubCategorySelect";
import { AddressAutoComplete } from "./addressAutoComplete";
import { StateAndRegionSelect } from "./stateAndRegionSelect";
import { sampleAdvertisementText } from "../util/sampleAdvertisementText";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Recaptcha from "../auth/recaptcha/recaptcha";
import { sampleBusinessAddressPlaceId } from "../util/sampleBusinessAddressPlaceId";
import AdvertisementRequestChangeReason from "../admin/adminSharedComponenets/advertisementRequestChangeReason";

function AdvertisementForm(props: any) {
  const {
    handleSubmit,
    imagesArrayPlaceholder,
    advertisementFormError,
    mainCategory,
    setMainCategory,
    subCategory,
    setSubCategory,
    advertisementDescription,
    setAdvertisementDescription,
    advertisementTitle,
    setAdvertisementTitle,
    abn,
    setAbn,
    websiteLink,
    setWebsiteLink,
    businessEmail,
    setBusinessEmail,
    businessAddress,
    setBusinessAddress,
    businessState,
    setBusinessState,
    businessRegion,
    setBusinessRegion,
    businessPhoneNumber,
    setBusinessPhoneNumber,
    imagesArray,
    setImagesArray,
    previewCanvasRef,
    imagesThumbnail,
    setImagesThumbnail,
    isDuringFormSubmission,
    submitButtonWording,
    recaptchaRef,
    advertisementRequestChangeReasonText,
  } = props;

  useEffect(() => {
    const firstInvalidField = document.querySelector(".is-invalid");
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [advertisementFormError]);

  return (
    <GeneralContainerRow>
      <GeneralColCard colMd={12} colLg={12} colXl={6}>
        <AdvertisementRequestChangeReason
          advertisementRequestChangeReasonText={
            advertisementRequestChangeReasonText
          }
        />
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-3">
            <Form.Label>Australian Business Number (ABN)</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Enter Your ABN"
              value={abn}
              onChange={(e) => setAbn(e.target.value)}
              isInvalid={inputFieldErrorMapperBoolean(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_ABN
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_ABN
              )}
              {", "}
              visit{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: `<a href='https://abr.business.gov.au' target='_blank'>abr.business.gov.au</a>`,
                }}
              />{" "}
              for more information
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Website URL <small>(optional)</small>
            </Form.Label>
            <Form.Control
              size="sm"
              id="advertisement-website-url"
              type="text"
              placeholder="Enter Website URL"
              value={websiteLink}
              onChange={(e) => setWebsiteLink(e.target.value)}
              isInvalid={inputFieldErrorMapperBoolean(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_WEBSITE_URL
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_WEBSITE_URL
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Business Email <small>(optional)</small>
            </Form.Label>
            <Form.Control
              size="sm"
              id="advertisement-website-url"
              type="text"
              placeholder="Enter Business Email"
              value={businessEmail}
              onChange={(e) => setBusinessEmail(e.target.value)}
              isInvalid={inputFieldErrorMapperBoolean(
                advertisementFormError,
                frontendInputFields.BUSINESS_EMAIL
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                advertisementFormError,
                frontendInputFields.BUSINESS_EMAIL
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ marginBottom: 0 }}>
              Advertisement Title
            </Form.Label>
            <CharacterCounter min={5} max={30} text={advertisementTitle} />
            <Form.Control
              size="sm"
              id="advertisement-title"
              type="text"
              placeholder="Enter Advertisement Title"
              value={advertisementTitle}
              onChange={(e) => setAdvertisementTitle(e.target.value)}
              isInvalid={inputFieldErrorMapperBoolean(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_TITLE
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_TITLE
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label style={{ marginBottom: 0 }}>
              Advertisement Description
            </Form.Label>
            <CharacterCounter
              min={100}
              max={1000}
              text={advertisementDescription}
            />
            <Form.Control
              size="sm"
              as="textarea"
              rows={8}
              value={advertisementDescription?.replaceAll("*****", "\n")}
              onChange={(e) =>
                setAdvertisementDescription(
                  e.target.value?.replaceAll("\n", "*****")
                )
              }
              isInvalid={inputFieldErrorMapperBoolean(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_DESCRIPTION
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_DESCRIPTION
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <CategoryAndSubCategorySelect
            mainCategory={mainCategory}
            setMainCategory={setMainCategory}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
            advertisementFormError={advertisementFormError}
          />
          <FormGroup className="mb-3">
            <AddressAutoComplete
              businessAddress={businessAddress}
              setBusinessAddress={setBusinessAddress}
              setBusinessState={setBusinessState}
              setBusinessRegion={setBusinessRegion}
              advertisementFormError={advertisementFormError}
            />
          </FormGroup>
          <StateAndRegionSelect
            businessState={businessState}
            setBusinessState={setBusinessState}
            businessRegion={businessRegion}
            setBusinessRegion={setBusinessRegion}
            advertisementFormError={advertisementFormError}
          />
          <Form.Group className="mb-3">
            <Form.Label style={{ marginBottom: 0 }}>
              Main Business Phone Number
            </Form.Label>
            <CharacterCounter min={5} max={20} text={businessPhoneNumber} />
            <Form.Control
              size="sm"
              id="advertisement-main-business-phone-number"
              type="text"
              placeholder="Enter Main Business Phone Number"
              value={businessPhoneNumber}
              onChange={(e) => setBusinessPhoneNumber(e.target.value)}
              isInvalid={inputFieldErrorMapperBoolean(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_PHONE_NUMBER
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                advertisementFormError,
                frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_PHONE_NUMBER
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-2">
            <ImageCrop
              previewCanvasRef={previewCanvasRef}
              imagesArray={imagesArray}
              setImagesArray={setImagesArray}
              setImagesThumbnail={setImagesThumbnail}
              imagesThumbnail={imagesThumbnail}
              advertisementFormError={advertisementFormError}
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="formFileSm">
            <FileUpload
              imagesArray={imagesArray}
              setImagesArray={setImagesArray}
              imagesThumbnail={imagesThumbnail}
              setImagesThumbnail={setImagesThumbnail}
              advertisementFormError={advertisementFormError}
            />
          </Form.Group>

          <Button
            size="sm"
            variant="primary"
            type="submit"
            className="mt-2"
            disabled={isDuringFormSubmission}
          >
            {isDuringFormSubmission ? "Loading ..." : submitButtonWording}
          </Button>
        </Form>
        {/* <FormErrorMessage
          errorMessage={
            advertisementFormError.length > 0 ? "Fix the errors and retry" : ""
          }
        /> */}
      </GeneralColCard>

      <GeneralColCard colMd={12} colLg={12} colXl={6}>
        <AdvertisementShow
          advertisementShowType={"newAdvertisement"}
          businessPhoneNumber={businessPhoneNumber || "02****44432"}
          businessAddress={businessAddress || sampleBusinessAddressPlaceId}
          businessState={businessState || "NSW"}
          businessRegion={businessRegion || "hunter-region"}
          websiteLink={
            websiteLink || `${process.env.REACT_APP_DEFAULT_FRONTEND_URL}`
          }
          businessEmail={
            businessEmail ||
            `${process.env.REACT_APP_DEFAULT_WHERETOJOY_MAIN_EMAIL}`
          }
          abn={abn || "82207965042"}
          imagesArray={
            imagesArray.length ? imagesArray : imagesArrayPlaceholder
          }
          imagesThumbnail={
            imagesThumbnail.length > 0
              ? imagesThumbnail
              : imagesArrayPlaceholder
          }
          advertisementTitle={advertisementTitle || "sample Title"}
          advertisementDescription={
            advertisementDescription || sampleAdvertisementText
          }
        />
      </GeneralColCard>
    </GeneralContainerRow>
  );
}

export default AdvertisementForm;
