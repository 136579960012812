import { Card, Col, Container, Row } from "react-bootstrap";
import GeneralContainer from "./generalContainer";
import GeneralRow from "./generalRow";
import GeneralCol from "./generalCol";
import GeneralCard from "./generalCard";

function GeneralColCard({ children, ...props }: any) {
  const { colMd, colLg, colXl } = props;
  return (
    <GeneralCol colMd={colMd} colLg={colLg} colXl={colXl}>
      <GeneralCard>{children}</GeneralCard>
    </GeneralCol>
  );
}

export default GeneralColCard;
