const HelicopterIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M371.5-429v-270H360q-109.208 0-184.854 76.25Q99.5-546.5 99-439v10h272.5ZM519-85H121.5v-57.5H519V-85Zm77.5-119H99q-24.438 0-40.969-16.531Q41.5-237.062 41.5-261.5v-176q0-132.919 92.791-225.959Q227.081-756.5 360-756.5h236.5V-559h202l40-80h78v277.5l-320 34V-204ZM759-818H121.5v-57.5H759v57.5Z" />
    </svg>
  );
};

export default HelicopterIconFilled;
