import { createContext, useState } from "react";

export const Context = createContext(true as any);

const ContextValues = () => {
  const [ifAuthenticated, setIfAuthenticated] = useState("notSet");
  const [ifSuperAdmin, setIfSuperAdmin] = useState("notSet");
  const [currentUser, setCurrentUser] = useState("notSet");
  const [openSidebar, setOpenSidebar] = useState(() => {
    return window.innerWidth >= 600;
  });
  const [openSidebarItem1, setOpenSidebarItem1] = useState(false);
  const [openSidebarItem2, setOpenSidebarItem2] = useState(false);
  const [openSidebarItem3, setOpenSidebarItem3] = useState(false);
  const [openSidebarItem4, setOpenSidebarItem4] = useState(false);
  const [openSidebarItem5, setOpenSidebarItem5] = useState(false);
  const [openSidebarItem6, setOpenSidebarItem6] = useState(false);
  const [openSidebarItem7, setOpenSidebarItem7] = useState(false);
  const [openSidebarItem8, setOpenSidebarItem8] = useState(false);
  const [openSidebarItem9, setOpenSidebarItem9] = useState(false);
  const [openSidebarItem10, setOpenSidebarItem10] = useState(false);
  const [openSidebarItem11, setOpenSidebarItem11] = useState(false);
  const [isNarrowScreen, setIsNarrowScreen] = useState(
    window.innerWidth < 600 ? "true" : "false"
  );
  const [allAdvertisements, setAllAdvertisements] = useState("notSet");
  const [bookmarkedAdvertisements, setBookmarkedAdvertisements] =
    useState("notSet");
  const [personalAdvertisements, setPersonalAdvertisements] =
    useState("notSet");
  const [personalEditedAdvertisements, setPersonalEditedAdvertisements] =
    useState("notSet");
  const [adminAllAdvertisements, setAdminAllAdvertisements] =
    useState("notSet");
  const [adminAllEditedAdvertisements, setAdminAllEditedAdvertisements] =
    useState("notSet");
  const [googleMapScriptLoaded, setGoogleMapScriptLoaded] = useState("notSet");
  const [shouldFetchAuthInitialStates, setShouldFetchAuthInitialStates] =
    useState(true);
  const [shouldFetchPublicInitialStates, setShouldFetchPublicInitialStates] =
    useState(true);
  const [selectedAdvertisementType, setSelectedAdvertisementType] =
    useState("notSet");
  const [selectedAdvertisement, setSelectedAdvertisement] = useState("notSet");
  const [relevantAdvertisements, setRelevantAdvertisements] =
    useState("notSet");
  const [
    selectedAdvertisementVisibilityStatus,
    setSelectedAdvertisementVisibilityStatus,
  ] = useState("notSet");
  const [adminAllUsers, setAdminAllUsers] = useState("notSet");

  const values = {
    ifAuthenticated,
    setIfAuthenticated,
    ifSuperAdmin,
    setIfSuperAdmin,
    currentUser,
    setCurrentUser,
    openSidebar,
    setOpenSidebar,
    openSidebarItem1,
    setOpenSidebarItem1,
    openSidebarItem2,
    setOpenSidebarItem2,
    openSidebarItem3,
    setOpenSidebarItem3,
    openSidebarItem4,
    setOpenSidebarItem4,
    openSidebarItem5,
    setOpenSidebarItem5,
    openSidebarItem6,
    setOpenSidebarItem6,
    openSidebarItem7,
    setOpenSidebarItem7,
    openSidebarItem8,
    setOpenSidebarItem8,
    openSidebarItem9,
    setOpenSidebarItem9,
    openSidebarItem10,
    setOpenSidebarItem10,
    openSidebarItem11,
    setOpenSidebarItem11,
    isNarrowScreen,
    setIsNarrowScreen,
    allAdvertisements,
    setAllAdvertisements,
    bookmarkedAdvertisements,
    setBookmarkedAdvertisements,
    googleMapScriptLoaded,
    setGoogleMapScriptLoaded,
    personalAdvertisements,
    setPersonalAdvertisements,
    personalEditedAdvertisements,
    setPersonalEditedAdvertisements,
    adminAllAdvertisements,
    setAdminAllAdvertisements,
    adminAllEditedAdvertisements,
    setAdminAllEditedAdvertisements,
    shouldFetchAuthInitialStates,
    setShouldFetchAuthInitialStates,
    shouldFetchPublicInitialStates,
    setShouldFetchPublicInitialStates,
    selectedAdvertisementType,
    setSelectedAdvertisementType,
    selectedAdvertisement,
    setSelectedAdvertisement,
    relevantAdvertisements,
    setRelevantAdvertisements,
    selectedAdvertisementVisibilityStatus,
    setSelectedAdvertisementVisibilityStatus,
    adminAllUsers,
    setAdminAllUsers,
  };
  return values;
};

export default ContextValues;
