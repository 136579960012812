const UserIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M224.89-255.5Q288-295 348.25-315T480-335q71.5 0 132 20t124.5 59.5Q781-310 799.25-364.649q18.25-54.648 18.25-115.25 0-144.101-96.75-240.851T480-817.5q-144 0-240.75 96.75T142.5-479.899q0 60.602 18.75 115.25Q180-310 224.89-255.5ZM479.869-451q-57.369 0-96.619-39.381-39.25-39.38-39.25-96.75 0-57.369 39.381-96.619 39.38-39.25 96.75-39.25 57.369 0 96.619 39.381 39.25 39.38 39.25 96.75 0 57.369-39.381 96.619-39.38 39.25-96.75 39.25Zm-.274 366q-81.553 0-154.09-31.263-72.538-31.263-125.772-85Q146.5-255 115.75-326.864 85-398.728 85-480.436q0-81.789 31.263-153.789 31.263-71.999 85-125.387Q255-813 326.864-844q71.864-31 153.572-31 81.789 0 153.795 31.132 72.005 31.131 125.387 84.5Q813-706 844-633.977q31 72.023 31 153.647 0 81.704-31.013 153.629t-84.5 125.563Q706-147.5 633.827-116.25 561.655-85 479.595-85Z" />
    </svg>
  );
};

export default UserIconFilled;
