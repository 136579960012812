import useAuthHook from "../authWrapper/hook/useAuthHook";
import axios from "axios";

function Axios({ children }: any) {
  const { setIfAuthenticated, setCurrentUser, setIfSuperAdmin } = useAuthHook();

  axios.defaults.baseURL = process.env.REACT_APP_DEFAULT_BACKEND_URL;
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (
        err.response.status === 401 &&
        err.config.url !== "auth/refresh-token"
      ) {
        const response = await axios.post("auth/refresh-token");
        if (response?.status === 200) {
          setIfAuthenticated("true");
          setCurrentUser(response.data);
          if (
            response?.data?.permissions &&
            response?.data?.permissions.includes("superAdmin")
          ) {
            setIfSuperAdmin("true");
          } else {
            setIfSuperAdmin("false");
          }
          return axios(err.config);
        } else {
          setIfAuthenticated("false");
          setIfSuperAdmin("false");
        }
      } else if (err.response.status !== 401) {
        return Promise.reject(err);
      }
    }
  );
  return <>{children}</>;
}

export default Axios;
