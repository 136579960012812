const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M224.89-255.5Q288-299 348.75-322T480-345q70.5 0 131.5 23t125 66.5Q781-310 799.25-364.649q18.25-54.648 18.25-115.25 0-144.101-96.75-240.851T480-817.5q-144 0-240.75 96.75T142.5-479.899q0 60.602 18.75 115.25Q180-310 224.89-255.5ZM479.869-451q-57.369 0-96.619-39.381-39.25-39.38-39.25-96.75 0-57.369 39.381-96.619 39.38-39.25 96.75-39.25 57.369 0 96.619 39.381 39.25 39.38 39.25 96.75 0 57.369-39.381 96.619-39.38 39.25-96.75 39.25Zm.349 366q-81.125 0-153.283-31.263-72.159-31.263-125.797-85Q147.5-255 116.25-326.81 85-398.619 85-480.266q0-81.141 31.263-153.049 31.263-71.909 85-125.547Q255-812.5 326.81-843.75 398.619-875 480.266-875q81.141 0 153.049 31.263 71.909 31.263 125.547 85Q812.5-705 843.75-633.131 875-561.261 875-480.218q0 81.125-31.263 153.283-31.263 72.159-85 125.797Q705-147.5 633.131-116.25 561.261-85 480.218-85Zm-.248-57.5q54.53 0 106.28-15.75T690-214q-52-36-104.23-54.75-52.229-18.75-105.71-18.75-53.481 0-106.02 18.5Q321.5-250.5 270-214q52 40 103.72 55.75t106.25 15.75Zm.03-366q34.5 0 56.5-22t22-56.5q0-34.5-22-56.5t-56.5-22q-34.5 0-56.5 22t-22 56.5q0 34.5 22 56.5t56.5 22Zm0-78.5Zm0 372Z" />
    </svg>
  );
};

export default UserIcon;
