export async function imageCropper(base64Data: string): Promise<string> {
  return new Promise<string>((resolve) => {
    const img = new Image();
    img.src = base64Data;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = 800;
      canvas.height = 600;

      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0, 800, 600);

        resolve(canvas.toDataURL("image/jpeg"));
      }
    };
  });
}
