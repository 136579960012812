import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import _ from "lodash";
import { Context } from "../../context/context";
import axios from "axios";
import AdvertisementCardComponent from "../advertisement/component/advertisementCardComponent";
import useAdvertisementPageHook from "./hook/useAdvertisementPageHook";
import AdvertisementCardPlaceholderComponent from "../../sharedComponents/advertisementCardPlaceholder";
import AdvertisementCategoryIsEmptyComponent from "./component/advertisementCategoryIsEmpty";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";
import { AdvertisementFilter } from "../../sharedComponents/advertisementFilter";
import { currentCategoriesAndSubCategories } from "../../util/currentCategoriesAndSubcategories";

const AdvertisementPage = () => {
  const { advertisementCategory, advertisementSubCategory } =
    useAdvertisementPageHook();
  const { allAdvertisements } = useContext(Context);
  const [isLoaded, setIsLoaded] = useState(false);
  const [advertisementsFiltered, setAdvertisementsFiltered] = useState("");
  const [
    allAdvertisementInThisCategoryAndSubCategory,
    setAllAdvertisementInThisCategoryAndSubCategory,
  ] = useState("notSet");

  useEffect(() => {
    if (allAdvertisements !== "notSet") {
      const category: any = currentCategoriesAndSubCategories.find(
        (item) => item.categoryAlias === advertisementCategory
      );
      const subCategory = category.subCategories.find(
        (item: any) => item.subCategoryAlias === advertisementSubCategory
      );
      const relevantAdvertisements: any = _.filter(allAdvertisements, {
        advertisementCategory: category.id,
        advertisementSubCategory: subCategory.id,
      });
      setAllAdvertisementInThisCategoryAndSubCategory(relevantAdvertisements);
    }
  }, [allAdvertisements, advertisementCategory, advertisementSubCategory]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  // useEffect(() => {}, [advertisementsFiltered]);

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [advertisementCategory, true],
          [advertisementSubCategory, false],
        ]}
      ></BreadCrumb>

      <AdvertisementFilter
        advertisements={allAdvertisementInThisCategoryAndSubCategory}
        setAdvertisementsFiltered={setAdvertisementsFiltered}
      />

      <GeneralCardLayoutWrapper>
        {advertisementsFiltered !== "notSet" &&
        advertisementsFiltered !== "loadingFilteredAdvertisements" &&
        isLoaded ? (
          <>
            {Array.isArray(advertisementsFiltered) &&
            advertisementsFiltered.length > 0 ? (
              advertisementsFiltered.map((item: any) => (
                <AdvertisementCardComponent
                  key={item.id}
                  src={`${process.env.REACT_APP_S3_ROUTE}/advertisements/${item.id}/img_0.jpg`}
                  title={item.advertisementTitle}
                  id={item.id}
                  description={item.advertisementDescription}
                />
              ))
            ) : (
              <AdvertisementCategoryIsEmptyComponent
                title="No Joy Yet"
                text="A new Joy would appear here as soon"
              />
            )}
          </>
        ) : (
          <>
            <AdvertisementCardPlaceholderComponent />
          </>
        )}
      </GeneralCardLayoutWrapper>
    </>
  );
};

export default AdvertisementPage;
