import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/context";
import { useNavigate } from "react-router";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { currentCategoriesAndSubCategories } from "../../../util/currentCategoriesAndSubcategories";

const useAdvertisementPageHook = () => {
  let { advertisementCategory, advertisementSubCategory, advertisementId } =
    useParams();

  const history = useNavigate();

  const {
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
    selectedAdvertisementType,
    setSelectedAdvertisementType,
    ifAuthenticated,
    selectedAdvertisement,
    setSelectedAdvertisement,
    relevantAdvertisements,
    setRelevantAdvertisements,
    // selectedAdvertisementVisibilityStatus,
    // setSelectedAdvertisementVisibilityStatus,
    ifSuperAdmin,
  } = useContext(Context);

  // const findAdvertisementVisibility = async (advertisementId: any) => {
  //   const response: any = await axios.get(
  //     `advertisements/advertisement-visibility/${advertisementId}`
  //   );
  //   const advertisementVisibility = response?.data?.advertisementVisibility;

  //   if (
  //     ifAuthenticated === "false" &&
  //     (advertisementVisibility === "onlyVisibleToAdmins" ||
  //       advertisementVisibility === "onlyVisibleToAdminsAndOwners")
  //   ) {
  //     const redirectUrl = window.location.pathname;
  //     sessionStorage.setItem("redirectUrl", redirectUrl);
  //     history("/login");
  //   }

  //   return advertisementVisibility;
  // };

  const findAdvertisementInFrontendById = (advertisementId: any) => {
    //check if the advertisement publicly available
    let target = _.find(allAdvertisements, {
      id: advertisementId,
    });
    if (!_.isUndefined(target)) {
      setSelectedAdvertisementType("public");
      setSelectedAdvertisement(target);
      // setSelectedAdvertisementVisibilityStatus("visibleToEveryone");
    }
    //check if personal advertisement
    if (_.isUndefined(target)) {
      target = _.find(personalAdvertisements, {
        id: advertisementId,
      });
      if (!_.isUndefined(target)) {
        setSelectedAdvertisementType("personalAll");
        setSelectedAdvertisement(target);
        // setSelectedAdvertisementVisibilityStatus(
        //   "onlyVisibleToAdminsAndOwners"
        // );
      }
    }
    //check if admin all advertisement
    if (_.isUndefined(target)) {
      target = _.find(adminAllAdvertisements, {
        id: advertisementId,
      });
      if (!_.isUndefined(target)) {
        setSelectedAdvertisementType("adminAll");
        setSelectedAdvertisement(target);
        // setSelectedAdvertisementVisibilityStatus("onlyVisibleToAdmins");
      }
    }
    //check if personal edited advertisement
    if (_.isUndefined(target)) {
      target = _.find(personalEditedAdvertisements, {
        id: advertisementId,
      });
      if (!_.isUndefined(target)) {
        setSelectedAdvertisementType("personalEdited");
        setSelectedAdvertisement(target);
        // setSelectedAdvertisementVisibilityStatus(
        //   "onlyVisibleToAdminsAndOwners"
        // );
      }
    }
    //check if admin edited advertisement
    if (_.isUndefined(target)) {
      target = _.find(adminAllEditedAdvertisements, {
        id: advertisementId,
      });
      if (!_.isUndefined(target)) {
        setSelectedAdvertisementType("adminEdited");
        setSelectedAdvertisement(target);
        // setSelectedAdvertisementVisibilityStatus("onlyVisibleToAdmins");
      }
    }

    return target;
  };

  useEffect(() => {
    (async () => {
      if (
        (ifAuthenticated === "false" && allAdvertisements !== "notSet") ||
        (ifAuthenticated === "true" &&
          ifSuperAdmin === "true" &&
          personalAdvertisements !== "notSet" &&
          personalEditedAdvertisements !== "notSet" &&
          adminAllAdvertisements !== "notSet" &&
          adminAllEditedAdvertisements !== "notSet") ||
        (ifAuthenticated === "true" &&
          ifSuperAdmin === "false" &&
          personalAdvertisements !== "notSet" &&
          personalEditedAdvertisements !== "notSet")
      ) {
        //find advertisement category from url
        const result1 = _.find(currentCategoriesAndSubCategories, {
          categoryAlias: advertisementCategory,
        });
        //find advertisement sub category from url
        const result2 = _.find(result1?.subCategories, {
          subCategoryAlias: advertisementSubCategory,
        });
        //find all the advertisement which has the same category and subcategory
        setRelevantAdvertisements(
          _.filter(allAdvertisements, {
            advertisementCategory: result1?.id,
            advertisementSubCategory: result2?.id,
          })
        );

        //find advertisement in frontend by id
        const target = findAdvertisementInFrontendById(advertisementId);

        //advertisement is not in frontend we'll check backend
        // if (_.isUndefined(target)) {
        //   const advertisementVisibility = await findAdvertisementVisibility(
        //     advertisementId
        //   );
        //   setSelectedAdvertisementVisibilityStatus(advertisementVisibility);
        // }
      }
    })();
  }, [
    advertisementCategory,
    advertisementSubCategory,
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
    ifAuthenticated,
    advertisementId,
  ]);

  return {
    advertisementCategory,
    advertisementSubCategory,
    relevantAdvertisements,
    setRelevantAdvertisements,
    advertisementId,
    selectedAdvertisement,
    selectedAdvertisementType,
    // selectedAdvertisementVisibilityStatus,
    ifAuthenticated,
    ifSuperAdmin,
    findAdvertisementInFrontendById,
    // findAdvertisementVisibility,
  };
};

export default useAdvertisementPageHook;
