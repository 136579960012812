const GrillIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M649.937-300q44.943 0 76.253 31.309 31.31 31.309 31.31 76.25t-31.31 76.191Q694.88-85 649.937-85 614.5-85 586-106t-38-55.5H255l-40.5 62Q208-89 196.803-86T176.5-89.5q-10.5-7.5-14-18.697t3-21.803l182-275.5q-74.463-28.141-120.482-88.321Q181-554 172.5-633.5H710q-8.5 79.5-55.75 139.25T535.5-406.5L596-313q-14 7-26.75 16.25T550-278.5L477-391q-6 2-15.917 2h-38.666q-9.917 0-16.417-2L293.5-219H548q9.5-35.5 37.5-58.25T649.937-300ZM650-142.5q21.213 0 35.606-14.144Q700-170.788 700-192.5q0-21.712-14.394-36.106Q671.213-243 650-243q-21.713 0-36.106 14.394Q599.5-214.212 599.5-192.5q0 21.712 14.394 35.856Q628.287-142.5 650-142.5ZM369-670q5-27.5-1.5-48.5t-29.5-52q-22-28.5-28-50.75t-.5-53.75H338q-3 33 3 52.25t27 46.5Q387.5-748 394.25-725t3.25 55H369Zm74 0q2.5-36-3.159-55.295Q434.182-744.591 413-770.5q-22-27.5-28.5-50t-1-54.5H412q-5 25.5.904 46.25Q418.809-808 442-776q19.5 27 26 50.67 6.5 23.669 3.5 55.33H443Zm73 0q3.5-35-2.159-54.545Q508.182-744.091 487-770.5q-22.5-29-29.25-51.516Q451-844.531 456.5-875H485q-5 25.5 1.154 46.25Q492.309-808 516-776q19.5 26.5 25.75 49.92Q548-702.661 544.5-670H516Z" />
    </svg>
  );
};

export default GrillIconFilled;
