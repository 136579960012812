const HelicopterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M371.5-429v-270H360q-109.208 0-184.854 76.083Q99.5-546.833 99-439v10h272.5ZM539-261.5V-699H429v327.5H99v110h440ZM596.5-385 859-413v-88.5H596.5V-385ZM519-85H121.5v-57.5H519V-85Zm77.5-119H99q-24.188 0-40.844-16.656Q41.5-237.312 41.5-261.5v-176q0-132.919 92.791-225.959Q227.081-756.5 360-756.5h236.5V-559h202l40-80h78v277.5l-320 34V-204ZM759-818H121.5v-57.5H759v57.5ZM596.5-385v-116.5V-385ZM539-261.5Z" />
    </svg>
  );
};

export default HelicopterIcon;
