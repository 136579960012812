import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { GeneralModal } from "./modals";
import { Image as BootstrapImage } from "react-bootstrap";
import randomstring from "randomstring";
import { removeObjectWithIdInArray } from "../util/removeObjectWithIdInArray";
import { AiOutlineCloseSquare } from "react-icons/ai";
import _ from "lodash";
import { imageCompressor } from "../util/imageCompressor";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../util/inputFieldErrorMapper";
import { frontendInputFields } from "../util/frontendInputFields";

function FileUpload(props: any) {
  const {
    imagesArray,
    setImagesArray,
    imagesThumbnail,
    setImagesThumbnail,
    advertisementFormError,
  } = props;
  const [fileUploadErrorModalShow, setFileUploadErrorModalShow] =
    useState(false);
  const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState("");

  const [duringImageUpload, setDuringImageUpload] = useState(false);
  const inputRef = useRef(null);

  const removeImage = (id: any) => {
    setImagesThumbnail(removeObjectWithIdInArray(imagesThumbnail, id));
    setImagesArray(removeObjectWithIdInArray(imagesArray, id));
    // if (imagesArray.length === 0) {
    //   resetFileInput();
    // }
  };

  function getImageDimensions(base64Image: string) {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.src = base64Image;
      i.onload = function () {
        resolved({ width: i.width, height: i.height });
      };
    });
  }

  const handleFileSelect = async (event: any) => {
    const files = event.target.files;
    const tempImagesArray = [];
    const tempImagesThumbnail = [];
    setDuringImageUpload(true);
    // await new Promise((r) => setTimeout(r, 1500));
    if (!imagesArray.length) {
      setFileUploadErrorModalShow(true);
      setFileUploadErrorMessage(`Please upload cover image first`);
      return;
    }
    for (const file of files) {
      if (!file.type.match("image.*")) {
        setFileUploadErrorModalShow(true);
        setFileUploadErrorMessage("Only Image files are supported");
        // resetFileInput();
        setDuringImageUpload(false);
        return;
      }
      if (
        process.env.REACT_APP_MAX_IMAGE_SIZE_CREATE_ADVERTISEMENT_BYTES &&
        file.size >
          parseInt(
            process.env.REACT_APP_MAX_IMAGE_SIZE_CREATE_ADVERTISEMENT_BYTES
          )
      ) {
        setFileUploadErrorModalShow(true);
        setFileUploadErrorMessage(
          `Image size should be less than ${
            parseInt(
              process.env.REACT_APP_MAX_IMAGE_SIZE_CREATE_ADVERTISEMENT_BYTES
            ) / 1000000
          } MB`
        );
        // resetFileInput();
        setDuringImageUpload(false);
        return;
      }
      if (
        process.env.REACT_APP_MAX_IMAGE_NUMBER_CREATE_ADVERTISEMENT &&
        tempImagesArray.length + imagesArray.length >=
          parseInt(process.env.REACT_APP_MAX_IMAGE_NUMBER_CREATE_ADVERTISEMENT)
      ) {
        setFileUploadErrorModalShow(true);
        setFileUploadErrorMessage(
          `Maximum ${process.env.REACT_APP_MAX_IMAGE_NUMBER_CREATE_ADVERTISEMENT} images are allowed`
        );
        // resetFileInput();
        setDuringImageUpload(false);
        return;
      }
      const image = await imageCompressor(file);
      const imageDimensions: any = await getImageDimensions(
        image.resizedBase64strReadyToBeUsedAsSrc
      );
      if (imageDimensions.width > 2 * imageDimensions.height) {
        setFileUploadErrorModalShow(true);
        setFileUploadErrorMessage(
          "We don't support ultra wide or panoramic images at the moment, image width is at least twice as image height!"
        );
        // resetFileInput();
        setDuringImageUpload(false);
        return;
      }

      const id = `img_${randomstring.generate(12)}`;
      tempImagesArray.push({
        id,
        src: image.resizedBase64strReadyToBeUsedAsSrc,
        data: image.resizedBase64strReadyToBeUsedAsSrc,
      });
      tempImagesThumbnail.push({
        id,
        src: image.resizedBase64strReadyToBeUsedAsSrc,
        data: image.resizedBase64strReadyToBeUsedAsSrc,
      });
    }
    setImagesArray([...imagesArray, ...tempImagesArray]);
    setImagesThumbnail([...imagesThumbnail, ...tempImagesThumbnail]);

    setDuringImageUpload(false);
  };

  // const resetFileInput = () => {
  //   const ref: any = inputRef;
  //   ref.current.value = null;
  // };

  // const handleClickOnInput = () => {
  //   document.getElementById("imageSelectWrapper")?.click();
  // };

  return (
    <>
      <GeneralModal
        modalShow={fileUploadErrorModalShow}
        setModalShow={setFileUploadErrorModalShow}
        message={fileUploadErrorMessage}
      />
      <Form.Label>{`Upload up to ${
        // @ts-ignore
        parseInt(process.env.REACT_APP_MAX_IMAGE_NUMBER_CREATE_ADVERTISEMENT) -
        1
      } Images:`}</Form.Label>
      <Form.Control
        size="sm"
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileSelect}
        disabled={!imagesArray.length || duringImageUpload}
        isInvalid={inputFieldErrorMapperBoolean(
          advertisementFormError,
          frontendInputFields.ADVERTISEMENT_SUPPORTING_IMAGES
        )}
      />
      <Form.Control.Feedback type="invalid">
        {inputFieldErrorMapperWording(
          advertisementFormError,
          frontendInputFields.ADVERTISEMENT_SUPPORTING_IMAGES
        )}
      </Form.Control.Feedback>
      {/* <Form.Label>{`Upload up to ${process.env.REACT_APP_MAX_IMAGE_NUMBER_CREATE_ADVERTISEMENT} Images:`}</Form.Label> */}
      {/* <div
        className="input-group pointerOnHover"
        style={{ marginBottom: "10px" }}
      >
        <span className="input-group-text" onClick={handleClickOnInput}>
          Choose Images:
        </span>
        <label id="imageSelectWrapper" className="form-control pointerOnHover">
          {duringImageUpload
            ? "Uploading ...."
            : `${imagesThumbnail.length}/${process.env.REACT_APP_MAX_IMAGE_NUMBER_CREATE_ADVERTISEMENT} images selected`}
          <input
            type="file"
            id="files"
            name="files[]"
            multiple
            ref={inputRef}
            onChange={handleFileSelect}
            className="hidden"
            disabled={duringImageUpload}
          />
        </label>
      </div> */}
      <div className="mt-3">
        {imagesThumbnail.length > 0 &&
          imagesThumbnail.map((imageThumbnail: any, index: any) => {
            if (imageThumbnail.id !== "img_cover") {
              return (
                <div
                  onClick={() => removeImage(imageThumbnail.id)}
                  className="pointerOnHover"
                  style={{ display: "inline-block", position: "relative" }}
                  key={index}
                >
                  <AiOutlineCloseSquare
                    style={{
                      color: "white",
                      background: "#f55d5d",
                      position: "absolute",
                      fontSize: "25px",
                      right: 10,
                    }}
                  />
                  <BootstrapImage
                    className="imageUploadThumbnail"
                    id={imageThumbnail.id}
                    src={imageThumbnail.src}
                  />
                </div>
              );
            }
          })}
      </div>
    </>
  );
}

export default FileUpload;
