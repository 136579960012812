import { Row } from "react-bootstrap";
import BreadCrumb from "../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../layouts/breadCrumbs/routesEnum";
import GeneralContainerRowColCard from "./generalContainerRowColCard";
import { Context } from "../context/context";
import { useContext, useEffect } from "react";

export const TermsAndConditionsContent = (
  <>
    <div>
      <p>
        These terms and conditions govern your use of WhereToJoy, including all
        associated services, content, and functionality. By accessing or using
        the website, you agree to be bound by these terms and conditions. If you
        disagree with any part of these terms and conditions, you may not access
        the website.
      </p>
      <p>
        <b>1. Use of the Website by Businesses</b>
      </p>
      <ol style={{ paddingLeft: "0.5rem" }}>
        <p>
          1.1. By using this website to advertise your business, you agree that
          you are solely responsible for the accuracy and validity of the
          information provided in your advertisement.
        </p>

        <p>
          1.2. You must provide a valid Australian Business Number (ABN) to
          advertise on this website.
        </p>

        <p>
          1.3. You agree to provide truthful, accurate, and up-to-date
          information about your business, including but not limited to contact
          details, address, and website URL.
        </p>

        <p>
          1.4. You acknowledge that any images uploaded to the website are your
          responsibility, and you have the necessary rights or permissions to
          use and distribute them.
        </p>

        <p>
          1.5. You are solely responsible for the content of your
          advertisements, including text, images, and any other material
          provided.
        </p>

        <p>
          1.6. You agree not to post any content that is false, misleading,
          unlawful, defamatory, or infringes upon the rights of others.
        </p>

        <p>
          1.7. We reserve the right to review, edit, or remove any advertisement
          that violates these terms and conditions without prior notice.
        </p>

        <p>
          1.8. WhereToJoy acts solely as a platform for advertising and does not
          guarantee the accuracy, quality, or legality of any advertisements
          posted on the website.
        </p>

        <p>
          1.9. We are not responsible for any transactions or disputes that may
          arise between businesses and customers who engage with your
          advertisement through the website.
        </p>

        <p>
          1.10. You agree to indemnify and hold WhereToJoy harmless from any
          claims, damages, or liabilities arising out of your use of the website
          or any content you post.
        </p>

        <p>
          1.11. By submitting content to the website, you grant WhereToJoy a
          non-exclusive, royalty-free, worldwide license to use, reproduce,
          modify, and distribute the content for the purpose of operating and
          promoting the website.
        </p>
      </ol>
      <p>
        <b>2. Use of the Website by General Users</b>
      </p>
      <ol style={{ paddingLeft: "0.5rem" }}>
        <p>
          2.1. As a visitor to WhereToJoy, you acknowledge that the website
          serves as a platform for businesses to advertise their products or
          services.
        </p>

        <p>
          2.2. You understand that WhereToJoy does not guarantee the accuracy,
          reliability, or quality of the advertisements posted by businesses on
          the website.
        </p>

        <p>
          2.3. While WhereToJoy regularly checks the validity and active status
          of business owners' Australian Business Numbers (ABNs), we do not
          guarantee the legitimacy or authenticity of the businesses listed on
          the website.
        </p>

        <p>
          2.4. You acknowledge that it is your responsibility to verify the
          authenticity and credibility of any business before engaging in any
          transactions or interactions with them.
        </p>

        <p>
          2.5. WhereToJoy acts solely as a platform for businesses to advertise
          and does not assume any responsibility for the actions, conduct, or
          services provided by the businesses listed on the website.
        </p>

        <p>
          2.6. We are not liable for any losses, damages, or disputes that may
          arise from your interactions or transactions with businesses listed on
          the website.
        </p>

        <p>
          2.7. You agree to indemnify and hold WhereToJoy harmless from any
          claims, liabilities, or damages arising out of your use of the website
          or interactions with businesses listed on the website.
        </p>

        <p>
          2.8. You understand that the content of advertisements posted by
          businesses is provided by the respective business owners, and
          WhereToJoy does not endorse or verify the accuracy of such content.
        </p>

        <p>
          2.9. You agree not to rely solely on the information provided in
          advertisements and to conduct your own due diligence before engaging
          with any business listed on the website.
        </p>
      </ol>
      <p>
        <b>3. Protection of User Privacy</b>
      </p>
      <ol style={{ paddingLeft: "0.5rem" }}>
        <p>
          3.1. WhereToJoy prioritizes the protection of user privacy. We commit
          to minimizing the collection of user data and ensuring that users are
          not tracked while using the website or app.
        </p>
        <p>
          3.2. We do not share any user data with third parties. Any information
          provided by users, including email addresses used as usernames, is
          solely for the purpose of facilitating access to the website or app.
        </p>
        <p>
          3.3. We ask for no more information or personal details than necessary
          for user registration and authentication purposes.
        </p>
        <p>
          3.4. Users can be assured that their privacy is respected and upheld
          throughout their interactions with WhereToJoy, and their trust in us
          is paramount.
        </p>
      </ol>
      <p>
        <b>4. General Provisions</b>
      </p>
      <ol style={{ paddingLeft: "0.5rem" }}>
        <p>
          4.1. By using this website, you're agreeing to our Privacy Policy,
          which lays out how we handle any info you share with us while using
          the website or app. Your use of the website implies your consent to
          the terms of our Privacy Policy.
        </p>

        <p>
          4.2. We reserve the right to terminate or suspend your access to the
          website at any time, without prior notice or liability, for any reason
          whatsoever, including without limitation if you breach these terms and
          conditions.
        </p>

        <p>
          4.3. We reserve the right to modify or replace these terms and
          conditions at any time. It is your responsibility to review these
          terms periodically for changes. Your continued use of the website
          after any modifications indicates your acceptance of the new terms.
        </p>

        <p>
          4.4. These terms and conditions are governed by and construed in
          accordance with the laws of Australia. Any disputes arising under
          these terms and conditions shall be subject to the exclusive
          jurisdiction of the courts of Australia. By using WhereToJoy, you
          acknowledge that you have read, understood, and agree to be bound by
          these terms and conditions. If you do not agree to these terms, please
          refrain from using the website.
        </p>
      </ol>
    </div>
  </>
);

function TermsAndConditions() {
  const { openSidebar, ifAuthenticated } = useContext(Context);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BreadCrumb
        routes={
          ifAuthenticated === "true"
            ? [
                ["home", true],
                [routesEnum.DASHBOARD_URL, true],
                [routesEnum.TERMS_AND_CONDITIONS_URL, false],
              ]
            : [
                ["home", true],
                [routesEnum.TERMS_AND_CONDITIONS_URL, false],
              ]
        }
      ></BreadCrumb>

      <GeneralContainerRowColCard
        colMd={openSidebar ? 12 : 12}
        colLg={openSidebar ? 12 : 12}
        colXl={openSidebar ? 12 : 12}
      >
        {TermsAndConditionsContent}
      </GeneralContainerRowColCard>
    </>
  );
}

export default TermsAndConditions;
