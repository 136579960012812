import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import useAdvertisementPageHook from "../advertisement/hook/useAdvertisementPageHook";
import Image from "react-bootstrap/Image";
import { Context } from "../../context/context";
import AdvertisementCardComponent from "../advertisement/component/advertisementCardComponent";
import AdvertisementCardPlaceholderComponent from "../../sharedComponents/advertisementCardPlaceholder";
import _ from "lodash";
import AdvertisementCategoryIsEmptyComponent from "../advertisement/component/advertisementCategoryIsEmpty";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";

function AdvertisementManage() {
  const history = useNavigate();
  const { personalAdvertisements } = useContext(Context);
  const { ifAuthenticated } = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (ifAuthenticated === "notSet" || ifAuthenticated === "false") {
    return null;
  }

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ADVERTISEMENT_MANAGE_URL, false],
        ]}
      ></BreadCrumb>
      <GeneralCardLayoutWrapper>
        {personalAdvertisements !== "notSet" ? (
          <>
            {/* @ts-ignore */}
            {_.isArray(personalAdvertisements) &&
            !_.isEmpty(personalAdvertisements) ? (
              personalAdvertisements.map((item: any, index: any) => (
                <AdvertisementCardComponent
                  redirectUrl={`/${routesEnum.DASHBOARD_URL}/${routesEnum.ADVERTISEMENT_MANAGE_URL}/${item.id}`}
                  key={index}
                  src={`${process.env.REACT_APP_S3_ROUTE}/advertisements/${item.id}/img_0.jpg`}
                  title={item.advertisementTitle}
                  id={item.id}
                  description={item.advertisementDescription}
                />
              ))
            ) : (
              <AdvertisementCategoryIsEmptyComponent
                title="No advertisement to manage"
                text="If you are a joy provider, you can create an advertisement and you'll find it here later."
              />
            )}
          </>
        ) : (
          <>
            <AdvertisementCardPlaceholderComponent />
          </>
        )}
      </GeneralCardLayoutWrapper>
    </>
  );
}

export default AdvertisementManage;
