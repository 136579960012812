const AdventureIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M770-321.5q-72 0-121.5-44T585-479H409l-34-57.5h210q3-27.5 15.5-54.75t32-47.75H313l-35-57.5h388L610.564-860H440v-57.5h170.5q19.303 0 34.151 11 14.849 11 20.349 28L726.879-697H760q85.962 0 141.731 51.45Q957.5-594.1 957.5-508.88q0 76.38-55.5 131.88t-132 55.5Zm0-57.5q52.5 0 91.25-38.75T900-509q0-56.5-36.75-93.25T770.128-639q-1.128 0-10.628.5t-10 .5l40 109-53.5 20-41-111q-28.024 22.879-41.512 49.959Q640-542.961 640-508.974q0 54.141 37.917 92.057Q715.833-379 770-379ZM279.765-42q-44.682 0-75.723-31.208Q173-104.417 173-149v-11H2.5v-57.5h194.682Q210-233 231-244.5t49-11.5q25 0 45.75 10.25T363-217.5h129.5v-125H2.5V-400h90v-120h-90v-57.5H282L390-400h102.5q23.719 0 40.609 16.891Q550-366.219 550-342.5v125q0 23.719-16.891 40.609Q516.219-160 492.5-160H393q-2 3-3.25 5.5L387-149q0 44.583-31.277 75.792Q324.446-42 279.765-42ZM150-400h172.5l-72.276-120H150v120ZM280-99.5q20.5 0 35-14.5t14.5-35q0-20.5-14.5-35t-35-14.5q-20.5 0-35 14.5t-14.5 35q0 20.5 14.5 35t35 14.5ZM247.5-280Z" />
    </svg>
  );
};

export default AdventureIcon;
