export const base64ToBlob = (
  base64Data: string,
  contentType: string = "image/jpeg"
) => {
  const byteCharacters = atob(
    base64Data.replace(/^data:image\/\w+;base64,/, "")
  );
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: contentType });
  return blob;
};
