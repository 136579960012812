const HomeIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M164.5-124.5v-474L480-835l315.5 236.833V-124.5h-234v-278H399v278H164.5Z" />
    </svg>
  );
};

export default HomeIconFilled;
