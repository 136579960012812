import { Card, Col, Row } from "react-bootstrap";

function FormErrorMessage(props: any) {
  return (
    <>
      {props.shouldNotIncludeWrapper ? (
        <small
          className={`text-danger mt-1 ${props.errorMessage ? "" : "hidden"}`}
        >
          <div style={{ lineHeight: "1rem" }}>{props.errorMessage}</div>
        </small>
      ) : (
        <Row
          className={`mt-1 ${props.errorMessage ? "" : "hidden"}`}
          style={{ width: "100%" }}
        >
          <Col>
            <small className="text-danger">
              <div style={{ lineHeight: "1rem" }}>{props.errorMessage}</div>
            </small>
          </Col>
        </Row>
      )}
    </>
  );
}

export default FormErrorMessage;
