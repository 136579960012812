import { useContext } from "react";
import { Context } from "../../../context/context";
import { useNavigate } from "react-router";
import useAuthHook from "../../../auth/authWrapper/hook/useAuthHook";

const useSidebarItemsComponentHook = (props: any) => {
  const { ifAuthenticated, isNarrowScreen } = useContext(Context);
  const { logout } = useAuthHook();
  const history = useNavigate();
  const {
    firstItem,
    openSidebar,
    setOpenSidebar,
    openSidebarItem,
    setOpenSidebarItem,
    icon,
    closeAllSidebarItems,
    title,
    subCategories,
    categoryAlias,
    shouldTitleBeBold,
  } = props;

  const handleOpenSidebarItem = () => {
    closeAllSidebarItems();
    setOpenSidebarItem(!openSidebarItem);
  };
  const handleOpenSidebarWhenClickedOnSidebarItemIcon = (
    categoryAlias: any
  ) => {
    handleOpenSidebarItem();
    if (categoryAlias === "home" && isNarrowScreen === "true") {
      setOpenSidebar(false);
    } else if (!openSidebar) {
      setOpenSidebar(true);
    }
    //handle the padding left for narrow screens
    const width = window.innerWidth;
    var element: any = document.getElementById("closeSidebarButton");
    if (width < 600 && !openSidebar) {
      element?.classList.add("marginLeftToSidebarMainButtonOnNarrowScreen");
    }
  };
  const whichCircle = (e: any) => {
    e.target.children[0]?.classList.toggle("hidden");
    e.target.children[1]?.classList.toggle("hidden");
  };

  const canShowSidebarItem = (subCategoryAlias: any) => {
    if (
      (ifAuthenticated === "true" && subCategoryAlias === "login") ||
      (ifAuthenticated === "true" && subCategoryAlias === "register") ||
      (ifAuthenticated === "true" && subCategoryAlias === "contact-us") ||
      (ifAuthenticated === "true" &&
        subCategoryAlias === "terms-and-conditions") ||
      ((ifAuthenticated === "false" || ifAuthenticated === "notSet") &&
        subCategoryAlias === "dashboard") ||
      ((ifAuthenticated === "false" || ifAuthenticated === "notSet") &&
        subCategoryAlias === "logout")
    ) {
      return false;
    }
    return true;
  };

  const goToMainCategoryPage = (mainCategoryPage: any) => {
    history(mainCategoryPage);
  };

  const goToItemCategoryPage = (subCategoryAlias: any) => {
    if (subCategoryAlias === "logout") {
      logout();
      if (isNarrowScreen === "true") {
        setOpenSidebar(false);
        closeAllSidebarItems();
      }
      return;
    }
    if (categoryAlias != "user-dashboard") {
      history(`advertisement/${categoryAlias}/${subCategoryAlias}`);
      if (isNarrowScreen === "true") {
        setOpenSidebar(false);
        closeAllSidebarItems();
      }
      return;
    }
    history(`/${subCategoryAlias}`);
    if (isNarrowScreen === "true") {
      setOpenSidebar(false);
      closeAllSidebarItems();
    }
  };
  return {
    firstItem,
    handleOpenSidebarWhenClickedOnSidebarItemIcon,
    icon,
    openSidebar,
    handleOpenSidebarItem,
    title,
    openSidebarItem,
    subCategories,
    canShowSidebarItem,
    goToItemCategoryPage,
    whichCircle,
    shouldTitleBeBold,
    categoryAlias,
    goToMainCategoryPage,
    isNarrowScreen,
    closeAllSidebarItems,
    setOpenSidebar,
  };
};

export default useSidebarItemsComponentHook;
