import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import useAdvertisementPageHook from "../../pages/advertisement/hook/useAdvertisementPageHook";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import AdvertisementShow from "../../sharedComponents/advertisementShow";
import { Context } from "../../context/context";
import { imageUrlToBase64 } from "../../util/imageUrlToBase64";
import randomstring from "randomstring";

function AdvertisementEditedVersionShow() {
  const {
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
    openSidebar,
  } = useContext(Context);
  const {
    ifAuthenticated,
    ifSuperAdmin,
    findAdvertisementInFrontendById,
    // findAdvertisementVisibility,
  } = useAdvertisementPageHook();

  const [imagesArray, setImagesArray] = useState<any[]>([]);

  const [selectedAdvertisement, setSelectedAdvertisement] = useState<any>(null);
  // const [
  //   selectedAdvertisementVisibilityStatus,
  //   setSelectedAdvertisementVisibilityStatus,
  // ] = useState<any>(null);
  let { editedAdvertisementId, advertisementId } = useParams();

  useEffect(() => {
    if (editedAdvertisementId) {
      (async () => {
        try {
          setSelectedAdvertisement(
            await findAdvertisementInFrontendById(editedAdvertisementId)
          );
          // setSelectedAdvertisementVisibilityStatus(
          //   await findAdvertisementVisibility(editedAdvertisementId)
          // );
          const response: any = await axios.get(
            `advertisements/get-advertisement-images/${editedAdvertisementId}`
          );
          const images = await Promise.all(
            response.data.images.map(async (obj: any, index: any) => {
              if (index === 0) {
                obj.id = "img_cover";
              } else {
                obj.id = `img_${randomstring.generate(12)}`;
              }
              const storedImageInS3 = await axios.get(
                `advertisements/get-advertisement-object-by-s3-url/${editedAdvertisementId}/${index}`
              );
              obj.src =
                "data:image/jpeg;base64," + storedImageInS3.data.imageObject;
              obj.data =
                "data:image/jpeg;base64," + storedImageInS3.data.imageObject;

              return obj;
            })
          );
          setImagesArray(images);
        } catch (e: any) {}
      })();
    }
  }, [
    editedAdvertisementId,
    advertisementId,
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
  ]);

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ADMIN_URL, true],
          [routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL, true],
          [
            advertisementId,
            true,
            "current-advertisement",
            `/dashboard/admin/admin-advertisements-manage/${advertisementId}`,
          ],
          ["edited-version", false],
        ]}
      ></BreadCrumb>

      <GeneralContainerRowColCard
        colMd={openSidebar ? 12 : 12}
        colLg={openSidebar ? 12 : 12}
        colXl={openSidebar ? 12 : 12}
      >
        {selectedAdvertisement && selectedAdvertisement !== "notSet" ? (
          <AdvertisementShow
            selectedAdvertisementId={selectedAdvertisement.id}
            advertisementTitle={selectedAdvertisement.advertisementTitle}
            advertisementDescription={
              selectedAdvertisement.advertisementDescription
            }
            businessPhoneNumber={selectedAdvertisement.businessPhoneNumber}
            businessAddress={selectedAdvertisement.businessAddress}
            businessState={selectedAdvertisement.businessState}
            businessRegion={selectedAdvertisement.businessRegion}
            websiteLink={selectedAdvertisement.websiteLink}
            businessEmail={selectedAdvertisement.businessEmail}
            abn={selectedAdvertisement.abn}
            imagesArray={imagesArray}
          />
        ) : (
          // : selectedAdvertisementVisibilityStatus === "notFoundInBackend" ? (
          //   "Couldn't find the joy, it might be deleted"
          // ) : ifAuthenticated === "true" &&
          //   ifSuperAdmin === "false" &&
          //   selectedAdvertisementVisibilityStatus === "onlyVisibleToAdmins" ? (
          //   "You should be an admin to be able to see this advertisement"
          // )
          "Loading ....."
        )}
      </GeneralContainerRowColCard>
    </>
  );
}

export default AdvertisementEditedVersionShow;
