import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/context";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import axios from "axios";
import { useParams } from "react-router";
import { PasswordResetSuccessfulModal } from "./component/modals";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import Recaptcha from "../recaptcha/recaptcha";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../../util/inputFieldErrorMapper";
import { frontendInputFields } from "../../util/frontendInputFields";

function SetNewPasswordInbound() {
  let { email, hash } = useParams();

  const { isNarrowScreen } = useContext(Context);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const recaptchaRef = useRef<any>();
  const [isDuringFormSubmission, setIsDuringFormSubmission] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setResetPasswordError([]);
    setIsDuringFormSubmission(true);
    const token = await recaptchaRef.current.executeAsync();
    axios
      .post("auth/set-new-password-inbound", {
        email,
        hash,
        newPassword: password,
        confirmNewPassword: confirmPassword,
        recaptchaToken: token,
      })
      .then(function (response) {
        setIsDuringFormSubmission(false);
        if (response?.status === 200) {
          setModalShow(true);
        }
      })
      .catch((error: any) => {
        setIsDuringFormSubmission(false);
        setResetPasswordError(error?.response?.data?.message);
      });
  };

  useEffect(() => {}, [isNarrowScreen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.SET_NEW_PASSWORD_LISTENER_URL, false],
        ]}
      ></BreadCrumb>
      <PasswordResetSuccessfulModal
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <GeneralContainerRowColCard>
        <Form onSubmit={handleSubmit}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control type="text" value={email} disabled={true} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="New Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                resetPasswordError,
                frontendInputFields.USER_NEW_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                resetPasswordError,
                frontendInputFields.USER_NEW_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                resetPasswordError,
                frontendInputFields.USER_CONFIRM_NEW_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                resetPasswordError,
                frontendInputFields.USER_CONFIRM_NEW_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringFormSubmission}
          >
            Send
          </Button>
        </Form>
      </GeneralContainerRowColCard>
    </>
  );
}

export default SetNewPasswordInbound;
