const AdventureIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M770-321.5q-72 0-121.5-44T585-479H409l-34-57.5h210q3-27.5 15.5-54.75t32-47.75H313l-35-57.5h388L610.436-860H440v-57.5h170.5q18.985 0 33.992 11 15.008 11 20.508 28L726.879-697H760q86.037 0 141.769 51.37Q957.5-594.26 957.5-508.88q0 76.38-55.5 131.88t-132 55.5Zm0-57.5q52.5 0 91.25-38.75T900-509q0-56.5-36.75-93.25T770-639q-1 0-10.5.5t-10 .5l40 109-53.5 20-41-111q-28.024 22.879-41.512 49.959Q640-542.961 640-509.145 640-455 678-417q38 38 92 38ZM279.882-42Q235.5-42 204.25-73.333 173-104.667 173-149v-11H2.5v-240h90.25v-120H2.5v-57.5H282L390-400h102.5q24.031 0 40.766 16.734Q550-366.531 550-342.5v125q0 24.031-16.734 40.766Q516.531-160 492.5-160H393q-2 3-3.25 5.5L387-149q0 44.333-31.368 75.667Q324.265-42 279.882-42ZM150-400h172.5l-72.276-120H150v120ZM280-99.5q20.5 0 35-14.5t14.5-35q0-20.5-14.5-35t-35-14.5q-20.5 0-35 14.5t-14.5 35q0 20.5 14.5 35t35 14.5Z" />
    </svg>
  );
};

export default AdventureIconFilled;
