import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

function DashboardCardComponent(props: any) {
  const { header, text, link, border } = props;
  const history = useNavigate();
  const goToDashboardCategory = (link: any) => {
    history(link);
  };
  return (
    <Card
      border={border}
      className="shadow pointerOnHover advertisementCard"
      onClick={() => goToDashboardCategory(link)}
    >
      <Card.Header as="h5" className="text-center">
        {header}
      </Card.Header>
      <Card.Body>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default DashboardCardComponent;
