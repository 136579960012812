import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import useAdvertisementPageHook from "../advertisement/hook/useAdvertisementPageHook";
import Image from "react-bootstrap/Image";
import { Context } from "../../context/context";
import AdvertisementCardComponent from "../advertisement/component/advertisementCardComponent";
import AdvertisementCardPlaceholderComponent from "../../sharedComponents/advertisementCardPlaceholder";
import _ from "lodash";
import AdvertisementCategoryIsEmptyComponent from "../advertisement/component/advertisementCategoryIsEmpty";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";
import LoginOrRegister from "../../sharedComponents/loginOrRegister";

function AdvertisementShowBookmark() {
  const history = useNavigate();
  const { allAdvertisements, bookmarkedAdvertisements } = useContext(Context);
  const bookmarkedAdvertisementsToBeShown = _.filter(allAdvertisements, (obj) =>
    _.includes(_.map(bookmarkedAdvertisements, "id"), obj.id)
  );
  const { ifAuthenticated } = useContext(Context);

  const goToLoginPage = () => {
    history("/login");
  };

  const goToRegisterPage = () => {
    history("/register");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.BOOKMARKED_ADVERTISEMENTS_URL, false],
        ]}
      ></BreadCrumb>
      {ifAuthenticated === "true" ? (
        <GeneralCardLayoutWrapper>
          {bookmarkedAdvertisements !== "notSet" ? (
            <>
              {/* @ts-ignore */}
              {_.isArray(bookmarkedAdvertisementsToBeShown) &&
              !_.isEmpty(bookmarkedAdvertisementsToBeShown) ? (
                bookmarkedAdvertisementsToBeShown.map(
                  (item: any, index: any) => (
                    <AdvertisementCardComponent
                      key={index}
                      src={`${process.env.REACT_APP_S3_ROUTE}/advertisements/${item.id}/img_0.jpg`}
                      title={item.advertisementTitle}
                      id={item.id}
                      description={item.advertisementDescription}
                    />
                  )
                )
              ) : (
                <AdvertisementCategoryIsEmptyComponent
                  title="Nothing Bookmarked Yet"
                  text="You can bookmark any advertisement by clicking on the start on top right of it and find it here later"
                />
              )}
            </>
          ) : (
            <>
              <AdvertisementCardPlaceholderComponent />
            </>
          )}
        </GeneralCardLayoutWrapper>
      ) : (
        <LoginOrRegister />
      )}
    </>
  );
}

export default AdvertisementShowBookmark;
