import { Alert, Card } from "react-bootstrap";
import parse from "html-react-parser";

function AdvertisementRequestChangeReason(props: any) {
  const { advertisementRequestChangeReasonText } = props;
  return (
    advertisementRequestChangeReasonText && (
      <>
        <Card
          border="secondary"
          style={{ width: "100%", marginBottom: "20px" }}
        >
          <Card.Body>
            <Alert
              variant="light"
              className="border textNotSelectable"
              style={{ background: "#f8f9fa", marginBottom: 0 }}
            >
              <div style={{ marginBottom: "10px" }}>
                <b style={{ color: "red" }}>
                  Please consider addressing the below items while editing your
                  advertisement:
                </b>
              </div>

              {parse(
                advertisementRequestChangeReasonText.replaceAll(
                  "*****",
                  "<br />"
                )
              )}
            </Alert>
          </Card.Body>
        </Card>
      </>
    )
  );
}

export default AdvertisementRequestChangeReason;
