import { Col } from "react-bootstrap";

function GeneralCol({ children, ...props }: any) {
  const { colMd, colLg, colXl } = props;
  return (
    <Col md={colMd} lg={colLg} xl={colXl} className="general-columns">
      {children}
    </Col>
  );
}

GeneralCol.defaultProps = {
  colMd: 8,
  colLg: 5,
  colXl: 4,
};

export default GeneralCol;
