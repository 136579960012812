import { Card, Col, Row } from "react-bootstrap";
import GeneralContainerRowColCard from "./generalContainerRowColCard";
import { useContext } from "react";
import { Context } from "../context/context";
import { useNavigate } from "react-router";
import GeneralContainerRowCol from "./generalContainerRowCol";

function LoginOrRegister() {
  const { openSidebar } = useContext(Context);
  const history = useNavigate();
  const goToLoginPage = () => {
    history("/login");
  };

  const goToRegisterPage = () => {
    history("/register");
  };
  return (
    <GeneralContainerRowColCard
      colMd={openSidebar ? 12 : 12}
      colLg={openSidebar ? 12 : 12}
      colXl={openSidebar ? 12 : 12}
    >
      <div>
        Please{" "}
        <span className="text-primary pointerOnHover" onClick={goToLoginPage}>
          {" "}
          Login
        </span>{" "}
        or{" "}
        <span
          className="text-primary pointerOnHover"
          onClick={goToRegisterPage}
        >
          Register
        </span>{" "}
        first
      </div>
    </GeneralContainerRowColCard>
  );
}

export default LoginOrRegister;
