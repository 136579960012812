import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Context } from "../../context/context";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import _ from "lodash";
import { GeneralModal } from "../../sharedComponents/modals";
import { base64ToBlob } from "../../util/base64ToBlob";
import AdvertisementForm from "../../sharedComponents/advertisementForm";

const AdvertisementCreate = () => {
  const recaptchaRef = useRef<any>();
  const [advertisementFormError, setAdvertisementFormError] = useState([]);
  const [mainCategory, setMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [advertisementDescription, setAdvertisementDescription] = useState("");
  const [advertisementTitle, setAdvertisementTitle] = useState("");
  const [abn, setAbn] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessRegion, setBusinessRegion] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [imagesArray, setImagesArray] = useState<any[]>([]);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [imagesThumbnail, setImagesThumbnail] = useState<any[]>([]);
  const [isDuringFormSubmission, setIsDuringFormSubmission] = useState(false);
  const [advertisementCreateModalShow, setAdvertisementCreateModalShow] =
    useState(false);
  const [advertisementCreateModalMessage, setAdvertisementCreateModalMessage] =
    useState("");
  const [isAbnActive, setIsAbnActive] = useState("notSet");
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      setImagesThumbnail([]);
      setImagesArray([]);
    };
  }, []);

  const {
    shouldFetchAuthInitialStates,
    setShouldFetchAuthInitialStates,
    setShouldFetchPublicInitialStates,
  } = useContext(Context);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsDuringFormSubmission(true);
    setAdvertisementFormError([]);
    const token = await recaptchaRef.current.executeAsync();
    try {
      const result = await axios.post("advertisements/create", {
        mainCategory: parseInt(mainCategory),
        subCategory: parseInt(subCategory),
        advertisementDescription,
        advertisementTitle,
        abn: parseInt(abn),
        websiteLink: websiteLink.length
          ? websiteLink.toLowerCase().includes("http")
            ? websiteLink.toLowerCase()
            : "https://" + websiteLink.toLowerCase()
          : `${process.env.REACT_APP_DEFAULT_FRONTEND_URL}`,
        businessEmail:
          businessEmail ||
          `${process.env.REACT_APP_DEFAULT_WHERETOJOY_MAIN_EMAIL}`,
        businessAddress: businessAddress,
        businessState,
        businessRegion,
        businessPhoneNumber,
        advertisementToBeEditedId: null,
        hasImageCover: imagesArray.some((item) => item.id === "img_cover"),
        hasSupportingImages: imagesArray.length > 1,
        recaptchaToken: token,
      });
      setAdvertisementCreateModalShow(true);
      setAdvertisementCreateModalMessage("Loading ...");
      const advertisementId = result.data.advertisementId;
      const editedAdvertisementId = result.data.editedAdvertisementId;
      const uploadUrlsArrays = await axios.all(
        imagesArray.map((image, index) => {
          return axios.post("advertisements/get-presigned-url", {
            advertisementId: editedAdvertisementId || advertisementId,
            index,
          });
        })
      );
      try {
        await axios.all(
          uploadUrlsArrays.map((uploadUrl, index) => {
            return axios
              .put(
                uploadUrl.data.uploadUrl,
                base64ToBlob(imagesArray[index].data, "image/jpeg"),
                {
                  withCredentials: false,
                  headers: {
                    "Content-Type": "image/jpeg",
                  },
                }
              )
              .catch((error) => {
                throw new Error(
                  `Error uploading image ${index + 1}: ${error.message}`
                );
              });
          })
        );
      } catch (error: any) {
        console.error(error.message);
      }
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
      setAdvertisementCreateModalShow(false);
      if (!shouldFetchAuthInitialStates && !shouldFetchAuthInitialStates) {
        history(
          `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADVERTISEMENT_MANAGE_URL}/${advertisementId}`
        );
      }
    } catch (error: any) {
      setIsDuringFormSubmission(false);
      setAdvertisementFormError(error?.response?.data?.message || []);
    }
  };

  const imagesArrayPlaceholder = [
    {
      src: "/image-placeholder-advertisement-card-sample.png",
      data: "/image-placeholder-advertisement-card-sample.png",
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ADVERTISEMENT_CREATE_URL, false],
        ]}
      ></BreadCrumb>
      <GeneralModal
        modalShow={advertisementCreateModalShow}
        setModalShow={setAdvertisementCreateModalShow}
        message={advertisementCreateModalMessage}
      />
      <AdvertisementForm
        handleSubmit={handleSubmit}
        imagesArrayPlaceholder={imagesArrayPlaceholder}
        advertisementFormError={advertisementFormError}
        mainCategory={mainCategory}
        setMainCategory={setMainCategory}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
        advertisementDescription={advertisementDescription}
        setAdvertisementDescription={setAdvertisementDescription}
        advertisementTitle={advertisementTitle}
        setAdvertisementTitle={setAdvertisementTitle}
        abn={abn}
        setAbn={setAbn}
        websiteLink={websiteLink}
        setWebsiteLink={setWebsiteLink}
        businessEmail={businessEmail}
        setBusinessEmail={setBusinessEmail}
        businessAddress={businessAddress}
        setBusinessAddress={setBusinessAddress}
        businessState={businessState}
        setBusinessState={setBusinessState}
        businessRegion={businessRegion}
        setBusinessRegion={setBusinessRegion}
        businessPhoneNumber={businessPhoneNumber}
        setBusinessPhoneNumber={setBusinessPhoneNumber}
        imagesArray={imagesArray}
        setImagesArray={setImagesArray}
        previewCanvasRef={previewCanvasRef}
        imagesThumbnail={imagesThumbnail}
        setImagesThumbnail={setImagesThumbnail}
        isDuringFormSubmission={isDuringFormSubmission}
        submitButtonWording="Create"
        isAbnActive={isAbnActive}
        setIsAbnActive={setIsAbnActive}
        recaptchaRef={recaptchaRef}
      />
    </>
  );
};

export default AdvertisementCreate;
