import { Card, Col, Container, Row } from "react-bootstrap";
import GeneralContainer from "./generalContainer";
import GeneralRow from "./generalRow";
import GeneralCol from "./generalCol";
import GeneralCard from "./generalCard";

function GeneralContainerRowColCard({ children, ...props }: any) {
  const { colMd, colLg, colXl, cardBorder, cardPaddingLeft, cardPaddingRight } =
    props;
  return (
    <GeneralContainer>
      <GeneralRow>
        <GeneralCol colMd={colMd} colLg={colLg} colXl={colXl}>
          <GeneralCard
            cardBorder={cardBorder}
            cardPaddingLeft={cardPaddingLeft}
            cardPaddingRight={cardPaddingRight}
          >
            {children}
          </GeneralCard>
        </GeneralCol>
      </GeneralRow>
    </GeneralContainer>
  );
}

export default GeneralContainerRowColCard;
