import { Form, FormGroup } from "react-bootstrap";
import { advertisementRegionsByState } from "../../util/advertisementRegionsByState";
import { createTitleFromAlias } from "../../util/createTitleFromAlias";
import { useEffect, useState } from "react";
import GeneralContainerRowCol from "../../sharedComponents/generalContainerRowCol";
import _ from "lodash";
import { advertisementStatuses } from "../../util/advertisementStatuses";
import { currentCategoriesAndSubCategoriesMain } from "../../util/currentCategoriesAndSubcategories";
import { calculateDropdownWidth } from "../../util/calculateDropdownWidth";

export function AdminAdvertisementFilter({ ...props }) {
  const [state, setState] = useState("-1");
  const [region, setRegion] = useState("-1");
  const [mainCategory, setMainCategory] = useState("-1");
  const [subCategory, setSubCategory] = useState("-1");
  const [status, setStatus] = useState("-100");

  const {
    adminAllAdvertisements,
    setAdminAdvertisementsFiltered,
    adminAllEditedAdvertisements,
  } = props;

  useEffect(() => {
    if (adminAllAdvertisements === "notSet") {
      setAdminAdvertisementsFiltered("notSet");
    } else {
      let filteredAds = adminAllAdvertisements;

      if (state !== "-1") {
        filteredAds = filteredAds.filter(
          (advertisement: any) =>
            advertisement.businessState?.toLowerCase() === state.toLowerCase()
        );
      }

      if (region !== "-1") {
        filteredAds = filteredAds.filter(
          (advertisement: any) =>
            advertisement.businessRegion?.toLowerCase() === region.toLowerCase()
        );
      }

      if (status !== "-100") {
        if (status === advertisementStatuses.hasEditedVersion.toString()) {
          filteredAds = filteredAds.filter((adminAllAdvertisementsItem: any) =>
            adminAllEditedAdvertisements.some(
              (editedAdvertisement: any) =>
                editedAdvertisement.advertisementId ===
                adminAllAdvertisementsItem.id
            )
          );
        } else if (
          status ===
          advertisementStatuses.pendingAfterEditedRequestChangeAddressed.toString()
        ) {
          console.log("are we here?");
          filteredAds = filteredAds.filter((adminAllAdvertisementsItem: any) =>
            adminAllEditedAdvertisements.some(
              (editedAdvertisement: any) =>
                editedAdvertisement.advertisementId ===
                  adminAllAdvertisementsItem.id &&
                editedAdvertisement.advertisementStatus.toString() ===
                  advertisementStatuses.pendingAfterRequestChangeAddressed.toString()
            )
          );
        } else {
          filteredAds = filteredAds.filter(
            (advertisement: any) =>
              advertisement.advertisementStatus?.toString() ===
              status.toString()
          );
        }
      }

      if (mainCategory !== "-1") {
        filteredAds = filteredAds.filter(
          (advertisement: any) =>
            advertisement.advertisementCategory?.toString() ===
            mainCategory.toString()
        );
      }

      if (subCategory !== "-1") {
        filteredAds = filteredAds.filter(
          (advertisement: any) =>
            advertisement.advertisementSubCategory?.toString() ===
            subCategory.toString()
        );
      }
      setAdminAdvertisementsFiltered("loadingFilteredAdvertisements");
      setTimeout(function () {
        setAdminAdvertisementsFiltered(filteredAds);
      }, 1000);
    }
  }, [
    adminAllAdvertisements,
    state,
    region,
    status,
    mainCategory,
    subCategory,
    setAdminAdvertisementsFiltered,
  ]);

  const statesOptions = (
    <>
      <option id="state-1" value="-1">
        All
      </option>
      {advertisementRegionsByState.map((state) => (
        <option
          id={`state${state.shortName.toLowerCase()}`}
          key={state.id}
          value={state.shortName.toLowerCase()}
        >
          {state.shortName.toUpperCase()}
        </option>
      ))}
    </>
  );

  const regionsOptions =
    state && state !== "-1" ? (
      <>
        <option id="region-1" value="-1">
          All
        </option>
        {/* @ts-ignore */}
        {advertisementRegionsByState
          .find(
            (states) => states.shortName.toLowerCase() == state.toLowerCase()
          )
          .regions.map((region, index) => (
            <option id={`region${region}`} key={index} value={region}>
              {createTitleFromAlias(region)}
            </option>
          ))}
      </>
    ) : (
      <option value="-1">All</option>
    );

  const statusOptions = (
    <>
      <option id="status-100" value="-100">
        All
      </option>
      {Object.keys(advertisementStatuses).map((key) => (
        <option
          // @ts-ignore
          id={`status${advertisementStatuses[key]}`}
          key={key}
          // @ts-ignore
          value={advertisementStatuses[key]}
        >
          {createTitleFromAlias(key)}
        </option>
      ))}
    </>
  );

  const mainCategoryOptions = (
    <>
      <option id="mainCategory-1" value="-1">
        All
      </option>
      {/* @ts-ignore */}
      {currentCategoriesAndSubCategoriesMain.map((category) => (
        <option
          id={`mainCategory${category.id}`}
          key={category.id}
          value={category.id}
        >
          {createTitleFromAlias(category.categoryAlias)}
        </option>
      ))}
    </>
  );

  const subCategoryOptions =
    mainCategory && mainCategory !== "-1" ? (
      <>
        <option id="subCategory-1" value="-1">
          All
        </option>
        {/* @ts-ignore */}
        {currentCategoriesAndSubCategoriesMain
          .find((cat: any) => cat.id.toString() === mainCategory.toString())
          ?.subCategories.map((subCategory: any) => (
            <option
              id={`subCategory${subCategory.id}`}
              key={subCategory.id}
              value={subCategory.id}
            >
              {createTitleFromAlias(subCategory.subCategoryAlias)}
            </option>
          ))}
      </>
    ) : (
      <option value="-1">All</option>
    );

  return (
    <>
      <GeneralContainerRowCol colMd={12} colLg={12} colXl={12}>
        <FormGroup className="mb-2" style={{ display: "inline-block" }}>
          <Form.Label className="me-1">Status</Form.Label>
          <Form.Select
            size="sm"
            className="me-3"
            style={{
              width: calculateDropdownWidth(`status${status}`),
              display: "inline-block",
            }}
            aria-label="status"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          >
            {statusOptions}
          </Form.Select>
        </FormGroup>
        <FormGroup className="mb-2" style={{ display: "inline-block" }}>
          <Form.Label className="me-1">MainCategory</Form.Label>
          <Form.Select
            size="sm"
            className="me-3"
            style={{
              width: calculateDropdownWidth(`mainCategory${mainCategory}`),
              display: "inline-block",
            }}
            aria-label="category"
            onChange={(e) => {
              setSubCategory("-1");
              setMainCategory(e.target.value);
            }}
            value={mainCategory}
          >
            {mainCategoryOptions}
          </Form.Select>
        </FormGroup>
        <FormGroup className="mb-2" style={{ display: "inline-block" }}>
          <Form.Label className="me-1">Subcategory</Form.Label>
          <Form.Select
            size="sm"
            className="me-3"
            style={{
              width: calculateDropdownWidth(`subCategory${subCategory}`),
              display: "inline-block",
            }}
            aria-label="subcategory"
            onChange={(e) => setSubCategory(e.target.value)}
            value={subCategory}
          >
            {subCategoryOptions}
          </Form.Select>
        </FormGroup>
        <FormGroup className="mb-2" style={{ display: "inline-block" }}>
          <Form.Label className="me-1">State</Form.Label>
          <Form.Select
            size="sm"
            className="me-3"
            style={{
              width: calculateDropdownWidth(`state${state}`),
              display: "inline-block",
            }}
            aria-label="state"
            onChange={(e) => {
              setRegion("-1");
              setState(e.target.value);
            }}
            value={state}
          >
            {statesOptions}
          </Form.Select>
        </FormGroup>
        <FormGroup className="mb-2" style={{ display: "inline-block" }}>
          <Form.Label className="me-1">Region</Form.Label>
          <Form.Select
            size="sm"
            className="me-3"
            style={{
              width: calculateDropdownWidth(`region${region}`),
              display: "inline-block",
            }}
            aria-label="region"
            onChange={(e) => setRegion(e.target.value)}
            value={region}
          >
            {regionsOptions}
          </Form.Select>
        </FormGroup>
      </GeneralContainerRowCol>
    </>
  );
}
