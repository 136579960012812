import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/context";
import { useNavigate } from "react-router-dom";

const useAuthHook = () => {
  const history = useNavigate();
  const {
    ifAuthenticated,
    setIfAuthenticated,
    currentUser,
    setCurrentUser,
    setIfSuperAdmin,
  } = useContext(Context);

  const checkIfAuthenticated = () => {
    axios.get("ifAuthenticated").then(function (response) {
      if (response?.data?.userId && response.status === 200) {
        setIfAuthenticated("true");
        setCurrentUser(response?.data);
        if (
          response?.data?.permissions &&
          response?.data?.permissions.includes("superAdmin")
        ) {
          setIfSuperAdmin("true");
        } else {
          setIfSuperAdmin("false");
        }
      } else {
        setIfAuthenticated("false");
        setIfSuperAdmin("false");
        setCurrentUser("notAuthenticated");
      }
    });
  };

  const logout = () => {
    axios.get("auth/logout").then(function (response) {
      if (response?.data?.logged_out && response.status === 200) {
        setIfAuthenticated("false");
        setIfSuperAdmin("false");
        setCurrentUser("notAuthenticated");
        history("/login");
      }
    });
  };

  return {
    ifAuthenticated,
    setIfAuthenticated,
    setIfSuperAdmin,
    currentUser,
    setCurrentUser,
    checkIfAuthenticated,
    logout,
  };
};

export default useAuthHook;
