import { Card } from "react-bootstrap";
import AdvertisementCardImageComponent from "./advertisementCardImage";
import _ from "lodash";
import { useEffect } from "react";

function AdvertisementCardPlaceholderComponent(props: any) {
  const { src, title, description } = props;
  function generateRandomArray(n: any) {
    return _.times(n, () => Math.floor(Math.random() * 100));
  }
  const randomArray = generateRandomArray(20);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {randomArray.map((item, index) => {
        return (
          <Card
            key={index}
            className="advertisementCard shadow pointerOnHover img-fluid"
          >
            <AdvertisementCardImageComponent emptyImage={true} />
            <Card.Body>
              <Card.Title className="text-truncate text-with-background">
                loading loading
              </Card.Title>
              <Card.Text className="text-truncate-container text-with-background">
                loading loading loading loading loading loading loading loading
                loading loading loading loading loading loading loading loading
              </Card.Text>
              {/* <h5 className="card-title placeholder-glow">
                <span className="placeholder col-6"></span>
              </h5>
              <p className="card-text placeholder-glow">
                <span className="placeholder col-7"></span>
                <span className="placeholder col-6"></span>
              </p> */}
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
}

export default AdvertisementCardPlaceholderComponent;
