import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import FormErrorMessage from "./formErrorMessage";
import { Form } from "react-bootstrap";
import { useState } from "react";
import Recaptcha from "../auth/recaptcha/recaptcha";
import CharacterCounter from "./characterCounter";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../util/inputFieldErrorMapper";
import { frontendInputFields } from "../util/frontendInputFields";
import TermsAndConditions from "./termsAndConditions";
import { isWebView } from "../util/isWebView";

export const GeneralModal = (props: any) => {
  const { modalShow, setModalShow } = props;
  const closeModal = () => {
    setModalShow(false);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };
  const showModal = () => {
    setModalShow(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };
  return (
    <Modal
      show={modalShow}
      size="sm"
      centered
      onHide={closeModal}
      onShow={showModal}
    >
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} size="sm">
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const RedirectToRegisterPage = (props: any) => {
  const { modalShow, setModalShow } = props;
  const closeModal = () => {
    setModalShow(false);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };
  const showModal = () => {
    setModalShow(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };
  const history = useNavigate();
  const goToRegisterPage = () => {
    closeModal();
    history("/register");
  };
  return (
    <Modal
      show={modalShow}
      size="sm"
      centered
      onHide={closeModal}
      onShow={showModal}
    >
      <Modal.Body>
        You've not registered in this website before, please register
        <span
          className="text-primary pointerOnHover"
          onClick={goToRegisterPage}
        >
          {" "}
          Register
        </span>{" "}
        first{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} size="sm">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const TermsAndConditionsModal = (props: any) => {
  const { modalShow, setModalShow } = props;
  const closeModal = () => {
    setModalShow(false);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };
  const showModal = () => {
    setModalShow(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };
  return (
    <Modal
      show={modalShow}
      size="lg"
      centered
      onHide={closeModal}
      onShow={showModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button size="sm" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const FunctionalityModal = (props: any) => {
  const {
    message,
    button,
    modalShow,
    setModalShow,
    url,
    extraParams,
    loginOnBehalfOfUser,
    deleteUser,
    suspendUser,
    unsuspendUser,
    resetPassUser,
    functionalityModalFunctionsList,
    history,
    functionalityModalAdvertisementError,
    setFunctionalityModalAdvertisementError,
  } = props;

  const [ifLoading, setIfLoading] = useState(false);
  const closeModal = () => {
    setModalShow(false);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };
  const showModal = () => {
    setModalShow(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };

  const removeErrorAndCloseModal = () => {
    closeModal();
    setFunctionalityModalAdvertisementError("");
  };

  const functions = async () => {
    setIfLoading(true);
    try {
      if (
        functionalityModalFunctionsList.includes("loginOnBehalfOfUser") &&
        loginOnBehalfOfUser &&
        extraParams.userId
      ) {
        await loginOnBehalfOfUser(extraParams.userId);
      }
      if (
        functionalityModalFunctionsList.includes("deleteUser") &&
        deleteUser &&
        extraParams.userId
      ) {
        await deleteUser(extraParams.userId);
      }
      if (
        functionalityModalFunctionsList.includes("suspendUser") &&
        suspendUser &&
        extraParams.userId
      ) {
        await suspendUser(extraParams.userId);
      }
      if (
        functionalityModalFunctionsList.includes("unsuspendUser") &&
        unsuspendUser &&
        extraParams.userId
      ) {
        await unsuspendUser(extraParams.userId);
      }
      if (
        functionalityModalFunctionsList.includes("resetPassUser") &&
        resetPassUser &&
        extraParams.userId
      ) {
        await resetPassUser(extraParams.userId);
      }
      if (
        functionalityModalFunctionsList.includes("history") &&
        history &&
        url
      ) {
        history(url);
      }
      setIfLoading(false);
      setModalShow(false);
    } catch (e: any) {
      setIfLoading(false);
      setFunctionalityModalAdvertisementError(
        e?.response?.data?.message || "An unknown error occurred"
      );
    }
  };

  return (
    <Modal show={modalShow} size="sm" centered onShow={showModal}>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={removeErrorAndCloseModal} size="sm">
          Cancel
        </Button>
        <Button onClick={functions} size="sm" disabled={ifLoading}>
          {ifLoading ? "Loading..." : button}
        </Button>
        <FormErrorMessage
          shouldNotIncludeWrapper={true}
          errorMessage={functionalityModalAdvertisementError}
        />
      </Modal.Footer>
    </Modal>
  );
};

export const RedirectModal = (props: any) => {
  const { message, button, modalShow, setModalShow, url } = props;
  const history = useNavigate();
  const onRedirect = (url: string) => {
    history(url);
  };
  const closeModal = () => {
    setModalShow(false);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };
  const showModal = () => {
    setModalShow(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };

  return (
    <Modal
      show={modalShow}
      size="sm"
      centered
      onHide={closeModal}
      onShow={showModal}
    >
      <Modal.Body>
        {message ||
          "Are you sure? you are about to be redirected to another page"}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} size="sm">
          Cancel
        </Button>
        <Button onClick={() => onRedirect(url)} size="sm">
          {button || "redirect"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const DeleteModal = (props: any) => {
  const {
    onDelete,
    deleteInProgress,
    show,
    onHide,
    message,
    deleteAdvertisementError,
  } = props;
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          variant="outline-dark"
          size="sm"
          disabled={deleteInProgress}
          style={{ marginBottom: 0 }}
        >
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          size="sm"
          variant="outline-danger"
          disabled={deleteInProgress}
          style={{ marginBottom: 0 }}
        >
          {deleteInProgress ? "Loading ..." : "delete"}
        </Button>
        <FormErrorMessage errorMessage={deleteAdvertisementError} />
      </Modal.Footer>
    </Modal>
  );
};

export const ArchiveModal = (props: any) => {
  const {
    onArchive,
    archiveInProgress,
    show,
    onHide,
    message,
    archiveAdvertisementError,
  } = props;
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          variant="outline-dark"
          size="sm"
          disabled={archiveInProgress}
          style={{ marginBottom: 0 }}
        >
          Cancel
        </Button>
        <Button
          onClick={onArchive}
          size="sm"
          variant="outline-danger"
          disabled={archiveInProgress}
          style={{ marginBottom: 0 }}
        >
          {archiveInProgress ? "Loading ..." : "archive"}
        </Button>
        <FormErrorMessage errorMessage={archiveAdvertisementError} />
      </Modal.Footer>
    </Modal>
  );
};

export const ChangeStatusModal = (props: any) => {
  const {
    onChangeStatus,
    changeStatusInProgress,
    modalShow,
    setModalShow,
    message,
    changeStatusAdvertisementError,
    changeStatusButtonWording,
    shouldModalIncludeTextBox,
    setModalTextBoxContent,
    recaptchaRef,
    modalTextBoxContent,
    removeErrorsAndTextAndCloseModal,
  } = props;
  const closeModal = () => {
    removeErrorsAndTextAndCloseModal();
    setModalShow(false);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };
  const showModal = () => {
    setModalShow(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };
  return (
    <Modal
      show={modalShow}
      centered
      size={shouldModalIncludeTextBox ? "lg" : "sm"}
      onHide={closeModal}
      onShow={showModal}
    >
      <Modal.Body>
        {!shouldModalIncludeTextBox && message}
        {shouldModalIncludeTextBox && (
          <>
            <Form className="mt-2">
              <Recaptcha recaptchaRef={recaptchaRef} />
              <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                <Form.Label style={{ marginBottom: 0 }}>{message}</Form.Label>
                <CharacterCounter
                  min={20}
                  max={200}
                  text={modalTextBoxContent}
                />
                <Form.Control
                  onChange={(e) => {
                    setModalTextBoxContent(
                      e.target.value.replaceAll("\n", "*****")
                    );
                  }}
                  as="textarea"
                  placeholder="type the reason ..."
                  rows={3}
                  isInvalid={inputFieldErrorMapperBoolean(
                    changeStatusAdvertisementError,
                    frontendInputFields.ADMIN_REQUEST_CHANGE_REASON
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {inputFieldErrorMapperWording(
                    changeStatusAdvertisementError,
                    frontendInputFields.ADMIN_REQUEST_CHANGE_REASON
                  )}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeModal}
          variant="outline-dark"
          size="sm"
          disabled={changeStatusInProgress}
          style={{ marginBottom: 0 }}
        >
          Cancel
        </Button>
        <Button
          onClick={onChangeStatus}
          size="sm"
          variant="outline-danger"
          disabled={changeStatusInProgress}
          style={{ marginBottom: 0 }}
        >
          {changeStatusInProgress ? "Loading ..." : changeStatusButtonWording}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
