const CircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="13"
      viewBox="0 -960 960 960"
      width="13"
      fill="white"
    >
      <path d="M480.064-85q-80.971 0-153.129-31.263-72.159-31.263-125.797-85Q147.5-255 116.25-327.023 85-399.046 85-479.936q0-81.971 31.263-154.129 31.263-72.159 85-125.547Q255-813 327.023-844q72.023-31 152.913-31 81.971 0 154.135 31.132 72.165 31.131 125.547 84.5Q813-706 844-633.977q31 72.023 31 153.913 0 80.971-31.013 153.129-31.013 72.159-84.5 125.797Q706-147.5 633.977-116.25 561.954-85 480.064-85Zm-.097-57.5Q620.5-142.5 719-241.467t98.5-238.5Q817.5-620.5 719.033-719t-239-98.5q-139.533 0-238.533 98.467t-99 239q0 139.533 98.967 238.533t238.5 99ZM480-480Z" />
    </svg>
  );
};

export default CircleIcon;
