const DogIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M195.5-81.5V-497H253v358h322.5v-223.5L677-464q30-30 45.75-68.75T738.5-614q0-42.5-16-81T677-763l-33-34-131.5 131.5h-180l-54 54.5-41-40.5 71-71.5h180L644-878.5l74 74.5q38 38 57.5 87t21 103q-1.5 54-21 103T718-424l-85 85.5v257H195.5ZM381-297 181-497q-9.5-10-14.5-22.25t-5-25.75q0-14 5-26.75T181-594l76.5-77.5L373-556q27 27 41.5 61.25T429-423q0 34.5-12.25 67.25T381-297Z" />
    </svg>
  );
};

export default DogIcon;
