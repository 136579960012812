const SnowMobileIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M320-171.5H80q-32.5 0-50.5-21.5t-18-47q0-17 8.75-33.25T47.5-300l164-89.5L16-447l71-141 356.5 39L580-650l-45.5-41.5h-83V-749h106L842-485q14.5 14 20.75 27.5T869-430q0 37.5-29.25 65.25T763-332l-43 2 108.5 101H840q18 0 32-11.25T890.5-269H949q-6 41-36.5 69.25T840-171.5H611.5V-229H744L641.5-331.5H469q0 66.5-43.25 113.25T320-171.5ZM80-229h240q38.5 0 65-29t26.5-70L289-365.5l-213 114q-3.5 2-5.25 5T69-240q0 4.5 3.25 7.75T80-229Z" />
    </svg>
  );
};

export default SnowMobileIconFilled;
