const TentIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M81.5-81.5V-263l363-491-66.5-90 47-33.5 55 75.5 56.5-75.5 46 33.5-66 90L879-263v181.5H81.5Zm240-57.5H639L480-361 321.5-139Z" />
    </svg>
  );
};

export default TentIconFilled;
