import { Container } from "react-bootstrap";

function GeneralContainer({ children, ...props }: any) {
  return (
    <Container fluid className="containerGeneral">
      {children}
    </Container>
  );
}

export default GeneralContainer;
