import { Card, Form } from "react-bootstrap";
import AdvertisementCardComponent from "../advertisement/component/advertisementCardComponent";
import { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import axios from "axios";
import useHomeHook from "./hook/useHomeHook";
import AdvertisementCardPlaceholderComponent from "../../sharedComponents/advertisementCardPlaceholder";
import { Context } from "../../context/context";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";
import _ from "lodash";

function Home() {
  const { allAdvertisements } = useContext(Context);
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPlaceholder(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", false],
          ["Recent Joys", false],
        ]}
      ></BreadCrumb>
      <GeneralCardLayoutWrapper>
        {allAdvertisements && allAdvertisements !== "notSet" ? (
          _.chain(allAdvertisements)
            .orderBy(["date_createdAt"], ["desc"])
            .take(10)
            .value()
            .map((item: any) => (
              <AdvertisementCardComponent
                key={item.id}
                src={`${process.env.REACT_APP_S3_ROUTE}/advertisements/${item.id}/img_0.jpg`}
                title={item.advertisementTitle}
                id={item.id}
                description={item.advertisementDescription}
              />
            ))
        ) : (
          <AdvertisementCardPlaceholderComponent />
        )}
      </GeneralCardLayoutWrapper>
    </>
  );
}

export default Home;
