import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/context";

const useHandleWidthHook = () => {
  const {
    openSidebar,
    setOpenSidebar,
    openSidebarItem1,
    setOpenSidebarItem1,
    openSidebarItem2,
    setOpenSidebarItem2,
    openSidebarItem3,
    setOpenSidebarItem3,
    openSidebarItem4,
    setOpenSidebarItem4,
    openSidebarItem5,
    setOpenSidebarItem5,
    openSidebarItem6,
    setOpenSidebarItem6,
    openSidebarItem7,
    setOpenSidebarItem7,
    openSidebarItem8,
    setOpenSidebarItem8,
    openSidebarItem9,
    setOpenSidebarItem9,
    openSidebarItem10,
    setOpenSidebarItem10,
    openSidebarItem11,
    setOpenSidebarItem11,
    setIsNarrowScreen,
  } = useContext(Context);

  const handleLogicOnScreenResize = () => {
    let width = window.innerWidth;
    if (width < 600) {
      setOpenSidebar(false);
      setIsNarrowScreen("true");
    } else {
      setOpenSidebar(true);
      setIsNarrowScreen("false");
    }
  };

  useEffect(() => {
    handleLogicOnScreenResize();
  }, []);

  const closeAllSidebarItems = () => {
    setOpenSidebarItem1(false);
    setOpenSidebarItem2(false);
    setOpenSidebarItem3(false);
    setOpenSidebarItem4(false);
    setOpenSidebarItem5(false);
    setOpenSidebarItem6(false);
    setOpenSidebarItem7(false);
    setOpenSidebarItem8(false);
    setOpenSidebarItem9(false);
    setOpenSidebarItem10(false);
    setOpenSidebarItem11(false);
  };

  return {
    openSidebarItem1,
    setOpenSidebarItem1,
    openSidebarItem2,
    setOpenSidebarItem2,
    openSidebarItem3,
    setOpenSidebarItem3,
    openSidebarItem4,
    setOpenSidebarItem4,
    openSidebarItem5,
    setOpenSidebarItem5,
    openSidebarItem6,
    setOpenSidebarItem6,
    openSidebarItem7,
    setOpenSidebarItem7,
    openSidebarItem8,
    setOpenSidebarItem8,
    openSidebarItem9,
    setOpenSidebarItem9,
    openSidebarItem10,
    setOpenSidebarItem10,
    openSidebarItem11,
    setOpenSidebarItem11,
    openSidebar,
    setOpenSidebar,
    handleLogicOnScreenResize,
    closeAllSidebarItems,
  };
};

export default useHandleWidthHook;
