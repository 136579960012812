import { TfiFaceSad } from "react-icons/tfi";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { useEffect } from "react";

function Error() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BreadCrumb routes={[["home", true]]}></BreadCrumb>
      <div className="align-self-center text-center">
        <TfiFaceSad style={{ fontSize: "150px" }} />
        <h2>404 NOT FOUND!</h2>
      </div>
    </>
  );
}

export default Error;
