import GeneralContainer from "./generalContainer";
import GeneralRow from "./generalRow";

function GeneralContainerRow({ children, ...props }: any) {
  return (
    <GeneralContainer>
      <GeneralRow>{children}</GeneralRow>
    </GeneralContainer>
  );
}

export default GeneralContainerRow;
