import { base64ToBlob } from "./base64ToBlob";

export async function imageCompressor(
  inputFile: any,
  maxSizeInMB: number = 0.2,
  format: string = "image/jpeg",
  quality: number = 0.8,
  scaleFactor: number = 0.05
): Promise<{ resizedBase64strReadyToBeUsedAsSrc: string }> {
  // if (
  //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     navigator.userAgent
  //   )
  // ) {
  //   maxSizeInMB = 0.5;
  //   quality = 0.6;
  // }

  if (typeof inputFile === "string") {
    inputFile = base64ToBlob(inputFile);
  }

  return new Promise<{ resizedBase64strReadyToBeUsedAsSrc: string }>(
    (resolve, reject) => {
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      const image = new Image();
      image.src = URL.createObjectURL(inputFile);

      image.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx) {
          const width = image.naturalWidth;
          const height = image.naturalHeight;
          let newWidth = width;
          let newHeight = height;
          let needToCompress = false;

          if (inputFile.size > maxSizeInBytes) {
            needToCompress = true;
            newWidth = Math.round(width * scaleFactor);
            newHeight = Math.round(height * scaleFactor);
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          ctx.drawImage(image, 0, 0, newWidth, newHeight);

          const compressedBase64 = canvas.toDataURL(format, quality);
          const compressedBlob = new Blob([compressedBase64], { type: format });
          const compressedFileSizeInBytes = compressedBlob.size;

          if (
            compressedFileSizeInBytes > maxSizeInBytes ||
            needToCompress === false
          ) {
            resolve({ resizedBase64strReadyToBeUsedAsSrc: compressedBase64 });
          } else {
            const newScaleFactor = scaleFactor + 0.05;
            imageCompressor(
              inputFile,
              maxSizeInMB,
              format,
              quality,
              newScaleFactor
            ).then(resolve, reject);
          }
        } else {
          reject(new Error("Failed to obtain canvas context."));
        }
      };
    }
  );
}
