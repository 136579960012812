import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import _ from "lodash";
import { Context } from "../../context/context";
import axios from "axios";
import AdvertisementCardComponent from "../advertisement/component/advertisementCardComponent";
import useAdvertisementCategoryPageHook from "./hook/useAdvertisementCategoryPageHook";
import AdvertisementCardPlaceholderComponent from "../../sharedComponents/advertisementCardPlaceholder";
import { Card } from "react-bootstrap";
import { createTitleFromAlias } from "../../util/createTitleFromAlias";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";

const AdvertisementCategoryPage = () => {
  const history = useNavigate();
  const { advertisementCategory, relatedSubCategories } =
    useAdvertisementCategoryPageHook();

  const showAllAdvertisementsOfCategory = (subCategoryAlias: any) => {
    history(subCategoryAlias);
  };
  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [advertisementCategory, false],
        ]}
      ></BreadCrumb>
      <GeneralCardLayoutWrapper>
        {relatedSubCategories.map((relatedCategory: any, index: any) => (
          <Card
            className="shadow pointerOnHover advertisementCard"
            key={index}
            onClick={() =>
              showAllAdvertisementsOfCategory(relatedCategory.subCategoryAlias)
            }
            style={{ backgroundColor: "" }}
          >
            <Card.Body>
              <Card.Title className="text-center mb-0">
                {createTitleFromAlias(relatedCategory.subCategoryAlias)}
              </Card.Title>
            </Card.Body>
          </Card>
        ))}
      </GeneralCardLayoutWrapper>
    </>
  );
};

export default AdvertisementCategoryPage;
