import { CSSProperties, useContext } from "react";
import { Context } from "../../context/context";
import useSidebarHook from "../sidebar/hook/useSidebarHook";

function MainBody({ children }: any) {
  const { isNarrowScreen, setOpenSidebar, openSidebar } = useContext(Context);
  const { closeAllSidebarItems } = useSidebarHook();

  const handleOnClick = () => {
    if (isNarrowScreen === "true" && openSidebar) {
      setOpenSidebar(false);
      closeAllSidebarItems();
    }
  };
  const pointerEventsStyle: CSSProperties =
    isNarrowScreen === "true" && openSidebar ? { pointerEvents: "none" } : {};
  return (
    <div id="mainBody" onClick={handleOnClick}>
      <span style={pointerEventsStyle}>{children}</span>
    </div>
  );
}

export default MainBody;
