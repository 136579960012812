import { currentCategoriesAndSubCategories } from "./currentCategoriesAndSubcategories";
import _ from "lodash";

export const findCategorySubCategoryAliasById = (
  advertisementCategoryId: number,
  advertisementSubCategoryId: number
) => {
  const currentAdvertisementCategory = _.find(
    currentCategoriesAndSubCategories,
    {
      id: advertisementCategoryId,
    }
  );

  const currentAdvertisementSubCategory = _.find(
    currentAdvertisementCategory?.subCategories,
    {
      id: advertisementSubCategoryId,
    }
  );

  return {
    categoryAlias: currentAdvertisementCategory?.categoryAlias || "",
    subCategoryAlias: currentAdvertisementSubCategory?.subCategoryAlias || "",
  };
};
