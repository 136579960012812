import { Card } from "react-bootstrap";
import AdvertisementCardImageComponent from "../../../sharedComponents/advertisementCardImage";

function AdvertisementCategoryIsEmptyComponent(props: any) {
  const { title, text } = props;
  return (
    <Card className="shadow advertisementCard">
      <AdvertisementCardImageComponent emptyImage={true} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default AdvertisementCategoryIsEmptyComponent;
