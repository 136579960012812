import { Form, FormGroup } from "react-bootstrap";
import { advertisementRegionsByState } from "../util/advertisementRegionsByState";
import { createTitleFromAlias } from "../util/createTitleFromAlias";
import { useEffect, useState } from "react";
import GeneralContainerRowCol from "./generalContainerRowCol";
import _ from "lodash";
import { calculateDropdownWidth } from "../util/calculateDropdownWidth";

export function AdvertisementFilter({ ...props }) {
  const [state, setState] = useState("-1");
  const [region, setRegion] = useState("-1");

  const { advertisements, setAdvertisementsFiltered } = props;

  useEffect(() => {
    if (advertisements === "notSet") {
      setAdvertisementsFiltered("notSet");
    } else {
      let filteredAds = advertisements;
      if (state !== "-1") {
        filteredAds = _.filter(filteredAds, (advertisement: any) => {
          return (
            advertisement.businessState.toLowerCase() === state.toLowerCase()
          );
        });
      }
      if (region !== "-1") {
        filteredAds = _.filter(filteredAds, (advertisement: any) => {
          return (
            advertisement.businessRegion.toLowerCase() === region.toLowerCase()
          );
        });
      }
      setAdvertisementsFiltered("loadingFilteredAdvertisements");
      setTimeout(function () {
        setAdvertisementsFiltered(filteredAds);
      }, 1000);
    }
  }, [advertisements, state, region]);

  const statesOptions = (
    <>
      <option id="state-1" value="-1">
        All
      </option>
      {advertisementRegionsByState.map((state) => (
        <option
          id={`state${state.shortName.toLowerCase()}`}
          key={state.id}
          value={state.shortName.toLowerCase()}
        >
          {state.shortName.toUpperCase()}
        </option>
      ))}
    </>
  );

  const regionsOptions =
    state && state !== "-1" ? (
      <>
        <option id="region-1" value="-1">
          All
        </option>
        {/* @ts-ignore */}
        {advertisementRegionsByState
          .find(
            (states) => states.shortName.toLowerCase() == state.toLowerCase()
          )
          .regions.map((region, index) => (
            <option id={`region${region}`} key={index} value={region}>
              {createTitleFromAlias(region)}
            </option>
          ))}
      </>
    ) : (
      <option value="-1">All</option>
    );

  return (
    <>
      <GeneralContainerRowCol colMd={12} colLg={12} colXl={12}>
        <FormGroup className="mb-2" style={{ display: "inline-block" }}>
          <Form.Label className="me-1">State</Form.Label>
          <Form.Select
            size="sm"
            className="me-3"
            style={{
              width: calculateDropdownWidth(`state${state}`),
              display: "inline-block",
            }}
            aria-label="state"
            onChange={(e) => {
              setRegion("-1");
              setState(e.target.value);
            }}
            value={state}
          >
            {statesOptions}
          </Form.Select>
        </FormGroup>
        <FormGroup className="mb-2" style={{ display: "inline-block" }}>
          <Form.Label className="me-1">Region</Form.Label>
          <Form.Select
            size="sm"
            className="me-3"
            style={{
              width: calculateDropdownWidth(`region${region}`),
              display: "inline-block",
            }}
            aria-label="region"
            onChange={(e) => setRegion(e.target.value)}
            value={region}
          >
            {regionsOptions}
          </Form.Select>
        </FormGroup>
      </GeneralContainerRowCol>
    </>
  );
}
