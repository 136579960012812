export const calculateDropdownWidth = (item: any, font?: string) => {
  const optionElement: any = document.getElementById(item);
  if (!optionElement) {
    return "63px";
  }

  const dummy = document.createElement("div");
  if (font) {
    dummy.style.font = font;
  } else {
    const computedStyle = window.getComputedStyle(optionElement);
    dummy.style.font = computedStyle.font;
  }
  dummy.innerText = optionElement.textContent;
  dummy.style.position = "absolute";
  dummy.style.visibility = "hidden";
  document.body.insertBefore(dummy, document.body.firstChild);
  const measuredWidth = dummy.getBoundingClientRect().width;
  document.body.removeChild(dummy);
  return measuredWidth + 47 + "px";
};
