function CharacterCounter(props: any) {
  const { min, max, text } = props;
  const textLength = text?.length;
  let counterClassName = "";

  if (textLength >= min && textLength <= max) {
    counterClassName = "text-success";
  } else if (textLength >= 1 && textLength < min) {
    counterClassName = "text-danger";
  } else if (textLength > max) {
    counterClassName = "text-danger";
  }

  return (
    <>
      <div className={`text-muted mb-1`} style={{ fontSize: "10px" }}>
        Min:{min}, Max:{max},{" "}
        <span
          className={`mb-1 ${counterClassName}`}
          style={{ fontSize: "10px" }}
        >
          counter:{textLength}
        </span>
      </div>
    </>
  );
}

export default CharacterCounter;
