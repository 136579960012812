import React, { useState, useEffect, useContext } from "react";
import { Context } from "../context/context";
import axios from "axios";
import LoginToSeeAdvertisementDetails from "./loginToSeeAdvertisementDetails";

const Map = (props: any) => {
  const { businessAddress } = props;
  const [map, setMap] = useState(null);
  const { googleMapScriptLoaded, ifAuthenticated } = useContext(Context);
  const [businessAddressFormatted, setBusinessAddressFormatted] = useState("");
  const [computedBusinessAddress, setComputedBusinessAddress] = useState(null);

  const centerMap = (position: any, map: any) => {
    // @ts-ignore
    const newCenter = new google.maps.LatLng(position.lat, position.lng);
    map.setCenter(newCenter);
    // @ts-ignore
    new google.maps.Marker({
      position: position,
      map: map,
    });
    setMap(map);
  };

  const initMap = async () => {
    // @ts-ignore
    const map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 33, lng: 33 },
      zoom: 12,
      mapId: "ab40455dfb70034c",
    });
    if (typeof businessAddress === "object" && ifAuthenticated === "true") {
      centerMap(
        {
          lat:
            typeof businessAddress?.geometry?.location?.lat === "function"
              ? businessAddress?.geometry?.location?.lat()
              : businessAddress?.geometry?.location?.lat,
          lng:
            typeof businessAddress?.geometry?.location?.lng === "function"
              ? businessAddress?.geometry?.location?.lng()
              : businessAddress?.geometry?.location?.lng,
        },
        map
      );
      setBusinessAddressFormatted(businessAddress.formatted_address);
      setComputedBusinessAddress(businessAddress);
    } else if (
      typeof businessAddress === "string" ||
      ifAuthenticated !== "true"
    ) {
      // @ts-ignore
      const service = new google.maps.places.PlacesService(map);

      service.getDetails(
        {
          placeId: businessAddress,
        },
        function (place: any, status: any) {
          centerMap(
            {
              lat: place?.geometry?.location?.lat(),
              lng: place?.geometry?.location?.lng(),
            },
            map
          );
          setComputedBusinessAddress(place);
          setBusinessAddressFormatted(place.formatted_address);
        }
      );
    }
  };

  useEffect(() => {
    if (googleMapScriptLoaded === "true") {
      initMap();
    } else {
      setMap(null);
    }
  }, [googleMapScriptLoaded, businessAddress]);

  return (
    <>
      <div>
        <p style={{ marginBottom: "0.5rem" }}>
          <b>Address: </b>
          {ifAuthenticated === "true" ? (
            businessAddressFormatted
          ) : (
            <LoginToSeeAdvertisementDetails />
          )}
        </p>
      </div>
      <div className="">
        <a
          target="_blank"
          className="link-primary"
          href={`https://www.google.com/maps/search/?api=1&query=${
            //@ts-ignore
            typeof computedBusinessAddress?.geometry?.location.lat ===
            "function"
              ? //@ts-ignore
                computedBusinessAddress?.geometry?.location.lat()
              : //@ts-ignore
                computedBusinessAddress?.geometry?.location.lat
          },${
            //@ts-ignore
            typeof computedBusinessAddress?.geometry?.location?.lng ===
            "function"
              ? //@ts-ignore
                computedBusinessAddress?.geometry?.location?.lng()
              : //@ts-ignore
                computedBusinessAddress?.geometry?.location?.lng
          }`}
        >
          Open in Google Maps
        </a>
      </div>
      <div
        id="map"
        className="googleMapShowAdvertisementWrapper"
        style={{
          maxWidth: "300px",
          // margin: "auto",
          position: "relative",
          marginBottom: "1rem",
          height: "200px",
        }}
      >
        {map && <p>Map Loaded</p>}
      </div>
    </>
  );
};

export default Map;
