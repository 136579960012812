import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/context";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import axios from "axios";
import { ForgotPasswordLinkSentModal } from "./component/modals";
import { useNavigate } from "react-router";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import FormErrorMessage from "../../sharedComponents/formErrorMessage";
import Recaptcha from "../recaptcha/recaptcha";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../../util/inputFieldErrorMapper";
import { frontendInputFields } from "../../util/frontendInputFields";

function ForgotPasswordOutbound() {
  const history = useNavigate();
  const { isNarrowScreen, ifAuthenticated, currentUser, openSidebar } =
    useContext(Context);
  const [email, setEmail] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const recaptchaRef = useRef<any>();
  const [forgotPasswordError, setForgotPasswordError] = useState([]);
  const [isDuringSubmission, setIsDuringSubmission] = useState(false);

  const goToRegisterPage = () => {
    history("/register");
  };

  const goToLoginPage = () => {
    history("/login");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsDuringSubmission(true);
    setForgotPasswordError([]);
    const token = await recaptchaRef.current.executeAsync();
    axios
      .post("auth/forgot-password-outbound", {
        email,
        recaptchaToken: token,
      })
      .then(function (response) {
        if (response?.status === 200) {
          setIsDuringSubmission(false);
          setModalShow(true);
        }
      })
      .catch((error) => {
        setForgotPasswordError(error?.response?.data?.message);
        setIsDuringSubmission(false);
      });
  };

  useEffect(() => {
    if (ifAuthenticated === "true") {
      setEmail(currentUser.username);
    }
  }, [ifAuthenticated, currentUser, isNarrowScreen]);

  useEffect(() => {
    const firstInvalidField = document.querySelector(".is-invalid");
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [forgotPasswordError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumb
        routes={
          ifAuthenticated === "true"
            ? [
                ["home", true],
                [routesEnum.DASHBOARD_URL, true],
                [routesEnum.ACCOUNT_SETTING_URL, true],
                [routesEnum.FORGOT_PASSWORD_URL, false],
              ]
            : [
                ["home", true],
                [routesEnum.FORGOT_PASSWORD_URL, false],
              ]
        }
      ></BreadCrumb>
      <ForgotPasswordLinkSentModal
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 7 : 6}
        colXl={openSidebar ? 6 : 5}
      >
        <Form onSubmit={handleSubmit}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Forgot Password Email"
              value={ifAuthenticated === "true" ? currentUser.username : email}
              onChange={(e) => {
                setEmail(e.target.value.toLowerCase());
              }}
              disabled={ifAuthenticated === "true"}
              isInvalid={inputFieldErrorMapperBoolean(
                forgotPasswordError,
                frontendInputFields.USER_EMAIL
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                forgotPasswordError,
                frontendInputFields.USER_EMAIL
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringSubmission}
          >
            Send
          </Button>
          {ifAuthenticated === "false" && (
            <a
              className="link-opacity-100 float-end ms-2 pointerOnHover"
              onClick={goToRegisterPage}
            >
              <small>register</small>
            </a>
          )}
          {ifAuthenticated === "false" && (
            <a
              className="link-opacity-100 float-end ms-2 pointerOnHover"
              onClick={goToLoginPage}
            >
              <small>login</small>
            </a>
          )}
        </Form>
      </GeneralContainerRowColCard>
    </>
  );
}

export default ForgotPasswordOutbound;
