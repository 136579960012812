import { Button, Form } from "react-bootstrap";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import CharacterCounter from "../../sharedComponents/characterCounter";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/context";
import axios from "axios";
import Recaptcha from "../../auth/recaptcha/recaptcha";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../../util/inputFieldErrorMapper";
import { frontendInputFields } from "../../util/frontendInputFields";
import { GeneralModal } from "../../sharedComponents/modals";

function ContactUs() {
  const { openSidebar, currentUser, ifAuthenticated } = useContext(Context);
  const recaptchaRef = useRef<any>();
  const [contactUsEmail, setContactUsEmail] = useState("");
  const [contactUsMessage, setContactUsMessage] = useState("");
  const [advertisementCreateModalShow, setAdvertisementCreateModalShow] =
    useState(false);
  const [contactUsFormError, setContactUsFormError] = useState([]);
  const [isDuringFormSubmission, setIsDuringFormSubmission] = useState(false);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      setIsDuringFormSubmission(true);
      setContactUsFormError([]);
      const token = await recaptchaRef.current.executeAsync();
      const response = await axios.post("contact-us", {
        contactUsEmail:
          currentUser !== "notSet" && currentUser !== "notAuthenticated"
            ? currentUser.username
            : contactUsEmail,
        contactUsMessage,
        recaptchaToken: token,
      });
      setIsDuringFormSubmission(false);
      setContactUsEmail("");
      setContactUsMessage("");
      setAdvertisementCreateModalShow(true);
    } catch (error: any) {
      setIsDuringFormSubmission(false);
      setContactUsFormError(error?.response?.data?.message || []);
    }
  };

  useEffect(() => {
    const firstInvalidField = document.querySelector(".is-invalid");
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [contactUsFormError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadCrumb
        routes={
          ifAuthenticated === "true"
            ? [
                ["home", true],
                [routesEnum.DASHBOARD_URL, true],
                [routesEnum.CONTACT_US_URL, false],
              ]
            : [
                ["home", true],
                [routesEnum.CONTACT_US_URL, false],
              ]
        }
      ></BreadCrumb>
      <GeneralModal
        modalShow={advertisementCreateModalShow}
        setModalShow={setAdvertisementCreateModalShow}
        message={
          "We have successfully received your message and get back to you shortly."
        }
      />
      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 8 : 7}
        colXl={openSidebar ? 8 : 7}
      >
        <Form onSubmit={handleSubmit}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              value={
                currentUser !== "notSet" && currentUser !== "notAuthenticated"
                  ? currentUser.username
                  : contactUsEmail
              }
              placeholder="Enter your email address"
              onChange={(e) => setContactUsEmail(e.target.value.toLowerCase())}
              disabled={
                currentUser !== "notSet" && currentUser !== "notAuthenticated"
              }
              isInvalid={inputFieldErrorMapperBoolean(
                contactUsFormError,
                frontendInputFields.USER_EMAIL
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                contactUsFormError,
                frontendInputFields.USER_EMAIL
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <CharacterCounter min={10} max={500} text={contactUsMessage} />
            <Form.Control
              size="sm"
              as="textarea"
              rows={8}
              value={contactUsMessage?.replaceAll("*****", "\n")}
              onChange={(e) =>
                setContactUsMessage(e.target.value?.replaceAll("\n", "*****"))
              }
              isInvalid={inputFieldErrorMapperBoolean(
                contactUsFormError,
                frontendInputFields.CONTACT_US_MESSAGE
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                contactUsFormError,
                frontendInputFields.CONTACT_US_MESSAGE
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringFormSubmission}
          >
            {isDuringFormSubmission ? "Loading ..." : "Send Message"}
          </Button>
        </Form>
      </GeneralContainerRowColCard>
    </>
  );
}

export default ContactUs;
