import { Alert, Button, Card } from "react-bootstrap";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { ChangeStatusModal } from "./modals";
import { Context } from "../context/context";
import { useLocation, useNavigate, useParams } from "react-router";
import VisibilityLockIcon from "../icons/visibilityLockIcon/visibilityLockIcon";
import { advertisementStatuses } from "../util/advertisementStatuses";
import { routesEnum } from "../layouts/breadCrumbs/routesEnum";

function AdvertisementManageBox() {
  let { advertisementId } = useParams();
  const [advertisement, setAdvertisement] = useState<any>(null);
  const [editedAdvertisement, setEditedAdvertisement] = useState<any>(null);
  const [
    doesAdvertisementHaveEditedVersion,
    setDoesAdvertisementHaveEditedVersion,
  ] = useState("notSet");

  const [advertisementStatusToBeSet, setAdvertisementStatusToBeSet] = useState(
    advertisementStatuses.pendingInitialSubmit
  );
  const [shouldModalIncludeTextBox, setShouldModalIncludeTextBox] =
    useState(false);
  const [advertisementStatusModalMessage, setAdvertisementStatusModalMessage] =
    useState("");
  const [
    advertisementStatusModalMainButtonWording,
    setAdvertisementStatusModalMainButtonWording,
  ] = useState("");
  const [
    editedAdvertisementStatusWording,
    setEditedAdvertisementStatusWording,
  ] = useState("");
  const [advertisementStatusWording, setAdvertisementStatusWording] =
    useState("");
  const [
    advertisementChangeStatusModalShow,
    setAdvertisementChangeStatusModalShow,
  ] = useState(false);
  const [changeStatusInProgress, setChangeStatusInProgress] = useState(false);
  const [changeStatusAdvertisementError, setChangeStatusAdvertisementError] =
    useState("");
  const {
    setShouldFetchAuthInitialStates,
    setShouldFetchPublicInitialStates,
    personalEditedAdvertisements,
    personalAdvertisements,
    ifAuthenticated,
  } = useContext(Context);
  const history = useNavigate();

  useEffect(() => {
    if (advertisement) {
      if (
        advertisement.advertisementStatus ===
          advertisementStatuses.pendingAdminForce ||
        advertisement.advertisementStatus ===
          advertisementStatuses.pendingAfterUnarchive ||
        advertisement.advertisementStatus ===
          advertisementStatuses.pendingAfterEdit ||
        advertisement.advertisementStatus ===
          advertisementStatuses.pendingInitialSubmit
      ) {
        setAdvertisementStatusWording(
          "pending, we are currently reviewing your advertisement"
        );
      } else if (
        advertisement.advertisementStatus === advertisementStatuses.approve
      ) {
        setAdvertisementStatusWording(
          "approved, visible to everyone, everywhere!"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChange
      ) {
        setAdvertisementStatusWording(
          "pending, your ad is pending approval due to requested changes. Kindly revise to address the required adjustments."
        );
      } else if (
        advertisement.advertisementStatus === advertisementStatuses.archive
      ) {
        setAdvertisementStatusWording(
          "archived, the advertisement is archived and is only visible to you, you can unarchive it to make it visible to everyone everywhere!"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChangeAddressed
      ) {
        setAdvertisementStatusWording(
          "pending, thanks for addressing the change request, we are currently reviewing your advertisement"
        );
      }
    }
    if (editedAdvertisement) {
      if (
        editedAdvertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChange
      ) {
        setEditedAdvertisementStatusWording(
          "pending, after request change by admin, please edit again and address the request changes."
        );
      } else if (
        editedAdvertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChangeAddressed
      ) {
        setEditedAdvertisementStatusWording(
          "pending, thanks for addressing the change request, we are currently reviewing it."
        );
      } else if (
        editedAdvertisement.advertisementStatus ===
        advertisementStatuses.pendingInitialSubmit
      ) {
        setEditedAdvertisementStatusWording(
          "pending, we have received the edited version and currently reviewing it."
        );
      }
    }
  }, [advertisement, editedAdvertisement]);

  const changeStatusAdvertisement = async (advertisementStatus: number) => {
    if (advertisementStatus === advertisementStatuses.pendingAfterEdit) {
      history(
        `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADVERTISEMENT_MANAGE_URL}/${
          routesEnum.ADVERTISEMENT_EDIT_URL
        }/${
          doesAdvertisementHaveEditedVersion === "true"
            ? editedAdvertisement.id
            : advertisement.id
        }`
      );

      return;
    }
    setChangeStatusInProgress(true);
    setChangeStatusAdvertisementError("");
    try {
      await axios.post("advertisements/change-advertisement-status", {
        advertisementId: advertisement.id,
        advertisementStatus,
      });
      setAdvertisementChangeStatusModalShow(false);
      if (advertisementStatus === advertisementStatuses.softDelete) {
        history("/dashboard/advertisement-manage");
      }
      setChangeStatusInProgress(false);
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    } catch (error: any) {
      setChangeStatusInProgress(false);
      setChangeStatusAdvertisementError(error?.response?.data?.message);
    }
  };

  const removeErrorsAndTextAndCloseModal = () => {
    setChangeStatusAdvertisementError("");
    setShouldModalIncludeTextBox(false);
    setAdvertisementChangeStatusModalShow(false);
  };

  useEffect(() => {
    if (advertisementId && personalEditedAdvertisements !== "notSet") {
      const editedAdvertisementVersion = _.find(personalEditedAdvertisements, {
        advertisementId,
      });
      if (editedAdvertisementVersion) {
        setEditedAdvertisement(editedAdvertisementVersion);
        setDoesAdvertisementHaveEditedVersion("true");
      } else {
        setDoesAdvertisementHaveEditedVersion("false");
      }
    }
  }, [personalEditedAdvertisements]);

  useEffect(() => {
    if (advertisementId && personalAdvertisements !== "notSet") {
      const selectedAdvertisement = _.find(personalAdvertisements, {
        id: advertisementId,
      });
      if (selectedAdvertisement) {
        setAdvertisement(selectedAdvertisement);
      }
    }
  }, [personalAdvertisements]);

  const location = useLocation();

  return (
    <>
      {ifAuthenticated === "true" &&
        location.pathname ===
          `/dashboard/advertisement-manage/${advertisementId}` && (
          <>
            <ChangeStatusModal
              modalShow={advertisementChangeStatusModalShow}
              setModalShow={setAdvertisementChangeStatusModalShow}
              removeErrorsAndTextAndCloseModal={
                removeErrorsAndTextAndCloseModal
              }
              changeStatusInProgress={changeStatusInProgress}
              onChangeStatus={() =>
                changeStatusAdvertisement(advertisementStatusToBeSet)
              }
              message={advertisementStatusModalMessage}
              changeStatusAdvertisementError={changeStatusAdvertisementError}
              changeStatusButtonWording={
                advertisementStatusModalMainButtonWording
              }
              shouldModalIncludeTextBox={shouldModalIncludeTextBox}
            />
            <Card
              border="secondary"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              <Card.Body>
                <div className="text-center mb-3">
                  <VisibilityLockIcon
                    style={{
                      position: "relative",
                      bottom: "1px",
                      right: "2px",
                    }}
                  />
                  Owner View, this Section is only visible to you
                </div>
                <Alert
                  variant="light"
                  className="border textNotSelectable"
                  style={{ background: "#f8f9fa" }}
                >
                  <b style={{ color: "black" }}>Advertisement Status:</b>
                  <br />
                  <br />{" "}
                  {doesAdvertisementHaveEditedVersion === "false" &&
                    advertisementStatusWording}
                  {doesAdvertisementHaveEditedVersion === "true" &&
                    ` The edited version is ${editedAdvertisementStatusWording}`}
                  <br />
                  <br />
                  {doesAdvertisementHaveEditedVersion === "true" &&
                    `The current version is ${advertisementStatusWording}`}
                </Alert>
                <Button
                  variant="light"
                  className="border me-2 mb-2"
                  onClick={() => {
                    setAdvertisementStatusToBeSet(
                      advertisementStatuses.pendingAfterEdit
                    );
                    setAdvertisementStatusModalMessage(
                      "Are you sure? you are about to edit your advertisement, after the edited version will only be visible after admin approval"
                    );
                    setAdvertisementStatusModalMainButtonWording("Edit");
                    setShouldModalIncludeTextBox(false);
                    setAdvertisementChangeStatusModalShow(true);
                  }}
                  size="sm"
                >
                  Edit
                </Button>
                {advertisement?.advertisementStatus ==
                  advertisementStatuses.approve && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.archive
                      );
                      setAdvertisementStatusModalMessage(
                        "This will archive the advertisement and it will be only visible to you afterwards, you can unarchive it anytime later."
                      );
                      setAdvertisementStatusModalMainButtonWording("Archive");
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Archive
                  </Button>
                )}
                {advertisement?.advertisementStatus ===
                  advertisementStatuses.archive && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.pendingAfterUnarchive
                      );
                      setAdvertisementStatusModalMessage(
                        "This will unarchive the advertisement and make it visible to everyone everywhere!"
                      );
                      setAdvertisementStatusModalMainButtonWording("Unarchive");
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Unarchive
                  </Button>
                )}
                {advertisement?.advertisementStatus !==
                  advertisementStatuses.softDelete && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.softDelete
                      );
                      setAdvertisementStatusModalMessage(
                        "Are you sure? this will delete the advertisement and edited version of the same advertisement"
                      );
                      setAdvertisementStatusModalMainButtonWording("Delete");
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Delete
                  </Button>
                )}
              </Card.Body>
            </Card>
          </>
        )}
    </>
  );
}

export default AdvertisementManageBox;
