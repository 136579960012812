import { Row } from "react-bootstrap";
import { Context } from "../context/context";
import { useContext } from "react";

function ScrollableTable({ children, ...props }: any) {
  const { openSidebar } = useContext(Context);
  return (
    <Row
      style={{ padding: "5px" }}
      className={`scrollable-table ${
        openSidebar
          ? "scrollable-table-sidebar-open"
          : "scrollable-table-sidebar-close"
      }`}
    >
      {children}
    </Row>
  );
}

export default ScrollableTable;
