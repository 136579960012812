import { Form, Button } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/context";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import axios from "axios";
import { useNavigate } from "react-router";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import Recaptcha from "../../auth/recaptcha/recaptcha";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../../util/inputFieldErrorMapper";
import { frontendInputFields } from "../../util/frontendInputFields";

function AccountAndDataDeletion() {
  const history = useNavigate();
  const {
    currentUser,
    openSidebar,
    setShouldFetchAuthInitialStates,
    setShouldFetchPublicInitialStates,
    setIfAuthenticated,
  } = useContext(Context);
  const [password, setPassword] = useState("");
  const recaptchaRef = useRef<any>();
  const [accountAndDataDeletionError, setAccountAndDataDeletionError] =
    useState([]);
  const [isDuringSubmission, setIsDuringSubmission] = useState(false);

  useEffect(() => {
    const firstInvalidField = document.querySelector(".is-invalid");
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [accountAndDataDeletionError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteAccountAndData = async (event: any) => {
    event.preventDefault();
    setIsDuringSubmission(true);
    setAccountAndDataDeletionError([]);
    const token = await recaptchaRef.current.executeAsync();
    try {
      const response = await axios.post("auth/account-and-data-deletion", {
        email: currentUser.username,
        password,
        recaptchaToken: token,
      });
      setIsDuringSubmission(false);
      if (response?.status === 200) {
        setAccountAndDataDeletionError([]);
        setIfAuthenticated("false");
        setShouldFetchAuthInitialStates(true);
        setShouldFetchPublicInitialStates(true);
      }
    } catch (error: any) {
      setIsDuringSubmission(false);
      setAccountAndDataDeletionError(error?.response?.data?.message);
    }
  };

  const goToSetNewPasswordPage = () => {
    history("/dashboard/account-setting/set-new-password");
  };

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ACCOUNT_SETTING_URL, true],
          [routesEnum.ACCOUNT_AND_DATA_DELETION_URL, false],
        ]}
      ></BreadCrumb>
      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 7 : 6}
        colXl={openSidebar ? 6 : 5}
      >
        <Form onSubmit={handleDeleteAccountAndData}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Enter your email address"
              value={currentUser.username}
              disabled={true}
              isInvalid={inputFieldErrorMapperBoolean(
                accountAndDataDeletionError,
                frontendInputFields.USER_EMAIL
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                accountAndDataDeletionError,
                frontendInputFields.USER_EMAIL
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value.toLowerCase());
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                accountAndDataDeletionError,
                frontendInputFields.USER_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                accountAndDataDeletionError,
                frontendInputFields.USER_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="rounded border border-2 border-danger p-3 mt-3">
            <div className="">
              This will delete your account and all of your data including your
              bookmarked joys and the advertisements you may have. You'll be
              logged out upon successful deletion.
            </div>
            <br />
            <div className="">
              If you've forgotten your password or registered and logged in with
              social logins like google or facebook login,{" "}
              <a
                className="link-opacity-100 pointerOnHover"
                onClick={goToSetNewPasswordPage}
              >
                set a password
              </a>{" "}
              first and try again.
            </div>
          </div>

          <Button
            className="mt-3"
            variant="danger"
            type="submit"
            size="sm"
            disabled={isDuringSubmission}
          >
            Delete account & data
          </Button>
        </Form>
      </GeneralContainerRowColCard>
    </>
  );
}

export default AccountAndDataDeletion;
