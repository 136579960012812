import { useContext, useEffect } from "react";
import { Context } from "../../../context/context";
import useHandleWidthHook from "../../../layouts/sidebar/hook/useHandleWidthHook";
import CameraIcon from "../../../icons/cameraIcon/cameraIcon";
import FoodIcon from "../../../icons/grillIcon/grillIcon";
import SnowMobileIcon from "../../../icons/snowMobileIcon/snowMobileIcon";
import TentIcon from "../../../icons/tentIcon/tentIcon";
import DogIcon from "../../../icons/dogIcon/dogIcon";
import CarIcon from "../../../icons/carIcon/carIcon";
import AdventureIcon from "../../../icons/adventureIcon/adventureIcon";
import HelicopterIcon from "../../../icons/helicopterIcon/helicopterIcon";
import BoatIcon from "../../../icons/boatIcon/boatIcon";
import UserIcon from "../../../icons/userIcon/userIcon";
import { categoriesAlias } from "../../../util/currentCategoriesAndSubcategories";
import { useLocation } from "react-router-dom";
import DogIconFilled from "../../../icons/dogIcon/dogIcon-filled";
import UserIconFilled from "../../../icons/userIcon/userIconFilled";
import BoatIconFilled from "../../../icons/boatIcon/boatIconFilled";
import HelicopterIconFilled from "../../../icons/helicopterIcon/helicopterIconFilled";
import AdventureIconFilled from "../../../icons/adventureIcon/adventureIconFilled";
import CarIconFilled from "../../../icons/carIcon/carIconFilled";
import FoodIconFilled from "../../../icons/grillIcon/grillIconFilled";
import CameraIconFilled from "../../../icons/cameraIcon/cameraIconFilled";
import SnowMobileIconFilled from "../../../icons/snowMobileIcon/snowMobileIconFilled";
import TentIconFilled from "../../../icons/tentIcon/tentIconFilled";
import HomeIconFilled from "../../../icons/homeIcon/homeIconFilled";
import HomeIcon from "../../../icons/homeIcon/homeIcon";

const useSidebarHook = () => {
  const location = useLocation().pathname;
  const {
    closeAllSidebarItems,
    openSidebarItem1,
    setOpenSidebarItem1,
    openSidebarItem2,
    setOpenSidebarItem2,
    openSidebarItem3,
    setOpenSidebarItem3,
    openSidebarItem4,
    setOpenSidebarItem4,
    openSidebarItem5,
    setOpenSidebarItem5,
    openSidebarItem6,
    setOpenSidebarItem6,
    openSidebarItem7,
    setOpenSidebarItem7,
    openSidebarItem8,
    setOpenSidebarItem8,
    openSidebarItem9,
    setOpenSidebarItem9,
    openSidebarItem10,
    setOpenSidebarItem10,
    openSidebarItem11,
    setOpenSidebarItem11,
    openSidebar,
    setOpenSidebar,
    handleLogicOnScreenResize,
  } = useHandleWidthHook();

  const icons = [
    location === "/" ? <HomeIconFilled /> : <HomeIcon />,
    location.includes("dashboard") ||
    location.includes("login") ||
    location.includes("register") ? (
      <UserIconFilled />
    ) : (
      <UserIcon />
    ),
    location.includes(categoriesAlias.WATER_SPORTS) ? (
      <BoatIconFilled />
    ) : (
      <BoatIcon />
    ),

    location.includes(categoriesAlias.FLYING_EXPERIENCES) ? (
      <HelicopterIconFilled />
    ) : (
      <HelicopterIcon />
    ),
    location.includes(categoriesAlias.ADVENTURE_SPORTS) ? (
      <AdventureIconFilled />
    ) : (
      <AdventureIcon />
    ),
    location.includes(categoriesAlias.DRIVING_EXPERIENCES) ? (
      <CarIconFilled />
    ) : (
      <CarIcon />
    ),
    location.includes(categoriesAlias.ANIMAL_EXPERIENCES) ? (
      <DogIconFilled />
    ) : (
      <DogIcon />
    ),
    location.includes(categoriesAlias.OUTDOOR_ACTIVITIES) ? (
      <FoodIconFilled />
    ) : (
      <FoodIcon />
    ),
    location.includes(categoriesAlias.CULTURE_AND_ART) ? (
      <CameraIconFilled />
    ) : (
      <CameraIcon />
    ),
    location.includes(categoriesAlias.SNOW_SPORTS) ? (
      <SnowMobileIconFilled />
    ) : (
      <SnowMobileIcon />
    ),
    location.includes(categoriesAlias.CAMPING) ? (
      <TentIconFilled />
    ) : (
      <TentIcon />
    ),
  ];

  const openSidebarItemsArray = [
    openSidebarItem1,
    openSidebarItem2,
    openSidebarItem3,
    openSidebarItem4,
    openSidebarItem5,
    openSidebarItem6,
    openSidebarItem7,
    openSidebarItem8,
    openSidebarItem9,
    openSidebarItem10,
    openSidebarItem11,
  ];

  const setOpenSidebarItemsArray = [
    setOpenSidebarItem1,
    setOpenSidebarItem2,
    setOpenSidebarItem3,
    setOpenSidebarItem4,
    setOpenSidebarItem5,
    setOpenSidebarItem6,
    setOpenSidebarItem7,
    setOpenSidebarItem8,
    setOpenSidebarItem9,
    setOpenSidebarItem10,
    setOpenSidebarItem11,
  ];

  const { setAdvertisementCategoriesAndSubCategories, ifAuthenticated, isNarrowScreen } =
    useContext(Context);

  useEffect(() => {
    handleLogicOnScreenResize();
    window.addEventListener("resize", handleLogicOnScreenResize);
  }, [ifAuthenticated]);

  return {
    openSidebar,
    setOpenSidebar,
    closeAllSidebarItems,
    setAdvertisementCategoriesAndSubCategories,
    openSidebarItemsArray,
    setOpenSidebarItemsArray,
    icons,
    isNarrowScreen
  };
};

export default useSidebarHook;
