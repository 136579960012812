import { Card } from "react-bootstrap";
import AdvertisementCardImageComponent from "./advertisementCardImage";
import _ from "lodash";
import GeneralColCard from "./generalColCard";
import AdvertisementImageGallery from "./advertisementImageGallery";
import { sampleAdvertisementText } from "../util/sampleAdvertisementText";
import parse from "html-react-parser";
import GoogleMap from "./googleMap";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import AdvertisementManageBox from "./advertisementManageBox";
import { Context } from "../context/context";
import AdminAdvertisementManageBox from "../admin/adminSharedComponenets/adminAdvertisementManageBox";
import { useLocation, useParams } from "react-router";
import { GeneralModal } from "./modals";
import { useSearchParams } from "react-router-dom";
import { imageSrcArray } from "../util/imageSrcArray";
import AdminEditedAdvertisementManageBox from "../admin/adminSharedComponenets/adminEditedAdvertisementManageBox";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTitleFromAlias } from "../util/createTitleFromAlias";
import { sampleBusinessAddressPlaceId } from "../util/sampleBusinessAddressPlaceId";
import LoginToSeeAdvertisementDetails from "./loginToSeeAdvertisementDetails";

function AdvertisementShow(props: any) {
  const {
    imagesArray,
    advertisementDescription,
    advertisementTitle,
    abn,
    websiteLink,
    businessEmail,
    businessAddress,
    businessState,
    businessRegion,
    businessPhoneNumber,
    selectedAdvertisementId,
    advertisementShowType,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const [
    advertisementEditedSuccessfulModalShow,
    setAdvertisementEditedSuccessfulModalShow,
  ] = useState(false);

  const {
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
    isNarrowScreen,
    ifAuthenticated,
    ifSuperAdmin,
  } = useContext(Context);

  const personalAdvertisement = _.find(personalAdvertisements, {
    id: selectedAdvertisementId,
  });

  const personalEditedAdvertisement = _.find(personalEditedAdvertisements, {
    advertisementId: selectedAdvertisementId,
  });

  const adminSingleAdvertisement = _.find(adminAllAdvertisements, {
    id: selectedAdvertisementId,
  });

  const adminSingleEditedAdvertisement = _.find(adminAllEditedAdvertisements, {
    advertisementId: selectedAdvertisementId,
  });

  const adminEditedVersionAdvertisement = _.find(adminAllEditedAdvertisements, {
    id: selectedAdvertisementId,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      searchParams.get("editedCompleted") &&
      searchParams.get("editedCompleted") === "true"
    ) {
      setAdvertisementEditedSuccessfulModalShow(true);
      searchParams.delete("editedCompleted");
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <>
      <GeneralModal
        modalShow={advertisementEditedSuccessfulModalShow}
        setModalShow={setAdvertisementEditedSuccessfulModalShow}
        message="You have successfully edited your advertisement, it'll replace the current version after admin's approval"
      />

      <AdvertisementManageBox />

      <AdminAdvertisementManageBox />

      <AdminEditedAdvertisementManageBox />

      <AdvertisementImageGallery imagesArray={imagesArray} />

      <div className="mt-4">
        <h3>{advertisementTitle}</h3>
        <p>{parse(advertisementDescription.replaceAll("*****", "<br />"))}</p>
        <p>
          <b>State: </b>
          {businessState !== "-1" ? businessState.toUpperCase() : "-"}
        </p>
        <p>
          <b>Region: </b>
          {businessRegion !== "-1" ? createTitleFromAlias(businessRegion) : "-"}
        </p>
        <p>
          <b>Australian Business Number (ABN): </b>
          <a
            target="_blank"
            className="link-primary"
            href={`https://abr.business.gov.au/ABN/View?abn=${abn}`}
          >
            {abn}
          </a>
        </p>
        <p>
          <b>Phone Number: </b>
          {ifAuthenticated === "true" ? (
            businessPhoneNumber
          ) : (
            <LoginToSeeAdvertisementDetails />
          )}
        </p>
        {websiteLink !== `${process.env.REACT_APP_DEFAULT_FRONTEND_URL}` && (
          <p>
            <b>Website: </b>
            {ifAuthenticated === "true" ? (
              <a target="_blank" className="link-primary" href={websiteLink}>
                {websiteLink}
              </a>
            ) : (
              <LoginToSeeAdvertisementDetails />
            )}
          </p>
        )}
        {businessEmail !==
          `${process.env.REACT_APP_DEFAULT_WHERETOJOY_MAIN_EMAIL}` && (
          <p>
            <b>Email: </b>
            {ifAuthenticated === "true" ? (
              <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
            ) : (
              <LoginToSeeAdvertisementDetails />
            )}
          </p>
        )}
        <GoogleMap
          businessAddress={
            ifAuthenticated === "true"
              ? businessAddress
              : sampleBusinessAddressPlaceId
          }
        />
      </div>
    </>
  );
}

export default AdvertisementShow;
