import { useContext, useEffect, useRef, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { Context } from "../context/context";
import _ from "lodash";
import { currentCategoriesAndSubCategories } from "../util/currentCategoriesAndSubcategories";
import { createTitleFromAlias } from "../util/createTitleFromAlias";
import { advertisementRegionsByState } from "../util/advertisementRegionsByState";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../util/inputFieldErrorMapper";
import { frontendInputFields } from "../util/frontendInputFields";

export function StateAndRegionSelect({ ...props }) {
  const {
    setBusinessState,
    businessState,
    businessRegion,
    setBusinessRegion,
    advertisementFormError,
  } = props;

  const regionsOptions =
    businessState && businessState !== "-1" ? (
      <>
        <option value="-1">Open to Select</option>
        {/* @ts-ignore */}
        {advertisementRegionsByState
          .find(
            (state) =>
              state.shortName.toLowerCase() == businessState.toLowerCase()
          )
          .regions.map((region, index) => (
            <option key={index} value={region}>
              {createTitleFromAlias(region)}
            </option>
          ))}
      </>
    ) : (
      <option value="-1">type address first ...</option>
    );

  const statesOptions = (
    <>
      <option value="-1">type address first ...</option>
      {/* @ts-ignore */}
      {advertisementRegionsByState.map((state) => (
        <option key={state.id} value={state.shortName.toLowerCase()}>
          {state.shortName.toUpperCase()}
        </option>
      ))}
    </>
  );

  return (
    <>
      <FormGroup className="mb-3">
        <Form.Label>Business State</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Business State"
          onChange={(e) => {
            setBusinessState(e.target.value);
            setBusinessRegion("-1");
          }}
          value={businessState}
          disabled={true}
          isInvalid={inputFieldErrorMapperBoolean(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_STATE
          )}
        >
          {statesOptions}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {inputFieldErrorMapperWording(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_STATE
          )}
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup className="mb-3">
        <Form.Label>Select Business Region</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Business Region"
          onChange={(e) => setBusinessRegion(e.target.value)}
          value={businessRegion}
          disabled={!businessState || businessState === "-1"}
          isInvalid={inputFieldErrorMapperBoolean(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_REGION
          )}
        >
          {regionsOptions}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {inputFieldErrorMapperWording(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_REGION
          )}
        </Form.Control.Feedback>
      </FormGroup>
    </>
  );
}
