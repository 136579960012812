import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../context/context";
import { useNavigate } from "react-router";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { currentCategoriesAndSubCategories } from "../../../util/currentCategoriesAndSubcategories";

const useAdvertisementCategoryPageHook = () => {
  let { advertisementCategory } = useParams();

  // const { allAdvertisementCategoriesAndSubCategories } = useContext(Context);

  const [relatedSubCategories, setRelatedSubCategories] = useState<any>([]);

  // useEffect(() => {
  //   if (
  //     allAdvertisementCategoriesAndSubCategories &&
  //     allAdvertisementCategoriesAndSubCategories !== "notSet"
  //   ) {
  //     const result1 = _.find(allAdvertisementCategoriesAndSubCategories, {
  //       categoryAlias: advertisementCategory,
  //     });
  //     setRelatedSubCategories(result1.subCategories);
  //   }
  // }, [allAdvertisementCategoriesAndSubCategories]);

  useEffect(() => {
    const result1 = _.find(currentCategoriesAndSubCategories, {
      categoryAlias: advertisementCategory,
    });
    setRelatedSubCategories(result1?.subCategories);
  }, []);

  return {
    advertisementCategory,
    relatedSubCategories,
  };
};

export default useAdvertisementCategoryPageHook;
