const CameraIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M480-269.5q71.25 0 119.125-47.875T647-436.5q0-71.5-47.875-119.25T480-603.5q-72.25 0-119.625 47.75T313-436.5q0 71.25 47.375 119.125T480-269.5Zm-337.5 145q-22.969 0-40.234-17.266Q85-159.031 85-182v-509.5q0-21.969 17.266-39.734Q119.531-749 142.5-749h147l72.057-86.5H598.5l72 86.5h147q21.969 0 39.734 17.766Q875-713.469 875-691.5V-182q0 22.969-17.766 40.234Q839.469-124.5 817.5-124.5h-675Z" />
    </svg>
  );
};

export default CameraIconFilled;
