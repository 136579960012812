import axios from "axios";
import { useContext, useEffect } from "react";
import { Context } from "../../context/context";
import _ from "lodash";

function AuthInitialStatesWrapper({ children }: any) {
  const {
    setBookmarkedAdvertisements,
    setPersonalAdvertisements,
    setPersonalEditedAdvertisements,
    ifAuthenticated,
    shouldFetchAuthInitialStates,
    setShouldFetchAuthInitialStates,
    setAdminAllAdvertisements,
    setAdminAllEditedAdvertisements,
    setAdminAllUsers,
    ifSuperAdmin,
  } = useContext(Context);

  useEffect(() => {
    if (ifAuthenticated === "true" && shouldFetchAuthInitialStates) {
      (async () => {
        //get bookmarked advertisements
        const bookmarkedAdvertisementsResult: any = await axios.get(
          "advertisements/bookmark-advertisement"
        );
        setBookmarkedAdvertisements(
          _.isUndefined(
            bookmarkedAdvertisementsResult?.data?.bookmarkedAdvertisements
          )
            ? []
            : bookmarkedAdvertisementsResult?.data?.bookmarkedAdvertisements
        );
        //get personal advertisement
        const personalAdvertisementsResult: any = await axios.get(
          "advertisements/personal-advertisements"
        );
        setPersonalAdvertisements(
          _.isUndefined(personalAdvertisementsResult.data)
            ? []
            : personalAdvertisementsResult.data
        );
        //get personal edited advertisement
        const personalEditedAdvertisementsResult: any = await axios.get(
          "advertisements/personal-edited-advertisements"
        );
        setPersonalEditedAdvertisements(
          _.isUndefined(personalEditedAdvertisementsResult?.data)
            ? []
            : personalEditedAdvertisementsResult.data
        );
        //set should fetch to false
        setShouldFetchAuthInitialStates(false);
      })();
    }
  }, [ifAuthenticated, shouldFetchAuthInitialStates]);

  useEffect(() => {
    if (
      ifAuthenticated === "true" &&
      ifSuperAdmin === "true" &&
      shouldFetchAuthInitialStates
    ) {
      (async () => {
        //get admin all advertisements
        const adminAllAdvertisements: any = await axios.get(
          "admin/admin-all-advertisements"
        );
        setAdminAllAdvertisements(adminAllAdvertisements?.data);
        //get admin all edited advertisements
        const adminAllEditedAdvertisements: any = await axios.get(
          "admin/admin-all-edited-advertisements"
        );
        setAdminAllEditedAdvertisements(adminAllEditedAdvertisements?.data);
        //get all users
        const adminAllUsers = await axios.get("admin/admin-all-users");
        setAdminAllUsers(adminAllUsers?.data);
        //set should fetch to false
        setShouldFetchAuthInitialStates(false);
      })();
    }
  }, [ifAuthenticated, shouldFetchAuthInitialStates, ifSuperAdmin]);

  return <>{children}</>;
}

export default AuthInitialStatesWrapper;
