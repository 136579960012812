import { useCallback, useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import { Watermark } from "@hirohe/react-watermark";
import { FaChevronCircleRight } from "react-icons/fa";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import ImageViewer from "./imageViewer";
import { imagePlaceholderAdvertisementCardBase64 } from "../util/imagePlaceholderAdvertisementCardBase64";
import { isWebView } from "../util/isWebView";

function AdvertisementImageGallery({ ...prop }) {
  const { imagesArray } = prop;

  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [shouldShowImages, setShouldShowImages] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldShowImages(true);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, []);

  const openModal = () => {
    if (!imagesArray.length) {
      return;
    }
    setShowModal(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setTimeout(() => {
      setCurrentIndex(0);
    }, 500);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };

  return (
    <>
      <div
        id="advertisementImageGalleryWrapper"
        className="advertisementImageGalleryWrapperGeneral advertisementMargin"
      >
        <FaChevronCircleRight
          style={{
            fontSize: "55px",
            color: "rgb(255 255 255 / 63%)",
            position: "absolute",
            margin: "auto",
            top: 0,
            bottom: 0,
            right: 10,
            zIndex: 2,
          }}
          onClick={openModal}
          className="pointerOnHover"
        />{" "}
        {(!imagesArray.length || !shouldShowImages) && (
          <>
            <Spinner
              style={{
                color: "#f7f7f7",
                position: "absolute",
                margin: "auto",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 2,
              }}
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
            />
          </>
        )}
        <Image
          fluid
          rounded
          style={{ width: "800px" }}
          src={
            imagesArray && imagesArray.length && shouldShowImages
              ? imagesArray[0].src
              : imagePlaceholderAdvertisementCardBase64
          }
          onClick={openModal}
          className="pointerOnHover"
        />
        {imagesArray && imagesArray.length && shouldShowImages ? (
          <div style={{ textAlign: "center" }}>
            <small>
              {imagesArray.length}
              {imagesArray.length > 1 ? <> images</> : <> image</>}
            </small>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <small className="text-center">Loading ...</small>
          </div>
        )}
      </div>
      <ImageViewer
        imagesArray={imagesArray}
        showModal={showModal}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        closeModal={closeModal}
        imageLoaded={imageLoaded}
        setImageLoaded={setImageLoaded}
      />
    </>
  );
}

export default AdvertisementImageGallery;
