import "./App.scss";
import MainWrapper from "./layouts/mainWrapper/mainWrapper";
import Sidebar from "./layouts/sidebar/sidebar";
import MainBody from "./layouts/mainBody/mainBody";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Context } from "./context/context";
import ContextValues from "./context/context";
import AuthWrapper from "./auth/authWrapper/authWrapper";
import Axios from "./auth/axios/axios";
import { Helmet } from "react-helmet";
import { routes } from "./routes";
import PublicInitialStatesWrapper from "./initialStates/publicInitialStatesWrapper/publicInitialStatesWrapper";
import AuthInitialStatesWrapper from "./initialStates/authInitialStatesWrapper/authInitialWrapper";
import RedirectWrapper from "./auth/authWrapper/redirectWrapper";

const App = () => {
  const routeComponents = routes.map(({ path, component: Component }, key) => (
    <Route path={path} element={<Component />} key={key} />
  ));

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WhereToJoy</title>
        {/* remove in case you want google to index the app */}
        <meta name="robots" content="noindex" data-react-helmet="true" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      <Context.Provider value={ContextValues()}>
        <Router>
          <Axios>
            <PublicInitialStatesWrapper>
              <AuthInitialStatesWrapper>
                <AuthWrapper>
                  <RedirectWrapper>
                    <MainWrapper>
                      <Sidebar />
                      <MainBody>
                        <Routes>{routeComponents}</Routes>
                      </MainBody>
                    </MainWrapper>
                  </RedirectWrapper>
                </AuthWrapper>
              </AuthInitialStatesWrapper>
            </PublicInitialStatesWrapper>
          </Axios>
        </Router>
      </Context.Provider>
    </>
  );
};

export default App;
