import { useContext, useState } from "react";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import { Context } from "../../context/context";
import AdvertisementCardComponent from "../../pages/advertisement/component/advertisementCardComponent";
import AdvertisementCardPlaceholderComponent from "../../sharedComponents/advertisementCardPlaceholder";
import _ from "lodash";
import AdvertisementCategoryIsEmptyComponent from "../../pages/advertisement/component/advertisementCategoryIsEmpty";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";
import { AdminAdvertisementFilter } from "../adminSharedComponenets/adminAdvertisementFilter";

function AdminAdvertisementsManage() {
  const { ifSuperAdmin, adminAllAdvertisements, adminAllEditedAdvertisements } =
    useContext(Context);

  const [adminAdvertisementsFiltered, setAdminAdvertisementsFiltered] =
    useState("notSet");

  if (ifSuperAdmin === "notSet" || ifSuperAdmin === "false") {
    return null;
  }

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ADMIN_URL, true],
          [routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL, false],
        ]}
      ></BreadCrumb>

      <AdminAdvertisementFilter
        adminAllAdvertisements={adminAllAdvertisements}
        setAdminAdvertisementsFiltered={setAdminAdvertisementsFiltered}
        adminAllEditedAdvertisements={adminAllEditedAdvertisements}
      />

      <GeneralCardLayoutWrapper>
        {adminAdvertisementsFiltered !== "notSet" &&
        adminAdvertisementsFiltered !== "loadingFilteredAdvertisements" ? (
          <>
            {/* @ts-ignore */}
            {_.isArray(adminAdvertisementsFiltered) &&
            !_.isEmpty(adminAdvertisementsFiltered) ? (
              adminAdvertisementsFiltered.map((item: any, index: any) => (
                <AdvertisementCardComponent
                  redirectUrl={`/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}/${item.id}`}
                  key={index}
                  src={`${process.env.REACT_APP_S3_ROUTE}/advertisements/${item.id}/img_0.jpg`}
                  title={item.advertisementTitle}
                  id={item.id}
                  description={item.advertisementDescription}
                />
              ))
            ) : (
              <AdvertisementCategoryIsEmptyComponent
                title="No advertisement to manage by admin"
                text="check details, we received not advertisement from the endpoint"
              />
            )}
          </>
        ) : (
          <>
            <AdvertisementCardPlaceholderComponent />
          </>
        )}
      </GeneralCardLayoutWrapper>
    </>
  );
}

export default AdminAdvertisementsManage;
