import { Form, Button } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/context";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import axios from "axios";
import { useNavigate } from "react-router";
import { UpdatePasswordSuccessfulModal } from "./component/modals";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import FormErrorMessage from "../../sharedComponents/formErrorMessage";
import Recaptcha from "../recaptcha/recaptcha";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../../util/inputFieldErrorMapper";
import { frontendInputFields } from "../../util/frontendInputFields";

function UpdatePassword() {
  const history = useNavigate();

  const { isNarrowScreen, ifAuthenticated, currentUser, openSidebar } =
    useContext(Context);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [updatePasswordError, setUpdatePasswordError] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isDuringSubmission, setIsDuringSubmission] = useState(false);
  const recaptchaRef = useRef<any>();

  const goToForgotPasswordPage = () => {
    history("/dashboard/forgot-password");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsDuringSubmission(true);
    setUpdatePasswordError([]);
    const token = await recaptchaRef.current.executeAsync();
    axios
      .post("auth/update-password", {
        currentPassword,
        newPassword,
        confirmNewPassword,
        recaptchaToken: token,
      })
      .then(function (response) {
        if (response?.status === 200) {
          setIsDuringSubmission(false);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setModalShow(true);
        }
      })
      .catch((error) => {
        setUpdatePasswordError(error?.response?.data?.message);
        setIsDuringSubmission(false);
      });
  };

  useEffect(() => {}, [isNarrowScreen]);

  useEffect(() => {
    const firstInvalidField = document.querySelector(".is-invalid");
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [updatePasswordError]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (ifAuthenticated === "notSet" || ifAuthenticated === "false") {
    return null;
  }

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ACCOUNT_SETTING_URL, true],
          [routesEnum.UPDATE_PASSWORD_URL, false],
        ]}
      ></BreadCrumb>
      <UpdatePasswordSuccessfulModal
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 7 : 6}
        colXl={openSidebar ? 6 : 5}
      >
        <Form onSubmit={handleSubmit}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              value={
                currentUser !== "notSet" && currentUser !== "notAuthenticated"
                  ? currentUser.username
                  : ""
              }
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                updatePasswordError,
                frontendInputFields.USER_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                updatePasswordError,
                frontendInputFields.USER_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                updatePasswordError,
                frontendInputFields.USER_NEW_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                updatePasswordError,
                frontendInputFields.USER_NEW_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Confirm New Password"
              value={confirmNewPassword}
              onChange={(e) => {
                setConfirmNewPassword(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                updatePasswordError,
                frontendInputFields.USER_CONFIRM_NEW_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                updatePasswordError,
                frontendInputFields.USER_CONFIRM_NEW_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringSubmission}
          >
            Send
          </Button>
        </Form>
      </GeneralContainerRowColCard>
    </>
  );
}

export default UpdatePassword;
