const CarIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M320-730 216-834l40-40.5L360.5-770 320-730Zm320 0-40-40 104-104.5 40.5 40.5L640-730Zm-188.5-51.5V-959H509v177.5h-57.5Zm-298 777q-12.4 0-20.7-8.012-8.3-8.013-8.3-20.488v-320.643L209-607q4.571-13.15 15.464-20.825Q235.357-635.5 250-635.5h460q14.643 0 25.536 7.675T751-607l84.5 253.357V-33q0 12.475-8.3 20.488Q818.9-4.5 806.5-4.5h-19q-12.4 0-20.7-8.012-8.3-8.013-8.3-20.488v-54.5h-558V-33q0 12.475-8.3 20.488Q183.9-4.5 171.5-4.5h-18ZM204-411h552l-55.5-167h-441L204-411Zm82.647 215.5q22.853 0 38.103-15.382 15.25-15.383 15.25-37.5 0-23.451-15.382-39.285-15.383-15.833-37.5-15.833-23.451 0-39.285 15.647-15.833 15.647-15.833 39 0 22.853 15.647 38.103t39 15.25Zm387.235 0q23.451 0 39.285-15.382Q729-226.265 729-248.382q0-23.451-15.647-39.285-15.647-15.833-39-15.833-22.853 0-38.103 15.647t-15.25 39q0 22.853 15.382 38.103 15.383 15.25 37.5 15.25Z" />
    </svg>
  );
};

export default CarIconFilled;
