const CameraIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M479.5-269.5q71.75 0 119.625-47.875T647-437q0-71-47.875-118.75T479.5-603.5q-71.75 0-119.125 47.75T313-437q0 71.75 47.375 119.625T479.5-269.5Zm0-57.5q-47 0-78-31.145T370.5-437q0-47 31-78t78-31q47 0 78.5 31t31.5 78.25q0 47.25-31.5 78.5T479.5-327Zm-337 202.5q-22.969 0-40.234-17.266Q85-159.031 85-182v-509.5q0-21.969 17.266-39.734Q119.531-749 142.5-749h147l72.057-86.5H598.5l72 86.5h147q21.969 0 39.734 17.766Q875-713.469 875-691.5V-182q0 22.969-17.766 40.234Q839.469-124.5 817.5-124.5h-675Zm675-57.5v-509.5H644L571.5-778H388.451L316.5-691.5h-174V-182h675ZM480-436.5Z" />
    </svg>
  );
};

export default CameraIcon;
