import { Alert, Button, Card } from "react-bootstrap";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  ChangeStatusModal,
  RedirectModal,
} from "../../sharedComponents/modals";
import { Context } from "../../context/context";
import { useLocation, useNavigate, useParams } from "react-router";
import VisibilityLockIcon from "../../icons/visibilityLockIcon/visibilityLockIcon";
import { advertisementStatuses } from "../../util/advertisementStatuses";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import { isWebView } from "../../util/isWebView";

function AdminAdvertisementManageBox() {
  let { advertisementId } = useParams();
  const recaptchaRef = useRef<any>();
  const [advertisement, setAdvertisement] = useState<any>(null);
  const [editedAdvertisement, setEditedAdvertisement] = useState<any>(null);
  const [advertisementStatusToBeSet, setAdvertisementStatusToBeSet] = useState(
    advertisementStatuses.pendingInitialSubmit
  );
  const [shouldModalIncludeTextBox, setShouldModalIncludeTextBox] =
    useState(false);
  const [modalTextBoxContent, setModalTextBoxContent] = useState("");
  const [advertisementStatusModalMessage, setAdvertisementStatusModalMessage] =
    useState("");
  const [
    advertisementStatusModalMainButtonWording,
    setAdvertisementStatusModalMainButtonWording,
  ] = useState("");
  const [redirectModalButtonWording, setRedirectModalButtonWording] =
    useState("");
  const [advertisementStatusWording, setAdvertisementStatusWording] =
    useState("");
  const [
    editedAdvertisementStatusWording,
    setEditedAdvertisementStatusWording,
  ] = useState("");
  const [
    advertisementChangeStatusModalShow,
    setAdvertisementChangeStatusModalShow,
  ] = useState(false);
  const [redirectModalMessage, setRedirectModalMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [checkEditedVersionModalShow, setCheckEditedVersionModalShow] =
    useState(false);
  const [changeStatusInProgress, setChangeStatusInProgress] = useState(false);
  const [changeStatusAdvertisementError, setChangeStatusAdvertisementError] =
    useState([]);
  const {
    setShouldFetchAuthInitialStates,
    setShouldFetchPublicInitialStates,
    adminAllEditedAdvertisements,
    adminAllAdvertisements,
    ifAuthenticated,
    ifSuperAdmin,
  } = useContext(Context);
  const history = useNavigate();
  const [
    doesAdvertisementHaveEditedVersion,
    setDoesAdvertisementHaveEditedVersion,
  ] = useState("notSet");

  useEffect(() => {
    if (advertisement) {
      if (
        advertisement.advertisementStatus === advertisementStatuses.softDelete
      ) {
        setAdvertisementStatusWording(
          "soft Deleted, the advertisement is soft deleted and not visible to anyone except the admins."
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAdminForce
      ) {
        setAdvertisementStatusWording(
          "pending, admin has forced the advertisement to go into pending mode"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChange
      ) {
        setAdvertisementStatusWording(
          "pending, after request change by admin, advertisement will become pending"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChangeAddressed
      ) {
        setAdvertisementStatusWording(
          "pending, thanks for addressing the change request, we are currently reviewing your advertisement"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterUnarchive
      ) {
        setAdvertisementStatusWording(
          "pending, after unarchive, the advertisement will become pending"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterEdit
      ) {
        setAdvertisementStatusWording(
          "pending, edit the advertisement before first approval will change the status to pending"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingInitialSubmit
      ) {
        setAdvertisementStatusWording(
          "pending, after creating the advertisement for the first time, it will go to pending mode"
        );
      } else if (
        advertisement.advertisementStatus === advertisementStatuses.approve
      ) {
        setAdvertisementStatusWording(
          "approved, visible to everyone, everywhere!"
        );
      } else if (
        advertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChange
      ) {
        setAdvertisementStatusWording(
          "request Change, please edit the advertisement so it can be reviewed by the admins!"
        );
      } else if (
        advertisement.advertisementStatus === advertisementStatuses.archive
      ) {
        setAdvertisementStatusWording(
          "archived, the advertisement is archived and is only visible to you, you can unarchive it to make it visible to everyone everywhere!"
        );
      }
    }
    if (editedAdvertisement) {
      if (
        editedAdvertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChange
      ) {
        setEditedAdvertisementStatusWording(
          "pending, after request change by admin, please edit again and address the request changes."
        );
      } else if (
        editedAdvertisement.advertisementStatus ===
        advertisementStatuses.pendingAfterRequestChangeAddressed
      ) {
        setEditedAdvertisementStatusWording(
          "pending, thanks for addressing the change request, we are currently reviewing it."
        );
      } else if (
        editedAdvertisement.advertisementStatus ===
        advertisementStatuses.pendingInitialSubmit
      ) {
        setEditedAdvertisementStatusWording(
          "pending, we have received the edited version and currently reviewing it."
        );
      }
    }
  }, [advertisement, editedAdvertisement]);

  useEffect(() => {
    if (advertisementId && adminAllAdvertisements !== "notSet") {
      const selectedAdvertisement = _.find(adminAllAdvertisements, {
        id: advertisementId,
      });
      if (selectedAdvertisement) {
        setAdvertisement(selectedAdvertisement);
      }
    }

    if (advertisementId && adminAllEditedAdvertisements !== "notSet") {
      const editedAdvertisementVersion = _.find(adminAllEditedAdvertisements, {
        advertisementId,
      });
      if (editedAdvertisementVersion) {
        setEditedAdvertisement(editedAdvertisementVersion);
        setDoesAdvertisementHaveEditedVersion("true");
      } else {
        setDoesAdvertisementHaveEditedVersion("false");
      }
    }
  }, [adminAllAdvertisements, adminAllEditedAdvertisements]);

  const changeStatusAdvertisement = async (advertisementStatus: number) => {
    setChangeStatusAdvertisementError([]);
    if (advertisementStatus === advertisementStatuses.pendingAfterEdit) {
      history(
        `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${
          routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL
        }/${routesEnum.ADVERTISEMENT_EDIT_URL}/${
          doesAdvertisementHaveEditedVersion === "true"
            ? editedAdvertisement.id
            : advertisement.id
        }`
      );

      return;
    }
    setChangeStatusInProgress(true);

    try {
      let token = "";
      if (shouldModalIncludeTextBox) {
        token = await recaptchaRef.current.executeAsync();
      }
      await axios.post("admin/admin-change-advertisement-status", {
        advertisementId: advertisement.id,
        advertisementStatus,
        adminAdvertisementRequestChangeReason: modalTextBoxContent,
        recaptchaToken: token,
        shouldModalIncludeTextBox,
      });
      setModalTextBoxContent("");
      setAdvertisementChangeStatusModalShow(false);
      if (isWebView()) {
        (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
      }
      if (advertisementStatus === advertisementStatuses.permanentlyDelete) {
        history("/dashboard/admin/admin-advertisements-manage");
      }
      setChangeStatusInProgress(false);
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    } catch (error: any) {
      setChangeStatusInProgress(false);
      setChangeStatusAdvertisementError(error?.response?.data?.message);
    }
  };

  const removeErrorsAndTextAndCloseModal = () => {
    setChangeStatusAdvertisementError([]);
    setModalTextBoxContent("");
    setModalTextBoxContent("");
  };

  const location = useLocation();

  return (
    <>
      {ifAuthenticated === "true" &&
        ifSuperAdmin &&
        location.pathname ===
          `/dashboard/admin/admin-advertisements-manage/${advertisementId}` && (
          <>
            <ChangeStatusModal
              modalShow={advertisementChangeStatusModalShow}
              setModalShow={setAdvertisementChangeStatusModalShow}
              removeErrorsAndTextAndCloseModal={
                removeErrorsAndTextAndCloseModal
              }
              changeStatusInProgress={changeStatusInProgress}
              onChangeStatus={() =>
                changeStatusAdvertisement(advertisementStatusToBeSet)
              }
              message={advertisementStatusModalMessage}
              changeStatusAdvertisementError={changeStatusAdvertisementError}
              changeStatusButtonWording={
                advertisementStatusModalMainButtonWording
              }
              shouldModalIncludeTextBox={shouldModalIncludeTextBox}
              setModalTextBoxContent={setModalTextBoxContent}
              recaptchaRef={recaptchaRef}
              modalTextBoxContent={modalTextBoxContent}
            />
            <RedirectModal
              modalShow={checkEditedVersionModalShow}
              setModalShow={setCheckEditedVersionModalShow}
              button={redirectModalButtonWording}
              message={redirectModalMessage}
              url={redirectUrl}
            />
            <Card
              border="danger"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              <Card.Body>
                <div className="text-center mb-3">
                  <VisibilityLockIcon
                    style={{
                      position: "relative",
                      bottom: "1px",
                      right: "2px",
                    }}
                  />
                  Admin View, this Section is only visible to you
                </div>
                <Alert
                  variant="light"
                  className="border textNotSelectable"
                  style={{ background: "#f8f9fa" }}
                >
                  <b style={{ color: "black" }}>Advertisement Status:</b>
                  <br />
                  <br />{" "}
                  {doesAdvertisementHaveEditedVersion === "false" &&
                    advertisementStatusWording}
                  {doesAdvertisementHaveEditedVersion === "true" &&
                    ` The edited version is ${editedAdvertisementStatusWording}`}
                  <br />
                  <br />
                  {doesAdvertisementHaveEditedVersion === "true" &&
                    `The current version is ${advertisementStatusWording}`}
                </Alert>

                <Button
                  variant="light"
                  className="border me-2 mb-2"
                  onClick={() => {
                    setAdvertisementStatusToBeSet(
                      advertisementStatuses.pendingAfterEdit
                    );
                    setAdvertisementStatusModalMessage(
                      "Are you sure? you are about to edit your advertisement, after the edited version will only be visible after admin approval"
                    );
                    setAdvertisementStatusModalMainButtonWording("Edit");
                    setShouldModalIncludeTextBox(false);
                    setAdvertisementChangeStatusModalShow(true);
                  }}
                  size="sm"
                >
                  Edit
                </Button>

                {advertisement?.advertisementStatus ===
                  advertisementStatuses.approve && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.archive
                      );
                      setAdvertisementStatusModalMessage(
                        "This will archive the advertisement and it will be only visible to you afterwards, you can unarchive it anytime later."
                      );
                      setAdvertisementStatusModalMainButtonWording("Archive");
                      setShouldModalIncludeTextBox(false);
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Archive
                  </Button>
                )}
                {advertisement?.advertisementStatus ===
                  advertisementStatuses.archive && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.pendingAfterUnarchive
                      );
                      setAdvertisementStatusModalMessage(
                        "This will unarchive the advertisement and make it visible to everyone everywhere!"
                      );
                      setAdvertisementStatusModalMainButtonWording("Unarchive");
                      setShouldModalIncludeTextBox(false);
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Unarchive
                  </Button>
                )}
                {advertisement?.advertisementStatus !==
                  advertisementStatuses.softDelete && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.softDelete
                      );
                      setAdvertisementStatusModalMessage(
                        "Are you sure? this will delete the advertisement and edited versions of the same advertisement"
                      );
                      setAdvertisementStatusModalMainButtonWording("Delete");
                      setShouldModalIncludeTextBox(false);
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Soft Delete
                  </Button>
                )}
                {advertisement?.advertisementStatus !==
                  advertisementStatuses.pendingAdminForce && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.pendingAdminForce
                      );
                      setAdvertisementStatusModalMessage(
                        "Are you sure? this will change the advertisement status to pending and afterwards it will only be visible to the user"
                      );
                      setAdvertisementStatusModalMainButtonWording(
                        "Make Pending"
                      );
                      setShouldModalIncludeTextBox(false);
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Make Pending
                  </Button>
                )}
                <Button
                  variant="light"
                  className="border me-2 mb-2"
                  onClick={() => {
                    setAdvertisementStatusToBeSet(
                      advertisementStatuses.permanentlyDelete
                    );
                    setAdvertisementStatusModalMessage(
                      "Are you sure? this will permanently delete the advertisement even from the database records!"
                    );
                    setAdvertisementStatusModalMainButtonWording(
                      "Permanently Delete"
                    );
                    setShouldModalIncludeTextBox(false);
                    setAdvertisementChangeStatusModalShow(true);
                  }}
                  size="sm"
                >
                  Permanently Delete
                </Button>
                {advertisement?.advertisementStatus !==
                  advertisementStatuses.approve && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.approve
                      );
                      setAdvertisementStatusModalMessage(
                        "Are you sure? this will approve the advertisement and make it visible to everyone, everywhere!"
                      );
                      setAdvertisementStatusModalMainButtonWording("Approve");
                      setShouldModalIncludeTextBox(false);
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Approve
                  </Button>
                )}
                {advertisement?.advertisementStatus !==
                  advertisementStatuses.pendingAfterRequestChange && (
                  <Button
                    variant="light"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setAdvertisementStatusToBeSet(
                        advertisementStatuses.pendingAfterRequestChange
                      );
                      setAdvertisementStatusModalMessage(
                        "Are you sure? this will send an email to user and tell them what they need to change inside the advertisement and resubmit it for revision"
                      );
                      setShouldModalIncludeTextBox(true);
                      setAdvertisementStatusModalMainButtonWording(
                        "Request Change"
                      );
                      setAdvertisementChangeStatusModalShow(true);
                    }}
                    size="sm"
                  >
                    Request Change
                  </Button>
                )}
                {doesAdvertisementHaveEditedVersion === "true" && (
                  <Button
                    variant="success"
                    className="border me-2 mb-2"
                    onClick={() => {
                      setCheckEditedVersionModalShow(true);
                      setRedirectModalMessage(
                        "Are you sure? you are about to see the edited version of the advertisement which is not visible to anyone except admins"
                      );
                      setRedirectModalButtonWording("Check Edited Version");
                      setRedirectUrl(
                        `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}/${routesEnum.ADVERTISEMENT_EDITED_VERSION_URL}/${editedAdvertisement.id}/${advertisement.id}`
                      );
                    }}
                    size="sm"
                  >
                    Check Edited Version
                  </Button>
                )}
              </Card.Body>
            </Card>
          </>
        )}
    </>
  );
}

export default AdminAdvertisementManageBox;
