const VisibilityLockIcon = (style: any) => {
  return (
    <span style={style.style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="black"
      >
        <path d="M480.299-358.461q58.393 0 99.816-41.722 41.424-41.722 41.424-100.116 0-58.393-41.722-99.816-41.722-41.424-100.116-41.424-58.393 0-99.817 41.722-41.423 41.722-41.423 100.116 0 58.393 41.722 99.817 41.722 41.423 100.116 41.423Zm-.67-31.847q-45.86 0-77.591-32.101-31.73-32.102-31.73-77.962 0-45.86 32.101-77.591 32.102-31.73 77.962-31.73 45.86 0 77.591 32.101 31.73 32.102 31.73 77.962 0 45.86-32.101 77.591-32.102 31.73-77.962 31.73ZM480.11-240q-129.187 0-235.649-71.077Q138-382.154 83.077-500 138-617.846 244.35-688.923 350.703-760 479.89-760q129.187 0 235.649 71.077Q822-617.846 876.923-500 822-382.154 715.649-311.077 609.297-240 480.11-240ZM480-500Zm-.169 229.231q117.323 0 214.977-62.039Q792.462-394.846 843.923-500q-51.461-105.154-148.947-167.192-97.485-62.039-214.807-62.039-117.323 0-214.977 62.039Q167.538-605.154 115.846-500q51.692 105.154 149.178 167.192 97.485 62.039 214.807 62.039Z" />
      </svg>
    </span>
  );
};

export default VisibilityLockIcon;
