import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import useAdvertisementPageHook from "../advertisement/hook/useAdvertisementPageHook";
import Image from "react-bootstrap/Image";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import AdvertisementShow from "../../sharedComponents/advertisementShow";
import Error from "../error/error";
import { Context } from "../../context/context";
import randomstring from "randomstring";

function AdvertisementCreatedShow() {
  const {
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
    openSidebar,
  } = useContext(Context);
  const {
    selectedAdvertisementType,
    ifAuthenticated,
    ifSuperAdmin,
    findAdvertisementInFrontendById,
    // findAdvertisementVisibility,
  } = useAdvertisementPageHook();
  let { advertisementCategory, advertisementSubCategory, advertisementId } =
    useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [imagesArray, setImagesArray] = useState<any[]>([]);
  // const [
  //   selectedAdvertisementVisibilityStatus,
  //   setSelectedAdvertisementVisibilityStatus,
  // ] = useState<any>(null);
  const [selectedAdvertisement, setSelectedAdvertisement] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        setSelectedAdvertisement(
          await findAdvertisementInFrontendById(advertisementId)
        );
        // setSelectedAdvertisementVisibilityStatus(
        //   await findAdvertisementVisibility(advertisementId)
        // );
        const response: any = await axios.get(
          `advertisements/get-advertisement-images/${advertisementId}`
        );
        const images = await Promise.all(
          response.data.images.map(async (obj: any, index: any) => {
            if (index === 0) {
              obj.id = "img_cover";
            } else {
              obj.id = `img_${randomstring.generate(12)}`;
            }
            const storedImageInS3 = await axios.get(
              `advertisements/get-advertisement-object-by-s3-url/${advertisementId}/${index}`
            );
            obj.src =
              "data:image/jpeg;base64," + storedImageInS3.data.imageObject;
            obj.data =
              "data:image/jpeg;base64," + storedImageInS3.data.imageObject;

            return obj;
          })
        );
        setImagesArray(images);
      } catch (e: any) {}
    })();
  }, [
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
  ]);

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [advertisementCategory, true],
          [advertisementSubCategory, true],
          ["details", false],
        ]}
      ></BreadCrumb>

      <GeneralContainerRowColCard
        colMd={openSidebar ? 12 : 12}
        colLg={openSidebar ? 12 : 12}
        colXl={openSidebar ? 12 : 12}
      >
        {selectedAdvertisement && selectedAdvertisement !== "notSet" ? (
          <AdvertisementShow
            selectedAdvertisementId={selectedAdvertisement.id}
            advertisementTitle={selectedAdvertisement.advertisementTitle}
            advertisementDescription={
              selectedAdvertisement.advertisementDescription
            }
            businessPhoneNumber={selectedAdvertisement.businessPhoneNumber}
            businessAddress={selectedAdvertisement.businessAddress}
            businessState={selectedAdvertisement.businessState}
            businessRegion={selectedAdvertisement.businessRegion}
            websiteLink={selectedAdvertisement.websiteLink}
            businessEmail={selectedAdvertisement.businessEmail}
            abn={selectedAdvertisement.abn}
            selectedAdvertisementType={selectedAdvertisementType}
            imagesArray={imagesArray}
          />
        ) : (
          // : selectedAdvertisementVisibilityStatus === "notFoundInBackend" ? (
          //   "Couldn't find the joy, it might be deleted"
          // ) : ifAuthenticated === "true" &&
          //   ifSuperAdmin === "false" &&
          //   selectedAdvertisementVisibilityStatus === "onlyVisibleToAdmins" ? (
          //   "You should be an admin to be able to see this advertisement"
          // )
          "Loading ....."
        )}
      </GeneralContainerRowColCard>
    </>
  );
}

export default AdvertisementCreatedShow;
