import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Context } from "../../context/context";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";

function AccountSetting() {
  const history = useNavigate();
  const { ifAuthenticated } = useContext(Context);

  const goToUpdatePasswordPage = () => {
    history(
      `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}/${routesEnum.UPDATE_PASSWORD_URL}`
    );
  };

  const goToAccountDeletionPage = () => {
    history(
      `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}/${routesEnum.ACCOUNT_AND_DATA_DELETION_URL}`
    );
  };

  const goToForgotPasswordPage = () => {
    history(
      `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}/${routesEnum.SET_NEW_PASSWORD_URL}`
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (ifAuthenticated === "notSet" || ifAuthenticated === "false") {
    return null;
  }

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ACCOUNT_SETTING_URL, false],
        ]}
      ></BreadCrumb>
      <GeneralCardLayoutWrapper>
        <Card
          className="shadow pointerOnHover advertisementCard"
          onClick={goToForgotPasswordPage}
          style={{ backgroundColor: "" }}
        >
          <Card.Body>
            <Card.Title className="text-center mb-0">
              Set New Password
            </Card.Title>
          </Card.Body>
        </Card>
        {/* <Card
          className="shadow pointerOnHover advertisementCard"
          onClick={goToUpdatePasswordPage}
          style={{ backgroundColor: "" }}
        >
          <Card.Body>
            <Card.Title className="text-center mb-0">
              Update Password
            </Card.Title>
          </Card.Body>
        </Card> */}
        <Card
          className="shadow pointerOnHover advertisementCard"
          onClick={goToAccountDeletionPage}
          style={{ backgroundColor: "" }}
        >
          <Card.Body>
            <Card.Title className="text-center mb-0">
              Account & Data Deletion
            </Card.Title>
          </Card.Body>
        </Card>
      </GeneralCardLayoutWrapper>
    </>
  );
}

export default AccountSetting;
