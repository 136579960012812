import useAuthHook from "./hook/useAuthHook";
import { useEffect } from "react";

function AuthWrapper({ children }: any) {
  const { checkIfAuthenticated } = useAuthHook();
  useEffect(() => {
    checkIfAuthenticated();
  }, []);
  return <>{children}</>;
}

export default AuthWrapper;
