import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Context } from "../../context/context";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import _ from "lodash";
import { GeneralModal } from "../../sharedComponents/modals";
import useAdvertisementPageHook from "../advertisement/hook/useAdvertisementPageHook";
import randomstring from "randomstring";
import { base64ToBlob } from "../../util/base64ToBlob";
import AdvertisementForm from "../../sharedComponents/advertisementForm";

const formData = new FormData();

const AdvertisementEdit = () => {
  const recaptchaRef = useRef<any>();
  const [advertisementFormError, setAdvertisementFormError] = useState([]);
  const [mainCategory, setMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [advertisementDescription, setAdvertisementDescription] = useState("");
  const [advertisementTitle, setAdvertisementTitle] = useState("");
  const [abn, setAbn] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessRegion, setBusinessRegion] = useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [imagesArray, setImagesArray] = useState<any[]>([]);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [imagesThumbnail, setImagesThumbnail] = useState<any[]>([]);
  const [isDuringFormSubmission, setIsDuringFormSubmission] = useState(false);
  const [advertisementEditModalShow, setAdvertisementEditModalShow] =
    useState(false);
  const [advertisementEditModalMessage, setAdvertisementEditModalMessage] =
    useState("");
  const [isAbnActive, setIsAbnActive] = useState("notSet");
  const [
    advertisementRequestChangeReasonText,
    setAdvertisementRequestChangeReasonText,
  ] = useState("");
  const history = useNavigate();
  const { findAdvertisementInFrontendById } = useAdvertisementPageHook();
  let { advertisementId } = useParams();
  const [currentAdvertisementId, setCurrentAdvertisementId] = useState("");
  const {
    shouldFetchAuthInitialStates,
    setShouldFetchAuthInitialStates,
    setShouldFetchPublicInitialStates,
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
  } = useContext(Context);

  useEffect(() => {
    if (advertisementId) {
      const advertisementToBeEdited =
        findAdvertisementInFrontendById(advertisementId);
      (async () => {
        const response: any = await axios.get(
          `advertisements/get-advertisement-images/${advertisementId}`
        );
        const images = await Promise.all(
          response.data.images.map(async (obj: any, index: any) => {
            if (index === 0) {
              obj.id = "img_cover";
            } else {
              obj.id = `img_${randomstring.generate(12)}`;
            }
            const storedImageInS3 = await axios.get(
              `advertisements/get-advertisement-object-by-s3-url/${advertisementId}/${index}`
            );
            obj.src =
              "data:image/jpeg;base64," + storedImageInS3.data.imageObject;
            obj.data =
              "data:image/jpeg;base64," + storedImageInS3.data.imageObject;

            return obj;
          })
        );

        setImagesThumbnail(images);
        setImagesArray(images);
      })();
      setAdvertisementRequestChangeReasonText(
        advertisementToBeEdited?.advertisementRequestChangeReason
      );
      setAdvertisementTitle(advertisementToBeEdited?.advertisementTitle);
      setMainCategory(advertisementToBeEdited?.advertisementCategory);
      setSubCategory(advertisementToBeEdited?.advertisementSubCategory);
      setAdvertisementDescription(
        advertisementToBeEdited?.advertisementDescription
      );
      setAbn(advertisementToBeEdited?.abn);
      setWebsiteLink(
        advertisementToBeEdited?.websiteLink !==
          `${process.env.REACT_APP_DEFAULT_FRONTEND_URL}`
          ? advertisementToBeEdited?.websiteLink?.replace(/https?:\/\//g, "")
          : ""
      );
      setBusinessEmail(
        advertisementToBeEdited?.businessEmail !==
          `${process.env.REACT_APP_DEFAULT_WHERETOJOY_MAIN_EMAIL}`
          ? advertisementToBeEdited?.businessEmail
          : ""
      );
      setBusinessPhoneNumber(advertisementToBeEdited?.businessPhoneNumber);
      setBusinessAddress(advertisementToBeEdited?.businessAddress);
      setBusinessState(advertisementToBeEdited?.businessState);
      setBusinessRegion(advertisementToBeEdited?.businessRegion);
      setCurrentAdvertisementId(
        advertisementToBeEdited?.advertisementId || advertisementToBeEdited?.id
      );
    }
  }, [
    advertisementId,
    allAdvertisements,
    personalAdvertisements,
    personalEditedAdvertisements,
    adminAllAdvertisements,
    adminAllEditedAdvertisements,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsDuringFormSubmission(true);
    setAdvertisementFormError([]);
    const token = await recaptchaRef.current.executeAsync();
    const advertisementToBeEdited =
      findAdvertisementInFrontendById(advertisementId);
    try {
      const result = await axios.post("advertisements/edit", {
        mainCategory: parseInt(mainCategory),
        subCategory: parseInt(subCategory),
        advertisementDescription,
        advertisementTitle,
        abn: parseInt(abn),
        websiteLink: websiteLink.length
          ? websiteLink.toLowerCase().includes("http")
            ? websiteLink.toLowerCase()
            : "https://" + websiteLink.toLowerCase()
          : `${process.env.REACT_APP_DEFAULT_FRONTEND_URL}`,
        businessEmail:
          businessEmail ||
          `${process.env.REACT_APP_DEFAULT_WHERETOJOY_MAIN_EMAIL}`,
        businessAddress: businessAddress,
        businessState,
        businessRegion,
        businessPhoneNumber,
        advertisementToBeEditedId: advertisementToBeEdited.id,
        hasImageCover: imagesArray.some((item) => item.id === "img_cover"),
        hasSupportingImages: imagesArray.length > 1,
        recaptchaToken: token,
      });
      setAdvertisementEditModalShow(true);
      setAdvertisementEditModalMessage("Loading ...");
      const advertisementId = result.data.advertisementId;
      const editedAdvertisementId = result.data.editedAdvertisementId;
      const uploadUrlsArrays = await axios.all(
        imagesArray.map((image, index) => {
          return axios.post("advertisements/get-presigned-url", {
            advertisementId: editedAdvertisementId || advertisementId,
            index,
          });
        })
      );
      try {
        await axios.all(
          uploadUrlsArrays.map((uploadUrl, index) => {
            return axios
              .put(
                uploadUrl.data.uploadUrl,
                base64ToBlob(imagesArray[index].data, "image/jpeg"),
                {
                  withCredentials: false,
                  headers: {
                    "Content-Type": "image/jpeg",
                  },
                }
              )
              .catch((error) => {
                throw new Error(
                  `Error uploading image ${index + 1}: ${error.message}`
                );
              });
          })
        );
      } catch (error: any) {
        console.error(error.message);
      }
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
      setAdvertisementEditModalShow(false);
      if (!shouldFetchAuthInitialStates && !shouldFetchAuthInitialStates) {
        history(
          `/dashboard/advertisement-manage/${advertisementId}?editedCompleted=true`
        );
      }
    } catch (error: any) {
      setIsDuringFormSubmission(false);
      setAdvertisementFormError(error?.response?.data?.message);
    }
  };

  const imagesArrayPlaceholder = [
    {
      src: "/image-placeholder-advertisement-card-sample.png",
      data: "/image-placeholder-advertisement-card-sample.png",
    },
  ];

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ADVERTISEMENT_MANAGE_URL, true],
          [
            currentAdvertisementId,
            true,
            "current-advertisement",
            `/dashboard/advertisement-manage/${currentAdvertisementId}`,
          ],
          ["editing", false],
        ]}
      ></BreadCrumb>
      <GeneralModal
        modalShow={advertisementEditModalShow}
        setModalShow={setAdvertisementEditModalShow}
        message={advertisementEditModalMessage}
      />
      <AdvertisementForm
        handleSubmit={handleSubmit}
        imagesArrayPlaceholder={imagesArrayPlaceholder}
        advertisementFormError={advertisementFormError}
        mainCategory={mainCategory}
        setMainCategory={setMainCategory}
        subCategory={subCategory}
        setSubCategory={setSubCategory}
        advertisementDescription={advertisementDescription}
        setAdvertisementDescription={setAdvertisementDescription}
        advertisementTitle={advertisementTitle}
        setAdvertisementTitle={setAdvertisementTitle}
        abn={abn}
        setAbn={setAbn}
        websiteLink={websiteLink}
        setWebsiteLink={setWebsiteLink}
        businessEmail={businessEmail}
        setBusinessEmail={setBusinessEmail}
        businessAddress={businessAddress}
        setBusinessAddress={setBusinessAddress}
        businessState={businessState}
        setBusinessState={setBusinessState}
        businessRegion={businessRegion}
        setBusinessRegion={setBusinessRegion}
        businessPhoneNumber={businessPhoneNumber}
        setBusinessPhoneNumber={setBusinessPhoneNumber}
        imagesArray={imagesArray}
        setImagesArray={setImagesArray}
        previewCanvasRef={previewCanvasRef}
        imagesThumbnail={imagesThumbnail}
        setImagesThumbnail={setImagesThumbnail}
        isDuringFormSubmission={isDuringFormSubmission}
        submitButtonWording="Edit"
        isAbnActive={isAbnActive}
        setIsAbnActive={setIsAbnActive}
        recaptchaRef={recaptchaRef}
        advertisementRequestChangeReasonText={
          advertisementRequestChangeReasonText
        }
      />
    </>
  );
};

export default AdvertisementEdit;
