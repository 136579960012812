import { Row, Col, Form, Button } from "react-bootstrap";

import {
  GoogleLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/context";
import { useNavigate } from "react-router";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import axios from "axios";
import { RegistrationCompletedModal } from "./component/modals";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import FormErrorMessage from "../../sharedComponents/formErrorMessage";
import { isWebView } from "../../util/isWebView";
import Recaptcha from "../recaptcha/recaptcha";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../../util/inputFieldErrorMapper";
import { frontendInputFields } from "../../util/frontendInputFields";
import {
  GeneralModal,
  TermsAndConditionsModal,
} from "../../sharedComponents/modals";
import { TermsAndConditionsContent } from "../../sharedComponents/termsAndConditions";
import HorizontalOr from "../../sharedComponents/horizontalOr";

function Register() {
  const history = useNavigate();
  const recaptchaRef = useRef<any>();
  const {
    ifAuthenticated,
    isNarrowScreen,
    openSidebar,
    setIfAuthenticated,
    setIfSuperAdmin,
    setCurrentUser,
  } = useContext(Context);

  const registerWithGoogle = () => {
    if (!termsAndConditionsOauthAccepted) {
      if (termsAndConditionsOauthAcceptedShouldShowError) {
        setTermsAndConditionsOauthAcceptedShouldShowError(false);
        setTimeout(() => {
          setTermsAndConditionsOauthAcceptedShouldShowError(true);
        }, 500);
        return;
      } else {
        setTermsAndConditionsOauthAcceptedShouldShowError(true);
        return;
      }
    }
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("googleRegisterClicked");
    } else {
      window.location.href = process.env.REACT_APP_GOOGLE_REGISTER_ROUTE as any;
    }
  };

  const registerWithFacebook = () => {
    if (!termsAndConditionsOauthAccepted) {
      if (termsAndConditionsOauthAcceptedShouldShowError) {
        setTermsAndConditionsOauthAcceptedShouldShowError(false);
        setTimeout(() => {
          setTermsAndConditionsOauthAcceptedShouldShowError(true);
        }, 500);
        return;
      } else {
        setTermsAndConditionsOauthAcceptedShouldShowError(true);
        return;
      }
    }
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("facebookRegisterClicked");
    } else {
      window.location.href = window.location.href = process.env
        .REACT_APP_FACEBOOK_REGISTER_ROUTE as any;
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationError, setRegistrationError] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isDuringSubmission, setIsDuringSubmission] = useState(false);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [termsAndConditionsOauthAccepted, setTermsAndConditionsOauthAccepted] =
    useState(false);
  const [
    termsAndConditionsOauthAcceptedShouldShowError,
    setTermsAndConditionsOauthAcceptedShouldShowError,
  ] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsDuringSubmission(true);
    setRegistrationError([]);
    const token = await recaptchaRef.current.executeAsync();
    axios
      .post("auth/register", {
        email,
        password,
        confirmPassword,
        recaptchaToken: token,
        termsAndConditionsAccepted,
      })
      .then(function (response) {
        if (response.status === 201) {
          setIsDuringSubmission(false);
          setEmail("");
          setPassword("");
          setConfirmPassword("");

          setModalShow(true);
        }
      })
      .catch((error) => {
        setRegistrationError(error?.response?.data?.message);
        setIsDuringSubmission(false);
      });
  };

  const goToLoginPage = () => {
    history("/login");
  };

  const openTermsAndConditionsModal = () => {
    setShowTermsAndConditionsModal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const firstInvalidField = document.querySelector(".is-invalid");
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [registrationError]);

  useEffect(() => {
    if (ifAuthenticated === "true") {
      const redirectUrl = sessionStorage.getItem("redirectUrl");
      sessionStorage.removeItem("redirectUrl");
      history(redirectUrl || "/dashboard");
    }
  }, [ifAuthenticated, isNarrowScreen]);

  if (ifAuthenticated === "notSet" || ifAuthenticated === "true") {
    return null;
  }

  const handleSubmitGoogleRegisterAuthMobile = async (event: any) => {
    event.preventDefault();
    setRegistrationError([]);
    const idToken = event.target.googleRegisterAuthMobile.value;
    axios
      .post("auth/mobile-auth-google-register", {
        idToken,
      })
      .then(function (response) {
        if (response?.status === 200) {
          setIfAuthenticated("true");
          setCurrentUser(response.data);
          if (
            response?.data?.permissions &&
            response?.data?.permissions.includes("superAdmin")
          ) {
            setIfSuperAdmin("true");
          } else {
            setIfSuperAdmin("false");
          }
        }
      })
      .catch((error) => {});
  };

  const handleSubmitFacebookRegisterAuthMobile = async (event: any) => {
    event.preventDefault();
    setRegistrationError([]);
    const accessToken =
      event.target.facebookRegisterAuthMobileAccessToken.value;
    const userId = event.target.facebookRegisterAuthMobileUserId.value;
    const authorisationToken =
      event.target.facebookRegisterAuthMobileAuthorisationToken.value;
    axios
      .post("auth/mobile-auth-facebook-register", {
        accessToken,
        userId,
        authorisationToken,
      })
      .then(function (response) {
        if (response?.status === 200) {
          setIfAuthenticated("true");
          setCurrentUser(response.data);
          if (
            response?.data?.permissions &&
            response?.data?.permissions.includes("superAdmin")
          ) {
            setIfSuperAdmin("true");
          } else {
            setIfSuperAdmin("false");
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.REGISTER_URL, false],
        ]}
      ></BreadCrumb>
      <TermsAndConditionsModal
        modalShow={showTermsAndConditionsModal}
        setModalShow={setShowTermsAndConditionsModal}
        message={TermsAndConditionsContent}
        title="Terms and Conditions"
      />
      <RegistrationCompletedModal
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 7 : 6}
        colXl={openSidebar ? 6 : 5}
      >
        <>
          <GoogleLoginButton
            className="loginButtons"
            onClick={registerWithGoogle}
            text="Register With Google"
            style={{
              boxShadow: "none",
              border: "1px solid #C8C8C8",
              width: "100%",
              marginRight: 0,
              marginLeft: 0,
            }}
          ></GoogleLoginButton>
          <FacebookLoginButton
            className="loginButtons"
            onClick={registerWithFacebook}
            text="Register With Facebook"
            style={{
              boxShadow: "none",
              width: "100%",
              marginRight: 0,
              marginLeft: 0,
            }}
          ></FacebookLoginButton>
          <Form
            id="googleRegisterAuthMobileForm"
            className="hidden"
            onSubmit={handleSubmitGoogleRegisterAuthMobile}
          >
            <Form.Group className="mb-2">
              <Form.Control
                id="googleRegisterAuthMobileInput"
                type="text"
                name="googleRegisterAuthMobile"
                placeholder="googleRegisterAuthMobile"
              />
            </Form.Group>
            <Button
              id="googleRegisterAuthMobileSubmit"
              className="me-2 mb-2"
              variant="primary"
              type="submit"
              size="sm"
              disabled={isDuringSubmission}
            >
              Submit
            </Button>
          </Form>
          <Form
            id="facebookAuthMobileForm"
            className="hidden"
            onSubmit={handleSubmitFacebookRegisterAuthMobile}
          >
            <Form.Group className="mb-2">
              <Form.Control
                id="facebookRegisterAuthMobileInputAccessToken"
                type="text"
                name="facebookRegisterAuthMobileAccessToken"
                placeholder="facebookRegisterAuthMobileAccessToken"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Control
                id="facebookRegisterAuthMobileInputAuthorisationToken"
                type="text"
                name="facebookRegisterAuthMobileAuthorisationToken"
                placeholder="facebookRegisterAuthMobileAuthorisationToken"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Control
                id="facebookRegisterAuthMobileInputUserId"
                type="text"
                name="facebookRegisterAuthMobileUserId"
                placeholder="facebookRegisterAuthMobileUserId"
              />
            </Form.Group>
            <Button
              id="facebookRegisterAuthMobileSubmit"
              className="me-2 mb-2"
              variant="primary"
              type="submit"
              size="sm"
              disabled={isDuringSubmission}
            >
              Submit
            </Button>
          </Form>
          <Form>
            <Form.Group className="mb-2 mt-1">
              <Form.Check
                className="me-0 textNotSelectable"
                inline
                name="termsAndConditionsOauth"
                id="termsAndConditionsOauth"
                type="checkbox"
                label="I've read and accepted"
                checked={termsAndConditionsOauthAccepted}
                onChange={(e) =>
                  setTermsAndConditionsOauthAccepted(e.target.checked)
                }
                isInvalid={termsAndConditionsOauthAcceptedShouldShowError}
              />{" "}
              <span
                onClick={openTermsAndConditionsModal}
                style={{ fontSize: "13px" }}
                className="pointerOnHover"
              >
                <a className="link-opacity-100">Terms & Conditions</a>
              </span>
              {termsAndConditionsOauthAcceptedShouldShowError && (
                <div style={{ fontSize: "0.875em", color: "#dc3545" }}>
                  Please read and accept the Terms & Conditions
                </div>
              )}
            </Form.Group>
          </Form>
        </>
      </GeneralContainerRowColCard>

      <HorizontalOr />

      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 7 : 6}
        colXl={openSidebar ? 6 : 5}
      >
        <Form onSubmit={handleSubmit}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                const lowercaseValue = e.target.value.toLowerCase();
                setEmail(lowercaseValue);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                registrationError,
                frontendInputFields.USER_EMAIL
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                registrationError,
                frontendInputFields.USER_EMAIL
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                registrationError,
                frontendInputFields.USER_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                registrationError,
                frontendInputFields.USER_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                registrationError,
                frontendInputFields.USER_CONFIRM_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                registrationError,
                frontendInputFields.USER_CONFIRM_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Check
              className="me-0 textNotSelectable"
              inline
              name="termsAndConditions"
              id="termsAndConditions"
              type="checkbox"
              label="I've read and accepted"
              checked={termsAndConditionsAccepted}
              onChange={(e) => setTermsAndConditionsAccepted(e.target.checked)}
              isInvalid={inputFieldErrorMapperBoolean(
                registrationError,
                frontendInputFields.TERMS_AND_CONDITIONS_ACCEPTED
              )}
            />{" "}
            <span
              onClick={openTermsAndConditionsModal}
              style={{ fontSize: "13px" }}
              className="pointerOnHover"
            >
              <a className="link-opacity-100">Terms & Conditions</a>
            </span>
            <div style={{ fontSize: "0.875em", color: "#dc3545" }}>
              {inputFieldErrorMapperWording(
                registrationError,
                frontendInputFields.TERMS_AND_CONDITIONS_ACCEPTED
              )}
            </div>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringSubmission}
          >
            Register
          </Button>
          <a
            className="link-opacity-100 float-end ms-2 pointerOnHover"
            onClick={goToLoginPage}
          >
            <small>login</small>
          </a>
        </Form>
      </GeneralContainerRowColCard>
    </>
  );
}

export default Register;
