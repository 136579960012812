export const categoriesAlias = {
  HOME: "home",
  USER_DASHBOARD: "user-dashboard",
  WATER_SPORTS: "water-sports",
  FLYING_EXPERIENCES: "flying-experiences",
  ADVENTURE_SPORTS: "adventure-sports",
  DRIVING_EXPERIENCES: "driving-experiences",
  ANIMAL_EXPERIENCES: "animal-experiences",
  OUTDOOR_ACTIVITIES: "outdoor-activities",
  CULTURE_AND_ART: "culture-and-art",
  SNOW_SPORTS: "snow-sports",
  CAMPING: "camping",
};

export const currentCategoriesAndSubCategoriesMain = [
  {
    categoryAlias: categoriesAlias.WATER_SPORTS,
    subCategories: [
      {
        subCategoryAlias: "scuba-diving-and-snorkeling",
        id: 0,
      },
      {
        subCategoryAlias: "jet-skiing",
        id: 1,
      },
      {
        subCategoryAlias: "fishing-and-boat-hire",
        id: 2,
      },
      {
        subCategoryAlias: "jet-boating",
        id: 3,
      },
      {
        subCategoryAlias: "sailing",
        id: 4,
      },
      {
        subCategoryAlias: "kayaking-and-canoeing",
        id: 5,
      },
      {
        subCategoryAlias: "wind-surfing",
        id: 6,
      },
      {
        subCategoryAlias: "paddle-board-and-surfing",
        id: 7,
      },
      {
        subCategoryAlias: "kite-surfing",
        id: 8,
      },
      {
        subCategoryAlias: "white-water-rafting",
        id: 9,
      },
    ],
    id: 2,
  },
  {
    categoryAlias: categoriesAlias.FLYING_EXPERIENCES,
    subCategories: [
      {
        subCategoryAlias: "skydiving",
        id: 0,
      },
      {
        subCategoryAlias: "hot-air-balloon",
        id: 1,
      },
      {
        subCategoryAlias: "helicopter-flights",
        id: 2,
      },
      {
        subCategoryAlias: "flying-lessons",
        id: 3,
      },
      {
        subCategoryAlias: "scenic-flights",
        id: 4,
      },
      {
        subCategoryAlias: "gliding-and-paragliding",
        id: 5,
      },
    ],
    id: 3,
  },
  {
    categoryAlias: categoriesAlias.ADVENTURE_SPORTS,
    subCategories: [
      {
        subCategoryAlias: "abseiling",
        id: 0,
      },
      {
        subCategoryAlias: "climbing",
        id: 1,
      },
      {
        subCategoryAlias: "mountain-biking",
        id: 2,
      },
      {
        subCategoryAlias: "caving",
        id: 3,
      },
      {
        subCategoryAlias: "canyoning",
        id: 4,
      },
      {
        subCategoryAlias: "bungee-jumping",
        id: 5,
      },
      {
        subCategoryAlias: "bridge-climbing",
        id: 6,
      },
    ],
    id: 4,
  },
  {
    categoryAlias: categoriesAlias.DRIVING_EXPERIENCES,
    subCategories: [
      {
        subCategoryAlias: "luxury-car-hire",
        id: 0,
      },
      {
        subCategoryAlias: "classic-car-hire",
        id: 1,
      },
      {
        subCategoryAlias: "karting",
        id: 2,
      },
      {
        subCategoryAlias: "motorbike-ride",
        id: 3,
      },
      {
        subCategoryAlias: "off-road-racing",
        id: 4,
      },
    ],
    id: 5,
  },
  {
    categoryAlias: categoriesAlias.ANIMAL_EXPERIENCES,
    subCategories: [
      {
        subCategoryAlias: "horse-riding",
        id: 0,
      },
      {
        subCategoryAlias: "swimming-with-dolphins",
        id: 1,
      },
      {
        subCategoryAlias: "whale-watching",
        id: 2,
      },
      {
        subCategoryAlias: "shark-diving",
        id: 3,
      },
    ],
    id: 6,
  },
  {
    categoryAlias: categoriesAlias.OUTDOOR_ACTIVITIES,
    subCategories: [
      {
        subCategoryAlias: "road-tours",
        id: 0,
      },
      {
        subCategoryAlias: "single-day-tours",
        id: 1,
      },
      {
        subCategoryAlias: "hiking-and-bushwalking-tours",
        id: 2,
      },
      {
        subCategoryAlias: "aboriginal-culture-tours",
        id: 3,
      },
      {
        subCategoryAlias: "theme-and-adventure-parks",
        id: 4,
      },
    ],
    id: 7,
  },
  {
    categoryAlias: categoriesAlias.CULTURE_AND_ART,
    subCategories: [
      {
        subCategoryAlias: "cinema-and-theatre",
        id: 0,
      },
      {
        subCategoryAlias: "music-experiences",
        id: 1,
      },
    ],
    id: 8,
  },
  {
    categoryAlias: categoriesAlias.SNOW_SPORTS,
    subCategories: [
      {
        subCategoryAlias: "skiing-and-snowboarding",
        id: 0,
      },
    ],
    id: 9,
  },
  {
    categoryAlias: categoriesAlias.CAMPING,
    subCategories: [
      {
        subCategoryAlias: "campsites",
        id: 0,
      },
      {
        subCategoryAlias: "holiday-parks",
        id: 1,
      },
    ],
    id: 10,
  },
];

export const currentCategoriesAndSubCategories = [
  {
    categoryAlias: categoriesAlias.HOME,
    subCategories: [],
    id: 0,
  },
  {
    categoryAlias: categoriesAlias.USER_DASHBOARD,
    subCategories: [
      {
        subCategoryAlias: "login",
        id: 0,
      },
      {
        subCategoryAlias: "register",
        id: 1,
      },
      {
        subCategoryAlias: "dashboard",
        id: 2,
      },
      {
        subCategoryAlias: "logout",
        id: 3,
      },
      {
        subCategoryAlias: "contact-us",
        id: 4,
      },
      {
        subCategoryAlias: "terms-and-conditions",
        id: 4,
      },
    ],
    id: 1,
  },
  ...currentCategoriesAndSubCategoriesMain,
];
