export const advertisementStatuses = {
  permanentlyDelete: -2,
  softDelete: -1,
  pendingInitialSubmit: 0,
  pendingAfterEdit: 1,
  pendingAfterRequestChange: 2,
  pendingAfterUnarchive: 3,
  pendingAdminForce: 4,
  approve: 5,
  archive: 6,
  pendingAfterRequestChangeAddressed: 7,
  hasEditedVersion: 8,
  pendingAfterEditedRequestChangeAddressed: 9,
};
