import { Card } from "react-bootstrap";

function GeneralCard({ children, ...props }: any) {
  const { cardBorder, cardPaddingLeft, cardPaddingRight } = props;
  return (
    <Card
      style={{
        border: cardBorder,
        paddingRight: cardPaddingRight,
        paddingLeft: cardPaddingLeft,
      }}
      className="general-cards"
    >
      {children}
    </Card>
  );
}

export default GeneralCard;
