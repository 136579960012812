const BoatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M131.5-421.5 429-854v432.5H131.5Zm110-57.5h130v-192l-130 192ZM516-421.5q19-63 27.25-121.75T551.5-670q0-64.5-7.75-124T516-916.5q39.5 16 92.25 61.5T709-743.25Q757-677 791.25-594.5t37.75 173H516Zm71.5-57.5h178q-17-88-63.5-170T600.5-785.5q4.5 32 6.5 61.75t2 53.75q0 46-7 99.5T587.5-479ZM360-192.5q-33.5 0-64.5-18.75T240-258.5q-13.5 13.5-32 26.75t-35.5 20.25q-29-17.5-55.25-53.5T83.5-339h793q-7.5 38-33.75 74t-55.25 53.5q-17-7-35.5-20.25t-32-26.75q-24 28.5-55.25 47.25T600-192.5q-33.5 0-64.5-18.75T480-258.5q-24.5 28.5-55.5 47.25T360-192.5ZM81.5-41.5V-99H120q31.5 0 61.25-10.75T240-149q29 28.5 59 39.25T360-99q31.5 0 61.5-10.75T480-149q29 28.5 59 39.25T600-99q31.5 0 61.5-10.75T720-149q28.5 28.5 58.5 39.25T840-99h39v57.5h-39q-29 0-59.25-8.75T720-83q-30.5 24-60.75 32.75T600-41.5q-29 0-59.25-8.75T480-83q-30.5 24-60.75 32.75T360-41.5q-29 0-59.25-8.75T240-83q-30.5 24-60.75 32.75T120-41.5H81.5Zm290-437.5Zm216 0Z" />
    </svg>
  );
};

export default BoatIcon;
