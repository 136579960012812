import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import Image from "react-bootstrap/Image";
import { Context } from "../../context/context";
import AdvertisementCardPlaceholderComponent from "../../sharedComponents/advertisementCardPlaceholder";
import _ from "lodash";
import AdvertisementCategoryIsEmptyComponent from "../../pages/advertisement/component/advertisementCategoryIsEmpty";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";
import Error from "../../pages/error/error";
import DashboardCardComponent from "../../pages/dashboard/component/dashboardCardComponent";
import useAuthHook from "../../auth/authWrapper/hook/useAuthHook";

function Admin() {
  const { logout } = useAuthHook();
  const history = useNavigate();
  const { ifSuperAdmin, ifAuthenticated } = useContext(Context);
  useEffect(() => {
    if (ifSuperAdmin === "false") {
      if (ifAuthenticated === "true") {
        logout();
      } else {
        history("/login");
      }
    }
  }, [ifSuperAdmin]);

  if (ifSuperAdmin === "notSet" || ifSuperAdmin === "false") {
    return null;
  }

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ADMIN_URL, false],
        ]}
      ></BreadCrumb>
      <GeneralCardLayoutWrapper>
        <DashboardCardComponent
          border="danger"
          header="Admin Manage Advertisements"
          text="You can see advertisements by their categories and manage them"
          link={routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}
        />
        <DashboardCardComponent
          border="danger"
          header="Admin Manage All Users"
          text="You can see advertisements by their categories and manage them"
          link={routesEnum.ADMIN_USERS_MANAGE_URL}
        />
      </GeneralCardLayoutWrapper>
    </>
  );
}

export default Admin;
