import { useContext, useState } from "react";
import { Card } from "react-bootstrap";
import { Context } from "../../../context/context";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import axios from "axios";
import LoginToBookmarkModal from "./modals";
import AdvertisementCardImageComponent from "../../../sharedComponents/advertisementCardImage";
import { findCategorySubCategoryAliasById } from "../../../util/findCategorySubCategoryAliasById";

function AdvertisementCardComponent(props: any) {
  const { src, title, description, id, redirectUrl } = props;
  const [modalShow, setModalShow] = useState(false);
  const history = useNavigate();
  const {
    allAdvertisements,
    bookmarkedAdvertisements,
    setBookmarkedAdvertisements,
    ifAuthenticated,
    personalAdvertisements,
    adminAllAdvertisements,
  } = useContext(Context);

  let categoryAndSubCategoryAlias: any;
  if (allAdvertisements && allAdvertisements !== "notSet") {
    let currentAdvertisement = _.find(allAdvertisements, {
      id,
    });

    if (_.isUndefined(currentAdvertisement)) {
      currentAdvertisement = _.find(personalAdvertisements, {
        id,
      });
    }

    if (_.isUndefined(currentAdvertisement)) {
      currentAdvertisement = _.find(adminAllAdvertisements, {
        id,
      });
    }

    categoryAndSubCategoryAlias = findCategorySubCategoryAliasById(
      currentAdvertisement.advertisementCategory,
      currentAdvertisement.advertisementSubCategory
    );
  }

  const goToAdvertisementPage = () => {
    history(
      redirectUrl ||
        `/advertisement/${categoryAndSubCategoryAlias.categoryAlias}/${categoryAndSubCategoryAlias.subCategoryAlias}/${id}`
    );
  };

  const bookmarkLogic = async () => {
    if (ifAuthenticated === "true") {
      const newBookmarkedAdvertisements = await axios.post(
        "advertisements/bookmark-advertisement",
        {
          advertisementId: id,
        }
      );
      setBookmarkedAdvertisements(newBookmarkedAdvertisements.data);
    } else {
      setModalShow(true);
    }
  };

  return (
    <>
      <LoginToBookmarkModal modalShow={modalShow} setModalShow={setModalShow} />
      <Card className="advertisementCard shadow pointerOnHover img-fluid">
        {bookmarkedAdvertisements &&
        bookmarkedAdvertisements !== "notSet" &&
        _.some(bookmarkedAdvertisements, (obj) => _.isEqual(obj, { id })) &&
        ifAuthenticated === "true" ? (
          <>
            <AiFillHeart
              onClick={bookmarkLogic}
              style={{
                fontSize: "40px",
                color: "#eb483d",
                position: "absolute",
                right: 2,
                top: 2,
                zIndex: 3,
              }}
            />
            <AiOutlineHeart
              onClick={bookmarkLogic}
              style={{
                fontSize: "40px",
                color: "#eb1102",
                position: "absolute",
                right: 2,
                top: 2,
                zIndex: 3,
              }}
            />
          </>
        ) : (
          <>
            <AiFillHeart
              onClick={bookmarkLogic}
              style={{
                fontSize: "40px",
                color: "black",
                opacity: "0.5",
                position: "absolute",
                right: 2,
                top: 2,
                zIndex: 3,
              }}
            />
            <AiOutlineHeart
              onClick={bookmarkLogic}
              style={{
                fontSize: "40px",
                color: "grey",
                position: "absolute",
                right: 2,
                top: 2,
                zIndex: 3,
              }}
            />
          </>
        )}
        <span onClick={goToAdvertisementPage}>
          <AdvertisementCardImageComponent src={src} />
          <Card.Body>
            <Card.Title className="text-truncate">
              {title.replaceAll("*****", "\n")}
            </Card.Title>
            <Card.Text className="text-truncate-container">
              {description.replaceAll("*****", "\n")}
            </Card.Text>
          </Card.Body>
        </span>
      </Card>
    </>
  );
}

export default AdvertisementCardComponent;
