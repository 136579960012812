import { useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Context } from "../context/context";
import { loadGoogleMapScript } from "../util/loadGoogleMapScript";
import _ from "lodash";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../util/inputFieldErrorMapper";
import { frontendInputFields } from "../util/frontendInputFields";

// @ts-ignore
window.initMap = function () {};

export function AddressAutoComplete({ ...props }) {
  const {
    businessAddress,
    setBusinessAddress,
    setBusinessState,
    setBusinessRegion,
    advertisementFormError,
  } = props;
  const [inputString, setInputString] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    if (
      businessAddress &&
      typeof businessAddress?.geometry?.location?.lat !== "function"
    ) {
      setInputString(businessAddress.formatted_address);
    }
  }, [businessAddress]);

  const { googleMapScriptLoaded } = useContext(Context);

  const onChangeAddress = (autocomplete: any) => {
    const place = autocomplete.getPlace();
    setBusinessAddress(place);
    const stateComponent = _.find(place.address_components, {
      types: ["administrative_area_level_1", "political"],
    });
    const stateShortNameState = stateComponent
      ? stateComponent.short_name
      : null;
    setBusinessRegion("-1");
    setBusinessState(stateShortNameState.toLowerCase());
    setInputString(place.formatted_address);
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;
    // @ts-ignore
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current,
      { types: ["address"], componentRestrictions: { country: "au" } }
    );
    autocomplete.setFields([
      "address_component",
      "formatted_address",
      "geometry",
      "place_id",
    ]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  const handleOnChange = (event: any) => {
    setInputString(event.target.value);
    if (businessAddress) {
      setBusinessAddress(null);
      setBusinessState("-1");
      setBusinessRegion("-1");
      setInputString("");
    }
  };

  useEffect(() => {
    if (googleMapScriptLoaded === "true") {
      initAutocomplete();
    }
  }, [googleMapScriptLoaded]);

  return (
    <>
      <Form.Label>Main Business Address</Form.Label>
      <Form.Control
        size="sm"
        type="text"
        placeholder="select address from dropdown"
        ref={searchInput}
        onChange={handleOnChange}
        value={inputString}
        isInvalid={inputFieldErrorMapperBoolean(
          advertisementFormError,
          frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_ADDRESS
        )}
      />
      <Form.Control.Feedback type="invalid">
        {inputFieldErrorMapperWording(
          advertisementFormError,
          frontendInputFields.ADVERTISEMENT_MAIN_BUSINESS_ADDRESS
        )}
      </Form.Control.Feedback>
    </>
  );
}
