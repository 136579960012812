import { useState } from "react";
import { useNavigate } from "react-router";
import { TermsAndConditionsModal } from "./modals";
import { TermsAndConditionsContent } from "./termsAndConditions";

const LoginToSeeAdvertisementDetails = () => {
  const history = useNavigate();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);
  const goToLoginPage = () => {
    history("/login");
  };
  const goToRegisterPage = () => {
    history("/register");
  };
  return (
    <>
      <TermsAndConditionsModal
        modalShow={showTermsAndConditionsModal}
        setModalShow={setShowTermsAndConditionsModal}
        message={TermsAndConditionsContent}
        title="Terms and Conditions"
      />
      <span>
        Please{" "}
        <span className="text-primary pointerOnHover" onClick={goToLoginPage}>
          Login
        </span>{" "}
        to view details
      </span>
    </>
  );
};

export default LoginToSeeAdvertisementDetails;
