const TentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M81.5-81.5V-263l363-491-66.5-90 47-33.5 55 75.5 56.5-75.5 46 33.5-66 90L879-263v181.5H81.5ZM480-705 139-243.5V-139h147l194-272 194.5 272h147v-104.5L480-705ZM357-139h246.5L480-312.5 357-139Zm123-272 194.5 272L480-411 286-139l194-272Z" />
    </svg>
  );
};

export default TentIcon;
