import Home from "./pages/home/home";
import Register from "./auth/Register/Register";
import Login from "./auth/Login/Login";
import About from "./pages/about/about";
import Error from "./pages/error/error";
import Dashboard from "./pages/dashboard/dashboard";
import ContactUs from "./pages/contactUs/contactUs";
import AdvertisementCreate from "./pages/advertisementCreate/advertisementCreate";
import AdvertisementPage from "./pages/advertisement/advertisementPage";
import AdvertisementCreatedShow from "./pages/advertisementCreatedShow/advertisementCreatedShow";
import AdvertisementCategoryPage from "./pages/advertisementCategory/advertisementCategoryPage";
import AdvertisementShowBookmark from "./pages/advertisementShowBookmark/advertisementShowBookmark";
import ForgotPasswordOutbound from "./auth/password/forgotPasswordOutbound";
import AccountSetting from "./pages/accountSetting/accountSetting";
import UpdatePassword from "./auth/password/updatePassword";
import AdvertisementManage from "./pages/advertisementManage/advertisementManage";
import { routesEnum } from "./layouts/breadCrumbs/routesEnum";
import Admin from "./admin/adminDashboard/admin";
import AdminUsersManage from "./admin/adminUsersManage/adminUsersManage";
import AdminAdvertisementsManage from "./admin/adminAdvertisementsManage/adminAdvertisementsManage";
import AdvertisementEdit from "./pages/advertisementEdit/advertisementEdit";
import AdvertisementEditedVersionShow from "./admin/advertisementEditedVersionShow/advertisementEditedVersionShow";
import AdvertisementAdminManageShow from "./admin/advertisementAdminManageShow/advertisementAdminManageShow";
import AdvertisementManageShow from "./pages/advertisementManageShow/advertisementManageShow";
import AdminAdvertisementEdit from "./admin/adminAdvertisementEdit/adminAdvertisementEdit";
import TermsAndConditions from "./sharedComponents/termsAndConditions";
import AccountAndDataDeletion from "./pages/accountAndDataDeletion/accountAndDataDeletion";
import ForgotPasswordInbound from "./auth/password/forgotPasswordInbound";
import SetNewPasswordOutbound from "./auth/password/setNewPasswordOutbound";
import SetNewPasswordInbound from "./auth/password/setNewPasswordInbound";

export const routes = [
  { path: `/`, component: Home },
  { path: `/${routesEnum.REGISTER_URL}`, component: Register },
  { path: `/${routesEnum.LOGIN_URL}`, component: Login },
  {
    path: `/${routesEnum.FORGOT_PASSWORD_URL}`,
    component: ForgotPasswordOutbound,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}/${routesEnum.FORGOT_PASSWORD_URL}`,
    component: ForgotPasswordOutbound,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}/${routesEnum.SET_NEW_PASSWORD_URL}`,
    component: SetNewPasswordOutbound,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}/${routesEnum.UPDATE_PASSWORD_URL}`,
    component: UpdatePassword,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}/${routesEnum.ACCOUNT_AND_DATA_DELETION_URL}`,
    component: AccountAndDataDeletion,
  },
  { path: `/${routesEnum.ABOUT_US_URL}`, component: About },
  { path: `/${routesEnum.DASHBOARD_URL}`, component: Dashboard },
  { path: `/${routesEnum.CONTACT_US_URL}`, component: ContactUs },
  {
    path: `/${routesEnum.FORGOT_PASSWORD_LISTENER_URL}/:email/:hash`,
    component: ForgotPasswordInbound,
  },
  {
    path: `/${routesEnum.SET_NEW_PASSWORD_LISTENER_URL}/:email/:hash`,
    component: SetNewPasswordInbound,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.CONTACT_US_URL}`,
    component: ContactUs,
  },
  {
    path: `/${routesEnum.TERMS_AND_CONDITIONS_URL}`,
    component: TermsAndConditions,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.TERMS_AND_CONDITIONS_URL}`,
    component: TermsAndConditions,
  },
  {
    path: `/${routesEnum.ADVERTISEMENT_URL}/:advertisementCategory`,
    component: AdvertisementCategoryPage,
  },
  {
    path: `/${routesEnum.ADVERTISEMENT_URL}/:advertisementCategory/:advertisementSubCategory`,
    component: AdvertisementPage,
  },
  {
    path: `/${routesEnum.ADVERTISEMENT_URL}/:advertisementCategory/:advertisementSubCategory/:advertisementId`,
    component: AdvertisementCreatedShow,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}/${routesEnum.ADVERTISEMENT_EDITED_VERSION_URL}/:editedAdvertisementId/:advertisementId`,
    component: AdvertisementEditedVersionShow,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.BOOKMARKED_ADVERTISEMENTS_URL}`,
    component: AdvertisementShowBookmark,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ACCOUNT_SETTING_URL}`,
    component: AccountSetting,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADVERTISEMENT_CREATE_URL}`,
    component: AdvertisementCreate,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADVERTISEMENT_MANAGE_URL}/${routesEnum.ADVERTISEMENT_EDIT_URL}/:advertisementId`,
    component: AdvertisementEdit,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}/${routesEnum.ADVERTISEMENT_EDIT_URL}/:advertisementId`,
    component: AdminAdvertisementEdit,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADVERTISEMENT_MANAGE_URL}`,
    component: AdvertisementManage,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADVERTISEMENT_MANAGE_URL}/:advertisementId`,
    component: AdvertisementManageShow,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}`,
    component: Admin,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}`,
    component: AdminAdvertisementsManage,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}/:advertisementId`,
    component: AdvertisementAdminManageShow,
  },
  {
    path: `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_USERS_MANAGE_URL}`,
    component: AdminUsersManage,
  },
  { path: "/*", component: Error },
];
