import React, { useState, useRef } from "react";
import { useEffect, DependencyList } from "react";
import { Form } from "react-bootstrap";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AdvertisementCardCreateAdPlaceholderComponent from "../pages/advertisement/component/advertisementCardCreateAdPlaceholderComponent";
import { imageCompressor } from "../util/imageCompressor";
import randomstring from "randomstring";
import _ from "lodash";
import { Buffer } from "buffer";
import { blobToBase64 } from "../util/blobToBase64";
import { imageCropper } from "../util/imageCropper";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../util/inputFieldErrorMapper";
import { frontendInputFields } from "../util/frontendInputFields";

const TO_RADIANS = Math.PI / 180;
export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(scale, scale);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps?: DependencyList
) {
  useEffect(() => {
    const t = setTimeout(() => {
      // @ts-ignore
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function ImageCrop({ ...prop }) {
  const {
    previewCanvasRef,
    setImagesThumbnail,
    imagesThumbnail,
    imagesArray,
    setImagesArray,
    advertisementFormError,
  } = prop;
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(800 / 600);
  const [imageLoadedForEdit, setImageLoadedForEdit] = useState(false);

  useEffect(() => {
    if (imagesArray && imagesArray.length && !imageLoadedForEdit) {
      setImgSrc(imagesArray[0].data);
      setImageLoadedForEdit(true);
    }
  }, [imagesArray]);

  async function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      if (!e.target.files[0].type.match("image.*")) {
        return;
      }
      // if (
      //   process.env.REACT_APP_MAX_IMAGE_SIZE_CREATE_ADVERTISEMENT_BYTES &&
      //   e.target.files[0].size >
      //     parseInt(
      //       process.env.REACT_APP_MAX_IMAGE_SIZE_CREATE_ADVERTISEMENT_BYTES
      //     )
      // ) {
      //   console.log(
      //     `Image size should be less than ${
      //       parseInt(
      //         process.env.REACT_APP_MAX_IMAGE_SIZE_CREATE_ADVERTISEMENT_BYTES
      //       ) / 1000000
      //     } MB`
      //   );
      //   return;
      // }
      const file = await imageCompressor(e.target.files[0]);
      setCrop(undefined);
      setImgSrc(file.resizedBase64strReadyToBeUsedAsSrc);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        const compressedImage = await imageCompressor(
          previewCanvasRef.current.toDataURL("image/jpeg")
        );

        const croppedAndCompressed = await imageCropper(
          compressedImage.resizedBase64strReadyToBeUsedAsSrc
        );

        const imageCoverThumbnail = [
          {
            id: `img_cover`,
            src: croppedAndCompressed,
            data: croppedAndCompressed,
          },
        ];
        const imageCoverImagesArray = [
          {
            id: `img_cover`,
            src: croppedAndCompressed,
            data: croppedAndCompressed,
          },
        ];
        const filteredArrayThumbnail = _.filter(
          imagesThumbnail,
          (item) => item.id !== `img_cover`
        );
        const filteredArrayImagesArray = _.filter(
          imagesArray,
          (item) => item.id !== `img_cover`
        );
        setImagesThumbnail([...imageCoverThumbnail, ...filteredArrayThumbnail]);
        setImagesArray([...imageCoverImagesArray, ...filteredArrayImagesArray]);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      <Form.Label>Upload Cover Image:</Form.Label>
      <Form.Control
        size="sm"
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        isInvalid={inputFieldErrorMapperBoolean(
          advertisementFormError,
          frontendInputFields.ADVERTISEMENT_COVER_IMAGE
        )}
      />
      <Form.Control.Feedback type="invalid">
        {inputFieldErrorMapperWording(
          advertisementFormError,
          frontendInputFields.ADVERTISEMENT_COVER_IMAGE
        )}
      </Form.Control.Feedback>

      {/* <Form.Group className="mb-3">
        <div className="input-group mb-3">
          <span className="input-group-text">Scale:</span>
          <Form.Control
            id="scale-input"
            type="number"
            step="0.1"
            min="1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
          <span className="input-group-text">Rotate:</span>
          <Form.Control
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div>
      </Form.Group> */}
      <div
        className="row mt-2"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        {!!imgSrc && (
          <>
            <small className="text-center">
              You can drag and scale have the best shot!
            </small>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              style={{
                maxWidth: "300px",
                height: "fit-content",
                // maxHeight: "381px",
                marginBottom: "10px",
                padding: 0,
              }}
              keepSelection={true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  maxHeight: "fit-content",
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </>
        )}

        {!!completedCrop && (
          <span style={{ display: "none" }}>
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: "contain",
              }}
            />
          </span>
        )}
      </div>
    </>
  );
}

export default ImageCrop;
