import { Button, Card } from "react-bootstrap";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  ChangeStatusModal,
  RedirectModal,
} from "../../sharedComponents/modals";
import { Context } from "../../context/context";
import { useLocation, useNavigate, useParams } from "react-router";
import VisibilityLockIcon from "../../icons/visibilityLockIcon/visibilityLockIcon";
import { advertisementStatuses } from "../../util/advertisementStatuses";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import parse from "html-react-parser";

function AdminEditedAdvertisementManageBox() {
  let { editedAdvertisementId, advertisementId } = useParams();
  const recaptchaRef = useRef<any>();
  const [editedAdvertisement, setEditedAdvertisement] = useState<any>(null);
  const [
    editedAdvertisementStatusToBeSet,
    setEditedAdvertisementStatusToBeSet,
  ] = useState(advertisementStatuses.pendingInitialSubmit);
  const [shouldModalIncludeTextBox, setShouldModalIncludeTextBox] =
    useState(false);
  const [modalTextBoxContent, setModalTextBoxContent] = useState("");
  const [
    editedAdvertisementStatusModalMessage,
    setEditedAdvertisementStatusModalMessage,
  ] = useState("");
  const [
    editedAdvertisementStatusModalMainButtonWording,
    setEditedAdvertisementStatusModalMainButtonWording,
  ] = useState("");
  const [redirectModalButtonWording, setRedirectModalButtonWording] =
    useState("");
  const [
    editedAdvertisementChangeStatusModalShow,
    setEditedAdvertisementChangeStatusModalShow,
  ] = useState(false);
  const [redirectModalMessage, setRedirectModalMessage] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [checkEditedVersionModalShow, setCheckEditedVersionModalShow] =
    useState(false);
  const [changeStatusInProgress, setChangeStatusInProgress] = useState(false);
  const [
    changeStatusEditedAdvertisementError,
    setChangeStatusEditedAdvertisementError,
  ] = useState([]);
  const {
    setShouldFetchAuthInitialStates,
    setShouldFetchPublicInitialStates,
    adminAllEditedAdvertisements,
    ifAuthenticated,
    ifSuperAdmin,
  } = useContext(Context);
  const history = useNavigate();

  const changeStatusEditedAdvertisement = async (
    editedAdvertisementStatus: number
  ) => {
    setChangeStatusInProgress(true);
    setChangeStatusEditedAdvertisementError([]);
    try {
      let token = "";
      if (shouldModalIncludeTextBox) {
        token = await recaptchaRef.current.executeAsync();
      }
      const result = await axios.post(
        "admin/admin-change-edited-advertisement-status",
        {
          editedAdvertisementId: editedAdvertisement.id,
          editedAdvertisementStatus,
          adminAdvertisementRequestChangeReason: modalTextBoxContent,
          originalAdvertisementId: advertisementId,
          recaptchaToken: token,
          shouldModalIncludeTextBox,
        }
      );
      setModalTextBoxContent("");
      setEditedAdvertisementChangeStatusModalShow(false);
      if (
        result.data.advertisementApproved === "success" ||
        result.data.editedAdvertisementHardDeleted === "success"
      ) {
        history(
          `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}/${advertisementId}`
        );
      }

      setChangeStatusInProgress(false);
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    } catch (error: any) {
      setChangeStatusInProgress(false);
      setChangeStatusEditedAdvertisementError(error?.response?.data?.message);
    }
  };

  const removeErrorsAndTextAndCloseModal = () => {
    setChangeStatusEditedAdvertisementError([]);
    setModalTextBoxContent("");
    setModalTextBoxContent("");
  };

  useEffect(() => {
    if (editedAdvertisementId && adminAllEditedAdvertisements !== "notSet") {
      const editedAdvertisementVersion = _.find(adminAllEditedAdvertisements, {
        id: editedAdvertisementId,
      });
      if (editedAdvertisementVersion) {
        setEditedAdvertisement(editedAdvertisementVersion);
      }
    }
  }, [adminAllEditedAdvertisements]);

  const location = useLocation();

  return (
    <>
      {ifAuthenticated === "true" &&
        ifSuperAdmin &&
        location.pathname ===
          `/dashboard/admin/admin-advertisements-manage/advertisement-edited-version/${editedAdvertisementId}/${advertisementId}` && (
          <>
            <ChangeStatusModal
              modalShow={editedAdvertisementChangeStatusModalShow}
              setModalShow={setEditedAdvertisementChangeStatusModalShow}
              removeErrorsAndTextAndCloseModal={
                removeErrorsAndTextAndCloseModal
              }
              changeStatusInProgress={changeStatusInProgress}
              onChangeStatus={() =>
                changeStatusEditedAdvertisement(
                  editedAdvertisementStatusToBeSet
                )
              }
              message={editedAdvertisementStatusModalMessage}
              changeStatusAdvertisementError={
                changeStatusEditedAdvertisementError
              }
              changeStatusButtonWording={
                editedAdvertisementStatusModalMainButtonWording
              }
              shouldModalIncludeTextBox={shouldModalIncludeTextBox}
              setModalTextBoxContent={setModalTextBoxContent}
              modalTextBoxContent={modalTextBoxContent}
              recaptchaRef={recaptchaRef}
            />
            <RedirectModal
              modalShow={checkEditedVersionModalShow}
              setModalShow={setCheckEditedVersionModalShow}
              button={redirectModalButtonWording}
              message={redirectModalMessage}
              url={redirectUrl}
            />
            <Card
              border="danger"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              <Card.Body>
                <div className="text-center mb-3">
                  <VisibilityLockIcon
                    style={{
                      position: "relative",
                      bottom: "1px",
                      right: "2px",
                    }}
                  />
                  Admin View, this Section is only visible to you
                </div>
                <Button
                  variant="light"
                  className="border me-2 mb-2"
                  onClick={() => {
                    setEditedAdvertisementStatusToBeSet(
                      advertisementStatuses.approve
                    );
                    setEditedAdvertisementStatusModalMessage(
                      "Are you sure? this will approve the edited version of the advertisement and it will replace the current version!"
                    );
                    setEditedAdvertisementStatusModalMainButtonWording(
                      "Approve"
                    );
                    setShouldModalIncludeTextBox(false);
                    setEditedAdvertisementChangeStatusModalShow(true);
                  }}
                  size="sm"
                >
                  Approve Edited Version
                </Button>
                <Button
                  variant="light"
                  className="border me-2 mb-2"
                  onClick={() => {
                    setEditedAdvertisementStatusToBeSet(
                      advertisementStatuses.pendingAfterRequestChange
                    );
                    setEditedAdvertisementStatusModalMessage(
                      "Are you sure? this will send an email to user and tell them what they need to change inside the edited version of the advertisement and resubmit it for revision"
                    );
                    setShouldModalIncludeTextBox(true);
                    setEditedAdvertisementStatusModalMainButtonWording(
                      "Request Change"
                    );
                    setEditedAdvertisementChangeStatusModalShow(true);
                  }}
                  size="sm"
                >
                  Request Change of Edited Version
                </Button>
                <Button
                  variant="light"
                  className="border me-2 mb-2"
                  onClick={() => {
                    setEditedAdvertisementStatusToBeSet(
                      advertisementStatuses.permanentlyDelete
                    );
                    setEditedAdvertisementStatusModalMessage(
                      "Are you sure? this will permanently delete the edited version of the advertisement even from the database records!"
                    );
                    setEditedAdvertisementStatusModalMainButtonWording(
                      "Permanently Delete"
                    );
                    setShouldModalIncludeTextBox(false);
                    setEditedAdvertisementChangeStatusModalShow(true);
                  }}
                  size="sm"
                >
                  Permanently Delete Edited Version
                </Button>
                <Button
                  variant="success"
                  className="border me-2 mb-2"
                  onClick={() => {
                    setCheckEditedVersionModalShow(true);
                    setRedirectModalMessage(
                      "Are you sure? you are about to see the current version of the advertisement"
                    );
                    setRedirectModalButtonWording("Check Current Version");
                    setRedirectUrl(
                      `/${routesEnum.DASHBOARD_URL}/${routesEnum.ADMIN_URL}/${routesEnum.ADMIN_ADVERTISEMENTS_MANAGE_URL}/${advertisementId}`
                    );
                  }}
                  size="sm"
                >
                  Check Current Version
                </Button>
              </Card.Body>
            </Card>
          </>
        )}
    </>
  );
}

export default AdminEditedAdvertisementManageBox;
