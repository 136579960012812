import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import { Link, useNavigate } from "react-router-dom";
import DashboardCardComponent from "./component/dashboardCardComponent";
import { useContext, useEffect } from "react";
import { Context } from "../../context/context";
import GeneralCardLayoutWrapper from "../../sharedComponents/generalCardLayoutWrapper";
import LoginOrRegister from "../../sharedComponents/loginOrRegister";

function Dashboard() {
  const { ifAuthenticated, ifSuperAdmin, currentUser } = useContext(Context);
  const history = useNavigate();
  function truncateString(str: any, maxLength: any) {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  }

  const goToLoginPage = () => {
    history("/login");
  };

  const goToRegisterPage = () => {
    history("/register");
  };

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, false],
        ]}
      ></BreadCrumb>
      {ifAuthenticated === "true" ? (
        <GeneralCardLayoutWrapper>
          {ifSuperAdmin === "true" && (
            <DashboardCardComponent
              border="danger"
              header="Admin Section"
              text="Here you can enter to admin section"
              link={routesEnum.ADMIN_URL}
            />
          )}

          <DashboardCardComponent
            header="Account Setting"
            text={`Hi ${truncateString(
              currentUser.username,
              15
            )} you can manage your account here`}
            link={routesEnum.ACCOUNT_SETTING_URL}
          />

          <DashboardCardComponent
            header="Bookmarked Joys"
            text="here you can find the Joys that you've already bookmarked"
            link={routesEnum.BOOKMARKED_ADVERTISEMENTS_URL}
          />
          <DashboardCardComponent
            header="Create Advertisement"
            text="If you are a joy provider, you can create your advertisement here"
            link={routesEnum.ADVERTISEMENT_CREATE_URL}
          />
          <DashboardCardComponent
            header="Manage Advertisement"
            text="You can manage your advertisements here"
            link={routesEnum.ADVERTISEMENT_MANAGE_URL}
          />
          <DashboardCardComponent
            header="Contact Us"
            text="You can send us a message any time and we'll get back to you shortly"
            link={routesEnum.CONTACT_US_URL}
          />
          <DashboardCardComponent
            header="Terms & Conditions"
            text="You can see the latest terms and conditions of WhereToJoy here"
            link={routesEnum.TERMS_AND_CONDITIONS_URL}
          />
        </GeneralCardLayoutWrapper>
      ) : (
        <LoginOrRegister />
      )}
    </>
  );
}

export default Dashboard;
