import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import _ from "lodash";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";

const ImageViewer = (props: any) => {
  const {
    imagesArray,
    showModal,
    closeModal,
    currentIndex,
    setCurrentIndex,
    imageLoaded,
    setImageLoaded,
  } = props;

  const imagesArrayMapToSrc = _.map(imagesArray, "src");

  const showPrevious = () => {
    setImageLoaded(false);
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const showNext = () => {
    setImageLoaded(false);
    if (currentIndex < imagesArrayMapToSrc.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleDivOneClick = (event: any) => {
    if (!event.target.closest(".modalImageArea")) {
      closeModal();
    }
  };

  return (
    <>
      <Modal
        id="imageViewerModal"
        fullscreen={true}
        show={showModal}
        onHide={() => closeModal()}
        centered
        onClick={handleDivOneClick}
      >
        <Modal.Body
          className="d-flex flex-column align-items-center justify-content-center"
          style={{ background: "rgba(0, 0, 0, 0.65)", padding: 0 }}
        >
          <div
            className="textNotSelectable"
            style={{
              position: "absolute",
              margin: "auto",
              color: "white",
              top: 12,
            }}
          >
            {`${currentIndex + 1}/${imagesArrayMapToSrc.length}`}
          </div>
          <MdOutlineClose
            className="pointerOnHover textNotSelectable"
            style={{
              color: "white",
              position: "absolute",
              margin: "auto",
              right: 5,
              top: 5,
              fontSize: 40,
            }}
            onClick={() => closeModal()}
          />
          {imagesArrayMapToSrc.length > 0 && (
            <>
              {!imageLoaded && (
                <>
                  <span style={{ position: "relative" }}>
                    <>
                      <Spinner
                        style={{
                          color: "#f7f7f7",
                          margin: "auto",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          zIndex: 2,
                        }}
                        animation="border"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </>
                  </span>
                </>
              )}
              <img
                src={imagesArrayMapToSrc[currentIndex]}
                alt={`Image ${currentIndex + 1}`}
                className="img-fluid textNotSelectable modalImageArea"
                onLoad={() => {
                  setTimeout(() => {
                    setImageLoaded(true);
                  }, 300);
                }}
                style={
                  imageLoaded ? { maxHeight: "80vh" } : { display: "none" }
                }
              />
              {currentIndex !== 0 && (
                <div
                  className="modalImageArea"
                  style={{
                    position: "absolute",
                    margin: "auto",
                    left: 5,
                  }}
                >
                  <FaChevronCircleLeft
                    style={{
                      fontSize: "55px",
                      color: "rgb(255 255 255 / 50%)",
                    }}
                    onClick={showPrevious}
                    className="pointerOnHover"
                  />
                </div>
              )}

              {currentIndex + 1 !== imagesArrayMapToSrc.length && (
                <div
                  className="modalImageArea"
                  style={{
                    position: "absolute",
                    margin: "auto",
                    right: 5,
                  }}
                >
                  <FaChevronCircleRight
                    style={{
                      fontSize: "55px",
                      color: "rgb(255 255 255 / 50%)",
                    }}
                    onClick={showNext}
                    className="pointerOnHover"
                  />
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImageViewer;
