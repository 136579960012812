const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M222-182h148v-249.5h220V-182h148v-387.5L480-763 222-569.667V-182Zm-57.5 57.5v-474L480-835l315.5 236.833V-124.5h-263V-374h-105v249.5h-263ZM480-473Z" />
    </svg>
  );
};

export default HomeIcon;
