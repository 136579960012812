export const routesEnum = {
  HOME_URL: "/",
  DASHBOARD_URL: "dashboard",
  CONTACT_US_URL: "contact-us",
  ABOUT_US_URL: "about",
  REGISTER_URL: "register",
  LOGIN_URL: "login",
  FORGOT_PASSWORD_URL: "forgot-password",
  SET_NEW_PASSWORD_URL: "set-new-password",
  FORGOT_PASSWORD_LISTENER_URL: "forgot-password-listener",
  SET_NEW_PASSWORD_LISTENER_URL: "set-new-password-listener",
  ADVERTISEMENT_URL: "advertisement",
  ADVERTISEMENT_EDITED_VERSION_URL: "advertisement-edited-version",
  ADVERTISEMENT_CREATE_URL: "advertisement-create",
  ADVERTISEMENT_EDIT_URL: "advertisement-edit",
  ADVERTISEMENT_MANAGE_URL: "advertisement-manage",
  ADMIN_URL: "admin",
  ADMIN_ADVERTISEMENTS_MANAGE_URL: "admin-advertisements-manage",
  ADMIN_USERS_MANAGE_URL: "admin-users-manage",
  BOOKMARKED_ADVERTISEMENTS_URL: "bookmarked-advertisements",
  ACCOUNT_SETTING_URL: "account-setting",
  UPDATE_PASSWORD_URL: "update-password",
  TERMS_AND_CONDITIONS_URL: "terms-and-conditions",
  ACCOUNT_AND_DATA_DELETION_URL: "account-and-data-deletion",
};
