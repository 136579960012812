import { useContext, useEffect, useRef, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { Context } from "../context/context";
import _ from "lodash";
import { currentCategoriesAndSubCategories } from "../util/currentCategoriesAndSubcategories";
import { createTitleFromAlias } from "../util/createTitleFromAlias";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../util/inputFieldErrorMapper";
import { frontendInputFields } from "../util/frontendInputFields";

export function CategoryAndSubCategorySelect({ ...props }) {
  const {
    mainCategory,
    subCategory,
    setMainCategory,
    setSubCategory,
    advertisementFormError,
  } = props;

  const categories = (
    <>
      <option value="-1">Open to Select</option>
      {/* @ts-ignore */}
      {currentCategoriesAndSubCategories.map((item: any, index: any) => {
        if (index !== 0 && index !== 1) {
          return (
            <option key={index} value={item.id}>
              {createTitleFromAlias(item.categoryAlias)}
            </option>
          );
        }
      })}
    </>
  );

  const subCategories =
    mainCategory && mainCategory !== "-1" ? (
      <>
        <option value="-1">Open to Select</option>
        {/* @ts-ignore */}
        {currentCategoriesAndSubCategories[
          mainCategory as any
        ].subCategories.map((item: any, index: any) => {
          return (
            <option key={index} value={item.id}>
              {createTitleFromAlias(item.subCategoryAlias)}
            </option>
          );
        })}
      </>
    ) : (
      <option value="-1">select main category first ...</option>
    );

  return (
    <>
      <FormGroup className="mb-3">
        <Form.Label>Select Main Category</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Main Category"
          onChange={(e) => {
            setMainCategory(e.target.value);
            setSubCategory("-1");
          }}
          value={mainCategory}
          isInvalid={inputFieldErrorMapperBoolean(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_MAIN_CATEGORY
          )}
        >
          {categories}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {inputFieldErrorMapperWording(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_MAIN_CATEGORY
          )}
        </Form.Control.Feedback>
      </FormGroup>

      <FormGroup className="mb-3">
        <Form.Label>Select Subcategory</Form.Label>
        <Form.Select
          size="sm"
          aria-label="Sub Category"
          onChange={(e) => setSubCategory(e.target.value)}
          value={subCategory}
          disabled={!mainCategory || mainCategory === "-1"}
          isInvalid={inputFieldErrorMapperBoolean(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_SUB_CATEGORY
          )}
        >
          {subCategories}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {inputFieldErrorMapperWording(
            advertisementFormError,
            frontendInputFields.ADVERTISEMENT_SUB_CATEGORY
          )}
        </Form.Control.Feedback>
      </FormGroup>
    </>
  );
}
