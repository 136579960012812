import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { isWebView } from "../../../util/isWebView";

export const RegistrationCompletedModal = (props: any) => {
  const { modalShow, setModalShow } = props;
  const closeModal = () => {
    setModalShow(false);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerClosed");
    }
  };
  const showModal = () => {
    setModalShow(true);
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("imageViewerOpened");
    }
  };
  const history = useNavigate();
  const goToLoginPage = () => {
    closeModal();
    history("/login");
  };
  return (
    <Modal
      show={modalShow}
      size="sm"
      centered
      onHide={closeModal}
      onShow={showModal}
    >
      <Modal.Body>
        Registration Completed, go to{" "}
        <span className="text-primary pointerOnHover" onClick={goToLoginPage}>
          Login
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} size="sm">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
