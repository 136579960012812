import ReCAPTCHA from "react-google-recaptcha";

function Recaptcha(props: any) {
  const { recaptchaRef } = props;

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={`${process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY}`}
      />
    </>
  );
}

export default Recaptcha;
