const DogIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="30"
      viewBox="0 -960 960 960"
      width="30"
      fill="white"
    >
      <path d="M195.833-81.833v-305.668l190.334 191q43.666-47.5 77.999-102.25t34.333-119.583q0-50.833-19.916-96.999-19.917-46.167-55.75-82.334l-123-123.5H484l156.667-156.666 76 76.5Q755-763 775.167-713.5 795.333-664 796.5-609.334q-1.167 54.667-21.333 104.167-20.167 49.5-58.5 87.833L632.5-332.667v250.834H195.833Zm188.5-206.334-202-202.167q-9.833-10.333-15.166-23.25-5.334-12.916-5.334-27.083 0-14.5 5.25-27.583 5.25-13.083 15.25-23.083l78.5-79.5 117.5 117.5q27.167 27.166 42 61.999 14.833 34.834 14.833 73 0 36-13 69.667t-37.833 60.5Z" />
    </svg>
  );
};

export default DogIconFilled;
