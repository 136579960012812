export const frontendInputFields = {
  ADVERTISEMENT_ABN: "advertisement-abn",
  ADVERTISEMENT_WEBSITE_URL: "advertisement-website-url",
  ADVERTISEMENT_TITLE: "advertisement-title",
  ADVERTISEMENT_DESCRIPTION: "advertisement-description",
  ADVERTISEMENT_MAIN_CATEGORY: "advertisement-main-category",
  ADVERTISEMENT_SUB_CATEGORY: "advertisement-sub-category",
  ADVERTISEMENT_MAIN_BUSINESS_ADDRESS: "advertisement-main-business-address",
  ADVERTISEMENT_MAIN_BUSINESS_STATE: "advertisement-main-business-state",
  ADVERTISEMENT_MAIN_BUSINESS_REGION: "advertisement-main-business-region",
  ADVERTISEMENT_MAIN_BUSINESS_PHONE_NUMBER:
    "advertisement-main-business-phone-number",
  ADVERTISEMENT_COVER_IMAGE: "advertisement-cover-image",
  ADVERTISEMENT_SUPPORTING_IMAGES: "advertisement-supporting-images",
  CONTACT_US_EMAIL: "contact-us-email",
  CONTACT_US_MESSAGE: "contact-us-message",
  USER_EMAIL: "user-email",
  RECAPTCHA_TOKEN: "recaptcha-token",
  USER_PASSWORD: "user-password",
  USER_LOGIN_CODE: "user-login-code",
  USER_NEW_PASSWORD: "user-new-password",
  USER_CONFIRM_NEW_PASSWORD: "user-confirm-new-password",
  USER_CONFIRM_PASSWORD: "user-confirm-password",
  ADMIN_REQUEST_CHANGE_REASON: "admin-request-change-reason",
  TERMS_AND_CONDITIONS_ACCEPTED: "terms-and-conditions-accepted",
  BUSINESS_EMAIL: "business-email",
};
