import SidebarButtonComponent from "./component/sidebarButtonComponent";
import SidebarItemsComponent from "./component/sidebarItemsComponent";
import useSidebarHook from "./hook/useSidebarHook";
import { currentCategoriesAndSubCategories } from "../../util/currentCategoriesAndSubcategories";
import { useLocation } from "react-router-dom";
import { categoriesAlias } from "../../util/currentCategoriesAndSubcategories";
import { useEffect, useRef } from "react";

function Sidebar() {
  const location = useLocation().pathname;
  const {
    openSidebar,
    setOpenSidebar,
    closeAllSidebarItems,
    openSidebarItemsArray,
    setOpenSidebarItemsArray,
    icons,
    isNarrowScreen
  } = useSidebarHook();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        if (isNarrowScreen === "true") {
          setOpenSidebar(false);
          closeAllSidebarItems();
        }
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div id="sidebar" ref={sidebarRef}>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <SidebarButtonComponent
          openSidebar={openSidebar}
          setOpenSidebar={setOpenSidebar}
          closeAllSidebarItems={closeAllSidebarItems}
        />

        <div id="sidebarElements">
          {currentCategoriesAndSubCategories.map(
            (advertisementCategoryAndSubCategory: any, index) => (
              <SidebarItemsComponent
                firstItem={index === 0 ? true : false}
                key={advertisementCategoryAndSubCategory.id}
                openSidebar={openSidebar}
                setOpenSidebarItem={
                  setOpenSidebarItemsArray[
                    advertisementCategoryAndSubCategory.id
                  ]
                }
                openSidebarItem={
                  openSidebarItemsArray[advertisementCategoryAndSubCategory.id]
                }
                title={advertisementCategoryAndSubCategory.categoryAlias}
                shouldTitleBeBold={
                  location.includes(
                    advertisementCategoryAndSubCategory.categoryAlias
                  ) ||
                  (advertisementCategoryAndSubCategory.categoryAlias ===
                    categoriesAlias.USER_DASHBOARD &&
                    (location.includes("dashboard") ||
                      location.includes("login") ||
                      location.includes("register"))) ||
                  (advertisementCategoryAndSubCategory.categoryAlias ===
                    categoriesAlias.HOME &&
                    location === "/")
                }
                icon={icons[advertisementCategoryAndSubCategory.id]}
                subCategories={
                  advertisementCategoryAndSubCategory.subCategories
                }
                categoryAlias={
                  advertisementCategoryAndSubCategory.categoryAlias
                }
                closeAllSidebarItems={closeAllSidebarItems}
                setOpenSidebar={setOpenSidebar}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
