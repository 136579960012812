import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/context";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import Table from "react-bootstrap/Table";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import { Button } from "react-bootstrap";
import ScrollableTable from "../../sharedComponents/scrollableTable";
import axios from "axios";
import { useNavigate } from "react-router";
import {
  FunctionalityModal,
  RedirectModal,
} from "../../sharedComponents/modals";

function AdminUsersManage() {
  const history = useNavigate();
  const { adminAllUsers } = useContext(Context);
  const {
    setShouldFetchAuthInitialStates,
    setShouldFetchPublicInitialStates,
    setIfAuthenticated,
    setIfSuperAdmin,
    setCurrentUser,
  } = useContext(Context);
  const [showFunctionalityModal, setShowFunctionalityModal] = useState(false);
  const [userId, setUserId] = useState(false);
  const [functionalityModalButtonWording, setFunctionalityModalButtonWording] =
    useState("");
  const [functionalityModalMessage, setFunctionalityModalMessage] =
    useState("");
  const [functionalityModalRedirectUrl, setFunctionalityModalRedirectUrl] =
    useState("");
  const [functionalityModalFunctionsList, setFunctionalityModalFunctionsList] =
    useState<any[]>([]);
  const [
    functionalityModalAdvertisementError,
    setFunctionalityModalAdvertisementError,
  ] = useState("");

  useEffect(() => {}, [adminAllUsers]);

  const deleteUser = async (userId: string) => {
    const response = await axios.post("admin/admin-delete-user", {
      userId,
    });
    if (response?.status === 201) {
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    }
  };

  const suspendUser = async (userId: string) => {
    const response = await axios.post("admin/admin-suspend-user", {
      userId,
    });
    if (response?.status === 201) {
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    }
  };

  const unsuspendUser = async (userId: string) => {
    const response = await axios.post("admin/admin-unsuspend-user", {
      userId,
    });
    if (response?.status === 201) {
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    }
  };

  const resetPassUser = async (userId: string) => {
    const response = await axios.post(
      "admin/admin-reset-pass-on-behalf-of-user",
      {
        userId,
      }
    );
    if (response?.status === 201) {
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    }
  };

  const loginOnBehalfOfUser = async (userId: string) => {
    const response = await axios.post("admin/admin-login-on-behalf-of-user", {
      userId,
    });
    if (response?.status === 200) {
      setIfAuthenticated("true");
      setCurrentUser(response.data);
      if (
        response?.data?.permissions &&
        response?.data?.permissions.includes("superAdmin")
      ) {
        setIfSuperAdmin("true");
      } else {
        setIfSuperAdmin("false");
      }
      setShouldFetchAuthInitialStates(true);
      setShouldFetchPublicInitialStates(true);
    }
  };

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.DASHBOARD_URL, true],
          [routesEnum.ADMIN_URL, true],
          [routesEnum.ADMIN_USERS_MANAGE_URL, false],
        ]}
      ></BreadCrumb>

      <FunctionalityModal
        functionalityModalAdvertisementError={
          functionalityModalAdvertisementError
        }
        setFunctionalityModalAdvertisementError={
          setFunctionalityModalAdvertisementError
        }
        modalShow={showFunctionalityModal}
        setModalShow={setShowFunctionalityModal}
        setShow
        button={functionalityModalButtonWording}
        message={functionalityModalMessage}
        url={functionalityModalRedirectUrl}
        loginOnBehalfOfUser={loginOnBehalfOfUser}
        deleteUser={deleteUser}
        suspendUser={suspendUser}
        unsuspendUser={unsuspendUser}
        resetPassUser={resetPassUser}
        functionalityModalFunctionsList={functionalityModalFunctionsList}
        extraParams={{ userId }}
        history={history}
      />

      <GeneralContainerRowColCard colMd={12} colLg={12} colXl={12}>
        <ScrollableTable>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Email</th>
                <th>Id</th>
                <th>Created_at</th>
                <th>Updated_at</th>
                <th className="table-content-cell-center">Login</th>
                <th className="table-content-cell-center">Delete</th>
                <th className="table-content-cell-center">(un)suspend</th>
                <th
                  style={{ minWidth: "106px" }}
                  className="table-content-cell-center"
                >
                  Reset Pass
                </th>
              </tr>
            </thead>
            <tbody>
              {adminAllUsers !== "notSet" &&
                adminAllUsers.map((user: any, index: any) => (
                  <tr key={index}>
                    <td>{user.email}</td>
                    <td>{user.id}</td>
                    <td>
                      {new Date(user.date_createdAt).toLocaleString("en-AU", {
                        timeZone: "Australia/Sydney",
                      })}
                    </td>
                    <td>
                      {new Date(user.date_updatedAt).toLocaleString("en-AU", {
                        timeZone: "Australia/Sydney",
                      })}
                    </td>
                    <td className="table-content-cell-center">
                      <Button
                        size="sm"
                        onClick={() => {
                          setUserId(user.id);
                          setFunctionalityModalButtonWording("Login");
                          setFunctionalityModalMessage(
                            "Are you sure? you will be logged in on behalf of this user."
                          );
                          setFunctionalityModalRedirectUrl("/dashboard");
                          setFunctionalityModalFunctionsList([
                            "history",
                            "loginOnBehalfOfUser",
                          ]);
                          setShowFunctionalityModal(true);
                        }}
                      >
                        Login
                      </Button>
                    </td>
                    <td className="table-content-cell-center">
                      <Button
                        size="sm"
                        onClick={() => {
                          setUserId(user.id);
                          setFunctionalityModalButtonWording("Delete");
                          setFunctionalityModalMessage(
                            "Are you sure? selected user and all their advertisements will be deleted"
                          );
                          setFunctionalityModalFunctionsList(["deleteUser"]);
                          setShowFunctionalityModal(true);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                    {!user.ifSuspended && (
                      <td className="table-content-cell-center">
                        <Button
                          size="sm"
                          onClick={() => {
                            setUserId(user.id);
                            setFunctionalityModalButtonWording("Suspend");
                            setFunctionalityModalMessage(
                              "Are you sure? selected user will be suspended and can't login to the system!"
                            );
                            setFunctionalityModalFunctionsList(["suspendUser"]);
                            setShowFunctionalityModal(true);
                          }}
                        >
                          Suspend
                        </Button>
                      </td>
                    )}
                    {user.ifSuspended && (
                      <td className="table-content-cell-center">
                        <Button
                          size="sm"
                          onClick={() => {
                            setUserId(user.id);
                            setFunctionalityModalButtonWording("Unsuspend");
                            setFunctionalityModalMessage(
                              "Are you sure? selected user will be unsuspend and can login to the system again!"
                            );
                            setFunctionalityModalFunctionsList([
                              "unsuspendUser",
                            ]);
                            setShowFunctionalityModal(true);
                          }}
                        >
                          Unsuspend
                        </Button>
                      </td>
                    )}
                    <td className="table-content-cell-center">
                      <Button
                        size="sm"
                        onClick={() => {
                          setUserId(user.id);
                          setFunctionalityModalButtonWording("Reset Pass");
                          setFunctionalityModalMessage(
                            "Are you sure? selected user's password will be set to a random password!"
                          );
                          setFunctionalityModalFunctionsList(["resetPassUser"]);
                          setShowFunctionalityModal(true);
                        }}
                      >
                        Reset Pass
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </ScrollableTable>
      </GeneralContainerRowColCard>
    </>
  );
}

export default AdminUsersManage;
