import { useContext } from "react";
import { Nav } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import _ from "lodash";
import { createTitleFromAlias } from "../../util/createTitleFromAlias";

function BreadCrumb(props: any) {
  const { routes } = props;
  const relativeUrl = window.location.pathname;
  const shouldShowBreadCrumbs = routes ? true : false;
  return (
    <div id="breadCrumbsContainer" className="textNotSelectable">
      <Breadcrumb>
        {!shouldShowBreadCrumbs ? (
          <Breadcrumb.Item
            active
            className=""
            style={{ height: "24px" }}
          ></Breadcrumb.Item>
        ) : (
          routes.map((route: any, index: any) => {
            {
              var link = "/";
              if (route[3]) {
                link = route[3];
              } else {
                if (route[0] === "home") {
                  link = "/";
                }
                if (relativeUrl.includes(route[0])) {
                  const temp: any = relativeUrl.split(route[0]);
                  link = `${temp[0]}${route[0]}`;
                }
              }
            }
            return (
              <Breadcrumb.Item active key={index}>
                {route[1] ? (
                  <Nav.Link
                    style={{ padding: 0, display: "inline" }}
                    className="link-primary"
                    as={Link}
                    to={link}
                  >
                    {createTitleFromAlias(route[2] || route[0])}
                  </Nav.Link>
                ) : (
                  createTitleFromAlias(route[2] || route[0])
                )}
              </Breadcrumb.Item>
            );
          })
        )}
      </Breadcrumb>
    </div>
  );
}

export default BreadCrumb;
