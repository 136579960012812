import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

import {
  GoogleLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../context/context";
import { useNavigate } from "react-router";
import BreadCrumb from "../../layouts/breadCrumbs/breadCrumb";
import { routesEnum } from "../../layouts/breadCrumbs/routesEnum";
import axios from "axios";
import { ResendLoginEmailCodeSuccessfulModal } from "./component/modals";
import GeneralContainerRowColCard from "../../sharedComponents/generalContainerRowColCard";
import FormErrorMessage from "../../sharedComponents/formErrorMessage";
import { isWebView } from "../../util/isWebView";
import Recaptcha from "../recaptcha/recaptcha";
import {
  inputFieldErrorMapperBoolean,
  inputFieldErrorMapperWording,
} from "../../util/inputFieldErrorMapper";
import { frontendInputFields } from "../../util/frontendInputFields";
import { useSearchParams } from "react-router-dom";
import {
  GeneralModal,
  RedirectToRegisterPage,
} from "../../sharedComponents/modals";
import HorizontalOr from "../../sharedComponents/horizontalOr";

function Login() {
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    ifAuthenticated,
    setIfAuthenticated,
    setIfSuperAdmin,
    isNarrowScreen,
    setCurrentUser,
    openSidebar,
  } = useContext(Context);
  const [
    userHasNotRegisteredBeforeModalShow,
    setUserHasNotRegisteredBeforeModalShow,
  ] = useState(false);

  const recaptchaRef = useRef<any>();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      searchParams.get("userHasNotRegisteredBefore") &&
      searchParams.get("userHasNotRegisteredBefore") === "true"
    ) {
      setUserHasNotRegisteredBeforeModalShow(true);
      searchParams.delete("userHasNotRegisteredBefore");
      setSearchParams(searchParams);
    }
  }, []);

  const loginWithGoogle = () => {
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("googleLoginClicked");
    } else {
      window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_ROUTE as any;
    }
  };

  const loginWithFacebook = () => {
    if (isWebView()) {
      (window as any).ReactNativeWebView.postMessage("facebookLoginClicked");
    } else {
      window.location.href = window.location.href = process.env
        .REACT_APP_FACEBOOK_LOGIN_ROUTE as any;
    }
  };

  const [isDuringSubmission, setIsDuringSubmission] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [disableLogin, setDisableLogin] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [
    loginWithEmailAndPasswordFormError,
    setLoginWithEmailAndPasswordFormError,
  ] = useState([]);
  const [loginSendCodeError, setLoginSendCodeError] = useState([]);

  useEffect(() => {
    const firstInvalidField = document.querySelector(".is-invalid");
    if (firstInvalidField) {
      firstInvalidField.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [loginWithEmailAndPasswordFormError]);

  const handleSubmitLoginForm = async (event: any) => {
    event.preventDefault();
    setIsDuringSubmission(true);
    setLoginWithEmailAndPasswordFormError([]);
    const token = await recaptchaRef.current.executeAsync();
    axios
      .post("auth/login-with-email-and-password", {
        username: email,
        password: password,
        sendLoginCode: true,
        recaptchaToken: token,
      })
      .then(function (response) {
        if (
          response?.status === 200 &&
          response?.data?.emailLoginCodeSent === true
        ) {
          setLoginWithEmailAndPasswordFormError([]);
          setIsDuringSubmission(false);
          setDisableLogin(true);
        } else if (response?.status === 200) {
          setIfAuthenticated("true");
        }
      })
      .catch((error) => {
        setLoginWithEmailAndPasswordFormError(
          error?.response?.data?.message || []
        );
        setIsDuringSubmission(false);
      });
  };

  const handleSubmitCode = async (event: any) => {
    event.preventDefault();
    setIsDuringSubmission(true);
    setLoginSendCodeError([]);
    const token = await recaptchaRef.current.executeAsync();
    axios
      .post("auth/login-with-email-and-password-and-email-code", {
        username: email,
        password,
        code,
        stayLoggedIn,
        recaptchaToken: token,
      })
      .then(function (response) {
        if (response?.status === 200) {
          setIfAuthenticated("true");
          setCurrentUser(response.data);
          setIsDuringSubmission(false);
          if (
            response?.data?.permissions &&
            response?.data?.permissions.includes("superAdmin")
          ) {
            setIfSuperAdmin("true");
          } else {
            setIfSuperAdmin("false");
          }
        }
      })
      .catch((error) => {
        setLoginSendCodeError(error?.response?.data?.message);
        setIsDuringSubmission(false);
      });
  };

  const handleSubmitGoogleLoginAuthMobile = async (event: any) => {
    event.preventDefault();
    setLoginSendCodeError([]);
    const idToken = event.target.googleLoginAuthMobile.value;
    axios
      .post("auth/mobile-auth-google-login", {
        idToken,
      })
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data?.userHasNotRegisteredBefore === true) {
            setUserHasNotRegisteredBeforeModalShow(true);
            return;
          }
          setIfAuthenticated("true");
          setCurrentUser(response.data);
          if (
            response?.data?.permissions &&
            response?.data?.permissions.includes("superAdmin")
          ) {
            setIfSuperAdmin("true");
          } else {
            setIfSuperAdmin("false");
          }
        }
      })
      .catch((error) => {});
  };

  const handleSubmitFacebookLoginAuthMobile = async (event: any) => {
    event.preventDefault();
    setLoginSendCodeError([]);
    const accessToken = event.target.facebookLoginAuthMobileAccessToken.value;
    const userId = event.target.facebookLoginAuthMobileUserId.value;
    const authorisationToken =
      event.target.facebookLoginAuthMobileAuthorisationToken.value;
    axios
      .post("auth/mobile-auth-facebook-login", {
        accessToken,
        userId,
        authorisationToken,
      })
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data?.userHasNotRegisteredBefore === true) {
            setUserHasNotRegisteredBeforeModalShow(true);
            return;
          }
          setIfAuthenticated("true");
          setCurrentUser(response.data);
          if (
            response?.data?.permissions &&
            response?.data?.permissions.includes("superAdmin")
          ) {
            setIfSuperAdmin("true");
          } else {
            setIfSuperAdmin("false");
          }
        }
      })
      .catch((error) => {});
  };

  const resendLoginEmailCode = async () => {
    setIsDuringSubmission(true);
    setLoginSendCodeError([]);
    const token = await recaptchaRef.current.executeAsync();
    axios
      .post("auth/login-with-email-and-password", {
        username: email,
        password,
        sendLoginCode: true,
        recaptchaToken: token,
      })
      .then(function (response) {
        if (response?.status === 200) {
          setIsDuringSubmission(false);
          setModalShow(true);
        }
      })
      .catch((error) => {
        setLoginSendCodeError(error?.response?.data?.message);
        setIsDuringSubmission(false);
      });
  };

  const cancelLogin = () => {
    setCode("");
    setLoginSendCodeError([]);
    setLoginWithEmailAndPasswordFormError([]);
    setEmail("");
    setPassword("");
    setDisableLogin(false);
  };

  const goToRegisterPage = () => {
    history("/register");
  };

  const goToForgotPasswordPage = () => {
    history("/forgot-password");
  };

  useEffect(() => {
    if (ifAuthenticated === "true") {
      history("/register");
    }
  }, [ifAuthenticated, isNarrowScreen]);

  if (ifAuthenticated === "notSet" || ifAuthenticated === "true") {
    return null;
  }

  return (
    <>
      <BreadCrumb
        routes={[
          ["home", true],
          [routesEnum.LOGIN_URL, false],
        ]}
      ></BreadCrumb>
      <RedirectToRegisterPage
        modalShow={userHasNotRegisteredBeforeModalShow}
        setModalShow={setUserHasNotRegisteredBeforeModalShow}
      />
      <ResendLoginEmailCodeSuccessfulModal
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 7 : 6}
        colXl={openSidebar ? 6 : 5}
      >
        <>
          <GoogleLoginButton
            className="loginButtons"
            onClick={loginWithGoogle}
            text="Login With Google"
            style={{
              boxShadow: "none",
              border: "1px solid #C8C8C8",
              width: "100%",
              marginRight: 0,
              marginLeft: 0,
            }}
          ></GoogleLoginButton>
          <FacebookLoginButton
            className="loginButtons"
            onClick={loginWithFacebook}
            text="Login With Facebook"
            style={{
              boxShadow: "none",
              width: "100%",
              marginRight: 0,
              marginLeft: 0,
            }}
          ></FacebookLoginButton>
        </>
        <Form
          id="googleLoginAuthMobileForm"
          className="hidden"
          onSubmit={handleSubmitGoogleLoginAuthMobile}
        >
          <Form.Group className="mb-2">
            <Form.Control
              id="googleLoginAuthMobileInput"
              type="text"
              name="googleLoginAuthMobile"
              placeholder="googleLoginAuthMobile"
            />
          </Form.Group>
          <Button
            id="googleLoginAuthMobileSubmit"
            className="me-2 mb-2"
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringSubmission}
          >
            Submit
          </Button>
        </Form>
        <Form
          id="facebookLoginAuthMobileForm"
          className="hidden"
          onSubmit={handleSubmitFacebookLoginAuthMobile}
        >
          <Form.Group className="mb-2">
            <Form.Control
              id="facebookLoginAuthMobileInputAccessToken"
              type="text"
              name="facebookLoginAuthMobileAccessToken"
              placeholder="facebookLoginAuthMobileAccessToken"
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              id="facebookLoginAuthMobileInputAuthorisationToken"
              type="text"
              name="facebookLoginAuthMobileAuthorisationToken"
              placeholder="facebookLoginAuthMobileAuthorisationToken"
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              id="facebookLoginAuthMobileInputUserId"
              type="text"
              name="facebookLoginAuthMobileUserId"
              placeholder="facebookLoginAuthMobileUserId"
            />
          </Form.Group>
          <Button
            id="facebookLoginAuthMobileSubmit"
            className="me-2 mb-2"
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringSubmission}
          >
            Submit
          </Button>
        </Form>
      </GeneralContainerRowColCard>
      <HorizontalOr />
      <GeneralContainerRowColCard
        colMd={openSidebar ? 8 : 7}
        colLg={openSidebar ? 7 : 6}
        colXl={openSidebar ? 6 : 5}
      >
        <Form onSubmit={handleSubmitLoginForm}>
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                const lowercaseValue = e.target.value.toLowerCase();
                setEmail(lowercaseValue);
              }}
              disabled={disableLogin}
              isInvalid={inputFieldErrorMapperBoolean(
                loginWithEmailAndPasswordFormError,
                frontendInputFields.USER_EMAIL
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                loginWithEmailAndPasswordFormError,
                frontendInputFields.USER_EMAIL
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              disabled={disableLogin}
              isInvalid={inputFieldErrorMapperBoolean(
                loginWithEmailAndPasswordFormError,
                frontendInputFields.USER_PASSWORD
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                loginWithEmailAndPasswordFormError,
                frontendInputFields.USER_PASSWORD
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={disableLogin || isDuringSubmission}
          >
            Login
          </Button>
          {!disableLogin && (
            <a
              className="link-opacity-100 float-end ms-2 pointerOnHover"
              onClick={goToRegisterPage}
            >
              <small>register</small>
            </a>
          )}

          {!disableLogin && (
            <a
              className="link-opacity-100 float-end ms-2 pointerOnHover"
              onClick={goToForgotPasswordPage}
            >
              <small>forgot pass</small>
            </a>
          )}
        </Form>
        <Form
          className={`mt-2 ${disableLogin ? "" : "hidden"}`}
          onSubmit={handleSubmitCode}
        >
          <Recaptcha recaptchaRef={recaptchaRef} />
          <Form.Group className="mb-2">
            <Form.Control
              type="text"
              placeholder="Enter code sent to your email"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              isInvalid={inputFieldErrorMapperBoolean(
                loginSendCodeError,
                frontendInputFields.USER_LOGIN_CODE
              )}
            />
            <Form.Control.Feedback type="invalid">
              {inputFieldErrorMapperWording(
                loginSendCodeError,
                frontendInputFields.USER_LOGIN_CODE
              )}
            </Form.Control.Feedback>
          </Form.Group>
          {/* <Form.Group className="mb-2">
            <Form.Check
              inline
              name="stayLoggedIn"
              id="stayLoggedIn"
              type="checkbox"
              label="Stay logged in for 30 days"
              checked={stayLoggedIn}
              onChange={(e) => setStayLoggedIn(e.target.checked)}
            />
          </Form.Group> */}
          <Button
            className="me-2"
            variant="primary"
            type="submit"
            size="sm"
            disabled={isDuringSubmission}
          >
            Submit
          </Button>
          <Button
            onClick={resendLoginEmailCode}
            className="me-2"
            variant="primary"
            size="sm"
            disabled={isDuringSubmission}
          >
            Resend Code
          </Button>
          <Button
            className="me-2"
            variant="primary"
            size="sm"
            onClick={cancelLogin}
            disabled={isDuringSubmission}
          >
            Cancel
          </Button>
        </Form>
      </GeneralContainerRowColCard>
    </>
  );
}

export default Login;
