export const inputFieldErrorMapperBoolean = (
  errors: Array<string>,
  field: string
) => {
  if (errors && Array.isArray(errors)) {
    return errors.some((error) => error.startsWith(field));
  }
  return false;
};

export const inputFieldErrorMapperWording = (
  errors: Array<string>,
  field: string
) => {
  if (errors && Array.isArray(errors)) {
    const error = errors.find((error) => error.startsWith(field));
    if (error) {
      return error.split(field)[1] || "";
    }
  }
  return "";
};
