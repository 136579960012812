export function loadGoogleMapScript() {
  const src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places&v=weekly&callback=initMap`;
  return new Promise((resolve) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      return;
    }
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}
