export const advertisementRegionsByState = [
  {
    id: 0,
    shortName: "nsw",
    longName: "new-south-wales",
    regions: [
      "sydney-surrounds",
      "hunter-region",
      "blue-mountains",
      "byron-bay",
      "central-coast",
      "newcastle",
      "north-coast",
      "port-stephens",
      "regional-nsw",
      "south-coast",
    ],
  },
  {
    id: 1,
    shortName: "vic",
    longName: "victoria",
    regions: [
      "melbourne-surrounds",
      "yarra-valley-dandenongs",
      "mornington-peninsula",
      "daylesford-hepburn-springs",
      "great-ocean-road",
      "phillip-island",
      "things-to-do-in-geelong",
      "regional-vic",
      "gippsland",
    ],
  },
  {
    id: 2,
    shortName: "qld",
    longName: "queensland",
    regions: [
      "brisbane-surrounds",
      "gold-coast",
      "sunshine-coast",
      "cairns",
      "tropical-north-queensland",
      "the-whitsundays",
      "great-barrier-reef",
      "fraser-coast",
      "noosa",
      "port-douglas",
      "regional-qld",
    ],
  },
  {
    id: 3,
    shortName: "wa",
    longName: "western-australia",
    regions: [
      "perth-surrounds",
      "rottnest-island",
      "broome",
      "exmouth",
      "mandurah",
      "margaret-river-south-west",
      "regional-wa",
    ],
  },
  {
    id: 4,
    shortName: "sa",
    longName: "south-australia",
    regions: [
      "adelaide-surrounds",
      "barossa-valley",
      "mclaren-vale",
      "adelaide-hills",
      "flinders-ranges",
      "kangaroo-island",
      "regional-sa",
    ],
  },
  {
    id: 5,
    shortName: "tas",
    longName: "tasmania",
    regions: [
      "hobart-south",
      "launceston-north",
      "east-coast",
      "bruny-island",
      "west-coast",
    ],
  },
  {
    id: 6,
    shortName: "act",
    longName: "australian-capital-territory",
    regions: ["canberra"],
  },
  {
    id: 7,
    shortName: "nt",
    longName: "northern-territory",
    regions: ["alice-springs-central", "darwin-top-end", "uluru-and-surrounds"],
  },
];
