import { Collapse } from "react-bootstrap";
import { FaRegCircle, FaCircle, FaAngleDown } from "react-icons/fa";
import useSidebarItemsComponentHook from "../hook/useSidebarItemsComponentHook";
import { createTitleFromAlias } from "../../../util/createTitleFromAlias";
import CircleIcon from "../../../icons/circle/circleIcon";
import CircleIconFilled from "../../../icons/circle/circleIconFilled";
import { useLocation } from "react-router";
import { useContext } from "react";
import { Context } from "../../../context/context";

function SidebarItemsComponent(props: any) {
  const {
    firstItem,
    handleOpenSidebarWhenClickedOnSidebarItemIcon,
    icon,
    openSidebar,
    handleOpenSidebarItem,
    title,
    openSidebarItem,
    subCategories,
    canShowSidebarItem,
    goToItemCategoryPage,
    whichCircle,
    shouldTitleBeBold,
    categoryAlias,
    goToMainCategoryPage,
    isNarrowScreen,
    closeAllSidebarItems,
    setOpenSidebar,
  } = useSidebarItemsComponentHook(props);

  const location = useLocation().pathname;

  const handleItemContainer = () => {
    if (subCategories?.length) {
      return;
    }
    if (categoryAlias === "home") {
      if (isNarrowScreen === "true") {
        setOpenSidebar(false);
      }
      goToMainCategoryPage("/");
    }
  };

  return (
    <>
      <div
        className={`sidebarItemContainer ${
          firstItem ? "topBorderFirstItem" : ""
        }`}
        onClick={handleItemContainer}
      >
        <div
          style={{ fontSize: "22px" }}
          className={`${shouldTitleBeBold ? "semiWhiteBackground" : ""}`}
        >
          <div
            className="mainIconSidebar"
            onClick={() =>
              handleOpenSidebarWhenClickedOnSidebarItemIcon(categoryAlias)
            }
            style={{ background: !shouldTitleBeBold ? "#1e90ff" : "" }}
          >
            {icon}
          </div>
        </div>
        <div className="noSelect " id="sidebarCategories">
          <Collapse
            in={openSidebar}
            dimension="width"
            // timeout={isNarrowScreen === "true" ? 0 : undefined}
          >
            <div>
              <div className="sidebarMainItemsWording">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "white",
                  }}
                  className={`sidebarItemWrapper noSelect ${
                    shouldTitleBeBold ? "semiWhiteBackground" : ""
                  }`}
                  onClick={handleOpenSidebarItem}
                >
                  <div
                    className={`sidebarItemWrapperTitle ${
                      shouldTitleBeBold ? "fw-bold" : ""
                    }`}
                  >
                    {createTitleFromAlias(title)}
                  </div>
                  {subCategories?.length > 0 && (
                    <div
                      className={`${
                        openSidebarItem
                          ? "rotate180degreesFaAngleUp"
                          : "rotate180degreesFaAngleDown"
                      }`}
                    >
                      <FaAngleDown />
                    </div>
                  )}
                </div>

                <Collapse
                  in={openSidebarItem}
                  // timeout={isNarrowScreen === "true" ? 0 : undefined}
                >
                  <div
                    style={{
                      color: "white",
                    }}
                    className={`${
                      shouldTitleBeBold ? "semiWhiteBackground" : ""
                    }`}
                  >
                    {subCategories &&
                      subCategories.map((subCategory: any, index: any) =>
                        canShowSidebarItem(subCategory.subCategoryAlias) ? (
                          <div
                            key={index}
                            className="categoryItems"
                            onClick={() =>
                              goToItemCategoryPage(subCategory.subCategoryAlias)
                            }
                          >
                            <span
                              style={{
                                position: "relative",
                                right: "2px",
                                bottom: "1px",
                              }}
                            >
                              {location.includes(
                                "/" + subCategory.subCategoryAlias
                              ) ? (
                                <CircleIconFilled />
                              ) : (
                                <CircleIcon />
                              )}
                            </span>
                            {createTitleFromAlias(subCategory.subCategoryAlias)}
                          </div>
                        ) : null
                      )}
                  </div>
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
}

export default SidebarItemsComponent;
