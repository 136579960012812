import axios from "axios";
import { useContext, useEffect } from "react";
import { Context } from "../../context/context";
import { loadGoogleMapScript } from "../../util/loadGoogleMapScript";

function PublicInitialStatesWrapper({ children }: any) {
  const {
    allAdvertisements,
    setAllAdvertisements,
    setGoogleMapScriptLoaded,
    shouldFetchPublicInitialStates,
    setShouldFetchPublicInitialStates,
  } = useContext(Context);

  useEffect(() => {
    loadGoogleMapScript().then(() => setGoogleMapScriptLoaded("true"));
  }, []);

  useEffect(() => {
    if (shouldFetchPublicInitialStates) {
      (async () => {
        const response1: any = await axios.get(
          "advertisements/get-all-advertisements"
        );
        setAllAdvertisements(response1.data);
        setShouldFetchPublicInitialStates(false);
      })();
    }
  }, [shouldFetchPublicInitialStates]);
  return <>{children}</>;
}

export default PublicInitialStatesWrapper;
