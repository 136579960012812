import { useContext, useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { imagePlaceholderAdvertisementCardBase64 } from "../util/imagePlaceholderAdvertisementCardBase64";
import { Context } from "../context/context";
import axios from "axios";
import useAdvertisementPageHook from "../pages/advertisement/hook/useAdvertisementPageHook";
import { isWithinLastDays } from "../util/isWithinLastDays";

function AdvertisementCardImageComponent(props: any) {
  const { src, onClick, emptyImage } = props;
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [updatedSrc, setUpdatedSrc] = useState<any>(null);
  const [duringFreshImageCardFetch, setDuringFreshImageCardFetch] =
    useState(false);
  const { findAdvertisementInFrontendById } = useAdvertisementPageHook();

  useEffect(() => {}, [isImageLoaded]);

  useEffect(() => {
    if (src && typeof src === "string") {
      (async () => {
        const advertisementId = src
          .replace(process.env.REACT_APP_S3_ROUTE as any, "")
          .split("/")[2];
        const advertisementToBeEdited =
          findAdvertisementInFrontendById(advertisementId);
        if (isWithinLastDays(advertisementToBeEdited.date_updatedAt, 10)) {
          setDuringFreshImageCardFetch(true);
          try {
            const storedImageInS3 = await axios.get(
              `advertisements/get-advertisement-object-by-s3-url/${advertisementId}/0`
            );
            setUpdatedSrc(
              "data:image/jpeg;base64," + storedImageInS3.data.imageObject
            );
            setDuringFreshImageCardFetch(false);
          } catch (e: any) {}
        }
      })();
    }
  }, []);

  return (
    <>
      {(!isImageLoaded || duringFreshImageCardFetch) && (
        <span style={{ position: "relative" }}>
          {!emptyImage && (
            <>
              <Spinner
                style={{
                  color: "#f7f7f7",
                  position: "absolute",
                  margin: "auto",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 2,
                }}
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </>
          )}
          <Card.Img
            variant="top"
            src={imagePlaceholderAdvertisementCardBase64}
            // src="/image-placeholder-advertisement-card.png"
          />
        </span>
      )}
      <span style={{ position: "relative" }}>
        <Card.Img
          variant="top"
          src={updatedSrc || src}
          onClick={onClick}
          onLoad={() => {
            setTimeout(() => {
              setIsImageLoaded(true);
            }, 300);
          }}
          style={
            isImageLoaded && !duringFreshImageCardFetch
              ? {}
              : { display: "none" }
          }
        />
      </span>
    </>
  );
}

AdvertisementCardImageComponent.defaultProps = {
  onClick: () => {},
  src: { imagePlaceholderAdvertisementCardBase64 },
  variant: "top",
};

export default AdvertisementCardImageComponent;
